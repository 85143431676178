import {
    ITechseeAccountChannel,
    ITechseeAccountChannelParams
} from '@techsee/techsee-client-infra/lib/infra/AccountChannelContracts';
import {SentByServerEvents, ServiceEvents} from './AccountSocketEvents';
import {DbUserAdapter} from '../AngularServices/UserAdapter';
import {IDbAccount} from '../AngularServices/AngularServices';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {EventEmitter} from 'events';
import {IAccountChannelData} from './AccountSocketServiceContracts';
import {IEventLogsService} from '../EventsLogService';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';

export interface IAccountSocketService {
    joinAccountChannel(): PromiseLike<void>;
    disconnectAccountChannel(accountId: string, userId: string): void;
    on(accountData: string, param2: (data: any) => any): void;

    readonly pendingRooms: any[];
    readonly wtPendingRooms: any[];
}

export class AccountState {
    pendingRooms: any[] = [];

    fsPendingRooms: any[] = [];

    wtPendingRooms: any[] = [];

    accountId: string = '';

    constructor(accountId: string) {
        this.accountId = accountId;
    }
}

export class AccountSocketService extends EventEmitter implements IAccountSocketService {
    private _accountSocket: ITechseeAccountChannel;

    private _browserUtilsService: IBrowserUtilsService;

    private _appState: any;

    private _dbUserService: DbUserAdapter;

    private _dbAccountService: IDbAccount;

    private _eventsLogService: IEventLogsService;

    private _accountState: Nullable<AccountState> = null;

    constructor(
        accountSocket: ITechseeAccountChannel,
        browserUtilsService: IBrowserUtilsService,
        appState: any,
        dbUserService: DbUserAdapter,
        dbAccountService: IDbAccount,
        eventsLogService: IEventLogsService
    ) {
        super();

        this._accountSocket = accountSocket;
        this._browserUtilsService = browserUtilsService;
        this._appState = appState;
        this._dbUserService = dbUserService;
        this._dbAccountService = dbAccountService;
        this._eventsLogService = eventsLogService;

        this.syncHandler = this.syncHandler.bind(this);

        this.subscribeSocketEvents();

        // @TODO find better way to handle page refresh or reenter
        if (this._browserUtilsService.getFromLocalStorage('reconnectAccountSocket')) {
            this.joinAccountChannel();
        }
    }

    get pendingRooms() {
        this.throwIfNotActive('pendingRooms');

        return this._accountState!.pendingRooms;
    }

    get wtPendingRooms() {
        this.throwIfNotActive('wtPendingRooms');

        return this._accountState!.wtPendingRooms;
    }

    joinAccountChannel(): PromiseLike<any> {
        return this._dbUserService
            .getAccountChannelData()
            .then(({data}) => {
                const user = data;

                if (!user || !user.accountId) {
                    return null;
                }

                this._accountState = new AccountState(user.accountId);

                const joinParams: ITechseeAccountChannelParams = {
                    userId: user._id,
                    accountId: user.accountId
                };

                return this._accountSocket
                    .joinAccountChannel(joinParams)
                    .then((data: any) => {
                        this._eventsLogService.info({
                            logType: LOG_EVENTS.joinAccountChannelSuccess,
                            userId: user._id,
                            accountId: user.accountId
                        });

                        this._browserUtilsService.saveToLocalStorage('reconnectAccountSocket', true);

                        this._accountState!.fsPendingRooms = data.fsPendingRooms;
                        this._accountState!.pendingRooms = data.pendingRooms;
                        this._accountState!.wtPendingRooms = data.wtPendingRooms;

                        return data;
                    })
                    .catch((err) => {
                        this._eventsLogService.error({
                            logType: LOG_EVENTS.joinAccountChannelFailed.type,
                            userId: user._id,
                            accountId: user.accountId,
                            meta: {err}
                        });

                        throw err;
                    });
            })
            .catch((err) => {
                // JS Data error is "Not Found!"
                if (err.message === 'Not Found!') {
                    return;
                }

                console.error('Error joining account socket: ' + err);
            });
    }

    disconnectAccountChannel(accountId: string, userId: string): void {
        this._appState.setOfflineRoomCount(0);
        this._appState.setPendingRoomCount(0);
        this._browserUtilsService.removeFromLocalStorage('reconnectAccountSocket');
        this._eventsLogService.info({
            logType: LOG_EVENTS.disconnectAccountChannel,
            userId,
            accountId
        });

        return this._accountSocket.disconnect();
    }

    private subscribeSocketEvents() {
        this._accountSocket.on(SentByServerEvents.DataSync, this.syncHandler);
    }

    private syncHandler(data: IAccountChannelData) {
        if (!data) {
            return;
        }

        if (data.fsPendingRooms) {
            this.emit(ServiceEvents.FS_DATA, data.fsPendingRooms);
        }

        if (data.pendingRooms) {
            this.emit(ServiceEvents.OFFLINE_DATA, data.pendingRooms);
        }
        if (data.wtPendingRooms) {
            this.emit(ServiceEvents.PENDING_ROOM_DATA, data.wtPendingRooms);
        }
    }

    private get isAccountStateActive() {
        return this._accountState !== null && !!this._accountState.accountId;
    }

    private throwIfNotActive(naProp: string) {
        if (!this.isAccountStateActive) {
            throw new Error(`${naProp} is not available while there no active accounts`);
        }
    }
}
