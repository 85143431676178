import clone from 'lodash/clone';

export class SizeWithRatioParams {
    constructor(widthLimit, heightLimit, isPortrait, aspectRatio) {
        this.widthLimit = widthLimit;
        this.heightLimit = heightLimit;
        this.isPortrait = isPortrait;
        this.aspectRatio = aspectRatio;
    }
}

export class SizeCalculationResult {
    constructor() {
        this.newWidth = 0;
        this.newHeight = 0;
    }
}

export function calculateSizeWithRatio(calcParams) {
    const result = new SizeCalculationResult();
    const clonedParams = clone(calcParams);

    if (clonedParams.isPortrait) {
        if (clonedParams.heightLimit * clonedParams.aspectRatio > clonedParams.widthLimit) {
            clonedParams.heightLimit = clonedParams.widthLimit / clonedParams.aspectRatio;
        }

        result.newHeight = clonedParams.heightLimit;
        result.newWidth = clonedParams.heightLimit * clonedParams.aspectRatio;
    } else {
        if (clonedParams.widthLimit / clonedParams.aspectRatio > clonedParams.heightLimit) {
            clonedParams.widthLimit = clonedParams.heightLimit * clonedParams.aspectRatio;
        }

        result.newWidth = clonedParams.widthLimit;
        result.newHeight = clonedParams.widthLimit / clonedParams.aspectRatio;
    }

    return result;
}
