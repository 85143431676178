'use strict';

import each from 'lodash/each';
import forEach from 'lodash/forEach';
import last from 'lodash/last';
import find from 'lodash/find';
import get from 'lodash/get';
import filter from 'lodash/filter';
import some from 'lodash/some';
import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import {getRootStore} from '../../../../_react_/app.bootstrap';
import {RECORDING_MODES} from '@techsee/techsee-common/lib/constants/room.constants';
import moment from 'moment';
import patterns from '@techsee/techsee-common/lib/constants/utils.patterns';

export class HistoryController {
    constructor(
        tsStateHelper,
        $scope,
        $localStorage,
        db,
        accountData,
        tsUrlUtils,
        tsVideoUtils,
        $stateParams,
        filter,
        tsSessionData,
        $translate,
        searchByTags
    ) {
        'ngInject';

        this.videoLink = null;
        this.$scope = $scope;
        this.stateHelper = tsStateHelper;
        this.$localStorage = $localStorage;
        this.db = db;
        this.accountData = accountData;
        this.timezone = this.accountData.settings.timezone;
        this.urlUtils = tsUrlUtils;
        this.videoUtils = tsVideoUtils;
        this.searchTags = accountData.protectedSettings
            ? accountData.protectedSettings.imageTagging && accountData.protectedSettings.searchTags
            : false;
        this.tsSessionData = tsSessionData;
        this.browserUtilsService = getRootStore().browserUtilsService;
        this.customerId = $stateParams.customerId || $stateParams.r;
        this.customerNumber = $stateParams.customerNumber || $stateParams.p;
        this.allLanguagesNoHtml = patterns.allLanguagesNoHtml;
        this.searchByTags = searchByTags;

        // FIXME: Will only work for non-live usage.
        this.customerNumber = $stateParams.c ? $stateParams.c + this.customerNumber : this.customerNumber;

        if (!this.searchByTags) {
            this.$scope.$watch(
                () => `${this.startDate}:${this.endDate}`,
                (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                        // if there is a search term, repeat that
                        // (the new date range will be applied)
                        if (this.searchTagForm && this.searchTagForm.searchTerm) {
                            return this.searchForTags(this.searchTagForm);
                        }
                        this._applyRange();
                    }
                }
            );
        }

        this.filter = filter;

        if (this.searchByTags) {
            this.$translate = $translate;
            this.searchByTagsCount = 0;
            this.filteredData = {
                records: [],
                images: 0,
                videos: 0,
                messages: 0,
                files: 0,
                recordings: 0
            };
            this.searchByTagsInput = '';
            this.searchByTagsController = {
                searchByTagsInput: this.searchByTagsInput,
                startDate: this.startDate,
                endDate: this.endDate,
                filteredData: this.filteredData,
                searchByTagsCount: this.searchByTagsCount,
                executeSearchByTags: (tags, fromDate, toDate, page) =>
                    this.executeSearchByTags(tags, fromDate, toDate, page),
                getSearchByTagsCount: (tags, fromDate, toDate) => this.getSearchByTagsCount(tags, fromDate, toDate),
                loadRoom: (roomRecord, images, videos) => this.loadRoom(roomRecord, images, videos),
                loadDashboard: (imageId, videoId, roomRecord) => this.loadDashboard(imageId, videoId, roomRecord),
                translate: (translationKey) => this.translate(translationKey)
            };
        } else {
            this._getHistoryData();
        }
    }

    getFormattedDate(dateString) {
        const dateMomentObject = moment(dateString, 'DD/MM/YYYY'); // 1st argument - string, 2nd argument - format
        const dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object

        return dateObject;
    }

    translate(translationKey, translationParams) {
        return this.$translate.instant(translationKey, translationParams);
    }

    executeSearchByTags(tags, fromDate, toDate, page) {
        const tagsArr = tags?.toString().split(',');
        const nonEmptyTagsArray = tagsArr.filter((tag) => tag.trim());
        const encodedTags = nonEmptyTagsArray.map((tag) => encodeURIComponent(tag));

        return this.db.History.searchByTags({
            tags: JSON.stringify(encodedTags),
            accountId: this.accountData._id,
            fromDate: this.getFormattedDate(fromDate),
            toDate: this.getFormattedDate(toDate),
            page: page,
            perPage: 20
        }).then((results) => {
            this.filteredData.records = results;
            // Filter only records with relevant images
            this.filteredData.records = this.filteredData.records.filter((record) =>
                record.images.filter((image) => image.tags.some((tag) => tags.includes(tag.text)))
            );
            this.filteredData.images = 0;

            each(this.filteredData.records, (record) => {
                // Filter and count only relevant images
                const filteredImages = record.images.filter((image) =>
                    image.tags.some((tag) => tags.includes(tag.text))
                );

                record.images = filteredImages;

                if (filteredImages && filteredImages.length) {
                    this.filteredData.images += filteredImages.length;
                }
            });
        });
    }

    getSearchByTagsCount(tags, fromDate, toDate) {
        const tagsArr = tags?.toString().split(',');
        const nonEmptyTagsArray = tagsArr.filter((tag) => tag.trim());
        const encodedTags = nonEmptyTagsArray.map((tag) => encodeURIComponent(tag));

        return this.db.History.searchByTagsCount({
            tags: JSON.stringify(encodedTags),
            accountId: this.accountData._id,
            fromDate: this.getFormattedDate(fromDate),
            toDate: this.getFormattedDate(toDate)
        }).then((results) => {
            this.searchByTagsCount = results.matches;

            return results;
        });
    }

    searchByTagsParamsAreValid(tags) {
        return tags && tags.length && this.dateIsValid(this.startDate) && this.dateIsValid(this.endDate);
    }

    dateIsValid(date) {
        if (!date) {
            return false;
        }

        const dateRegex =
            /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

        return dateRegex.test(date);
    }

    calculateAndFormatDuration(startDate, endDate) {
        const duration = moment.duration(moment(endDate).diff(moment(startDate)));
        const formattedMinutesSeconds = moment.utc(duration.asMilliseconds()).format('mm:ss');
        let hours = duration.hours();
        const days = duration.days();

        if (days > 0) {
            hours += 24 * days;
        }

        if (hours < 10) {
            hours = `0${hours}`;
        }

        return `${hours}:${formattedMinutesSeconds}`;
    }

    _createThumbnailsForVideos(historyData, videoRecordingData) {
        return this.videoUtils.createThumbnailsForVideos(historyData).then((result) => {
            this.historyData = result;

            if (videoRecordingData.length) {
                this._mergeVideosToHistory(videoRecordingData);
            }

            this._applyInitialFilter();
        });
    }

    displayRecordingHistoryDates(date) {
        return moment.utc(date).tz(this.timezone).format('DD.MM.YYYY | h:mm A');
    }

    displayDates(date) {
        return moment.utc(date).tz(this.timezone).format('MMMM D, Y h:mm A');
    }

    displayEditDates(date) {
        return moment.utc(date).tz(this.timezone).format('DD/MM/YY h:mm A');
    }

    _addNewHistoryRecord(recording) {
        const recordToAdd = {
            accountId: recording.accountId,
            customerId: recording.customerId,
            customerNumber: recording.customerNumber,
            files: [],
            images: [],
            messages: [],
            roomClosed: true,
            roomId: {_id: recording.sessionId},
            timestamp: recording.sessionStartTime,
            videos: []
        };

        this._addRecording(recording, recordToAdd);

        this.historyData.records.push(recordToAdd);
    }

    _addRecording(recording, historyRecord) {
        if (recording.type === RECORDING_MODES.AUTOMATIC) {
            if (!historyRecord.recordings) {
                historyRecord.recordings = [];
            }

            historyRecord.recordings.push(recording);
        } else {
            if (!this.historyData.hasVideoRecordings) {
                this.historyData.hasVideoRecordings = true;
            }

            recording.url = recording.link;
            recording.isRecordedVideo = recording.link;
            historyRecord.videos.push(recording);
        }
    }

    _mergeVideosToHistory(videos) {
        if (!this.historyData.records) {
            this.historyData.records = [];
        }

        if (videos && videos.length) {
            each(videos, (recording) => {
                const historyRecord = find(
                    this.historyData.records,
                    (record) => get(record, 'roomId._id') === recording.sessionId
                );

                if (isEmpty(historyRecord)) {
                    this._addNewHistoryRecord(recording);
                } else {
                    this._addRecording(recording, historyRecord);
                }
            });
        }
    }

    _getHistoryData() {
        const promises = [];

        const cachedSessionHistory = this.browserUtilsService.getFromSessionStorage('sessionHistory');
        const cachedVideoRecodringHistory = this.browserUtilsService.getFromSessionStorage('videoRecordingsHistory');

        if (cachedSessionHistory && cachedVideoRecodringHistory) {
            this.browserUtilsService.removeFromSessionStorage('sessionHistory');
            this.browserUtilsService.removeFromSessionStorage('videoRecordingsHistory');

            return this._createThumbnailsForVideos(cachedSessionHistory, cachedVideoRecodringHistory);
        } else if (this.customerId) {
            promises.push(
                this.db.History.byCustomerId(
                    {
                        params: {
                            customerId: this.customerId,
                            accountId: this.accountData._id
                        }
                    },
                    {bypassCache: true}
                ),
                this.db.VideoRecordings.byCustomerId(
                    {
                        params: {
                            customerId: this.customerId
                        }
                    },
                    {bypassCache: true}
                )
            );
        } else if (this.customerNumber) {
            promises.push(
                this.db.History.byCustomerNumber(
                    {
                        params: {
                            customerNumber: this.customerNumber,
                            accountId: this.accountData._id
                        }
                    },
                    {bypassCache: true}
                ),
                this.db.VideoRecordings.byCustomerNumber(
                    {
                        params: {
                            customerNumber: this.customerNumber
                        }
                    },
                    {bypassCache: true}
                )
            );
        }

        return Promise.all(promises).then(([recordHistory, videoRecordingHistory]) => {
            this.videoRecordings = videoRecordingHistory.data;

            return this._createThumbnailsForVideos(recordHistory.data, this.videoRecordings);
        });
    }

    _applyInitialFilter() {
        if (this.filter.start) {
            this.startDate = moment(this.filter.start, 'x').toDate();
        }

        if (this.filter.end) {
            this.endDate = moment(this.filter.end, 'x').toDate();
        }

        if (this.filter.tag) {
            this.searchTagForm.searchTerm = this.filter.tag;
            this.searchTagForm.$submitted = true;

            return this.searchForTags(this.searchTagForm);
        }

        this._applyRange();
    }

    _applyRange() {
        const filteredData = {
            records: [],
            images: 0,
            videos: 0,
            messages: 0,
            files: 0,
            recordings: 0
        };

        const startTimestamp = this.startDate ? moment(this.startDate).startOf('day').unix() : 0,
            endTimestamp = this.endDate ? moment(this.endDate).endOf('day').unix() : Infinity;

        each(this.historyData.records, (record) => {
            const recordTimestamp = moment(record.timestamp).utc().unix();

            if (startTimestamp <= recordTimestamp && endTimestamp >= recordTimestamp) {
                filteredData.images += record.images.length;
                filteredData.videos += record.videos.length;
                filteredData.messages += record.messages.length;
                filteredData.files += record.files.length;

                if (record.recordings && record.recordings.length) {
                    filteredData.recordings += record.recordings.length;
                }

                filteredData.records.push(record);
            }
        });

        this.filteredData = filteredData;
        this.recordings = this._getAllRecordings(this.filteredData.records);
    }

    _getAllRecordings(records) {
        const recordings = [];

        each(records, (record) => {
            if (record.recordings && record.recordings.length) {
                recordings.push(record.recordings);
            }
        });

        return this._sortByVideoDate(flatten(recordings));
    }

    _sortByVideoDate(videos) {
        return videos.sort((a, b) => new Date(b.sessionStartTime).valueOf() - new Date(a.sessionStartTime).valueOf());
    }

    calculateDuration(endDate, startDate) {
        return moment(moment(endDate).diff(startDate)).format('hh:mm:ss');
    }

    searchForTags(form) {
        if (!form.searchTerm) {
            this.emptySearchTermSubmitted = true;

            return;
        }

        this.emptySearchTermSubmitted = false;
        this._applyRange();

        const filteredData = {
            records: [],
            images: 0,
            videos: 0,
            messages: 0,
            files: 0,
            recordings: 0
        };

        const taggedRecords = [];

        forEach(this.filteredData.records, (record) => {
            const filteredRecord = this._findTaggedResources(record, form.searchTerm);

            if (filteredRecord && (filteredRecord.images.length > 0 || filteredRecord.videos.length > 0)) {
                filteredData.images += filteredRecord.images.length;
                filteredData.videos += filteredRecord.videos.length;
                filteredData.messages += filteredRecord.messages.length;
                filteredData.files += filteredRecord.files.length;

                taggedRecords.push(filteredRecord);
            }
        });

        filteredData.records = taggedRecords;
        this.filteredData = filteredData;
        this.recordings = this._getAllRecordings(this.filteredData.records);
    }

    _findTaggedResources(record, searchTerm) {
        const search = new RegExp(searchTerm.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&'), 'i');

        const images = filter(record.images, (resource) => some(resource.tags, (tag) => search.test(tag.text)));

        const videos = filter(record.videos, (resource) => some(resource.tags, (tag) => search.test(tag.text)));

        return assign({}, record, {images, videos});
    }

    clearSearchForm() {
        this.emptySearchTermSubmitted = false;

        if (this.searchTagForm) {
            this.searchTagForm.searchTerm = '';
            this.searchTagForm.$submitted = false;
        }

        this._applyRange();
    }

    loadDashboard(imageId, videoId, roomRecord) {
        const idQuery = this.customerId && `customerId:${encodeURIComponent(this.customerId)}`,
            numberQuery = this.customerNumber && `customerNumber:${this.customerNumber}`;
        let returnQuery = idQuery || numberQuery;

        // Tell the dashboard we are coming from history page
        this.urlUtils.setParamValue('history', true);

        this.tsSessionData.setValue('roomId', roomRecord._id).then(() => {
            if (this.searchByTags) {
                returnQuery = 'searchByTags';
            } else {
                if (this.searchTagForm && this.searchTagForm.searchTerm && this.searchTagForm.$submitted) {
                    returnQuery += `,tag:${this.searchTagForm.searchTerm}`;
                }

                if (this.startDate) {
                    returnQuery += `,start:${this.startDate.valueOf()}`;
                }

                if (this.endDate) {
                    returnQuery += `,end:${this.endDate.valueOf()}`;
                }

                if (imageId) {
                    this.urlUtils.setParamValue('loadImage', imageId);
                }

                if (videoId) {
                    this.urlUtils.setParamValue('loadVideo', videoId);
                }
            }

            if (returnQuery) {
                this.urlUtils.setParamValue('returnQuery', returnQuery);
            }

            const showRoomId = this.accountData.protectedSettings && this.accountData.protectedSettings.showRoomIdInUrl,
                roomId = showRoomId ? roomRecord._id : null;

            if (this.roomIsActiveOffline(roomRecord)) {
                return this.stateHelper.safeGo('dashboard.entry', {
                    room: roomId,
                    loadImage: imageId,
                    loadVideo: videoId,
                    returnQuery: returnQuery
                });
            }

            this.urlUtils.setParamValue('offline', 'true');

            this.stateHelper.safeGo('dashboard.main', {
                offline: true,
                history: true,
                room: roomId,
                loadImage: imageId,
                loadVideo: videoId,
                returnQuery: returnQuery
            });
        });
    }

    backToInvite() {
        this.accountData.protectedSettings.v3.enabled
            ? getRootStore().redirectionService.goToPortal()
            : this.stateHelper.safeGo('invite');
    }

    roomIsActiveOffline(room) {
        return room && room.offline && !moment(room.endTimestamp).unix();
    }

    loadRoom(roomRecord, images, videos) {
        const image = last(images) || {};
        const video = last(videos) || {};

        // eslint-disable-next-line no-constant-binary-expression
        this.loadDashboard(image !== {} ? image._id : null, video !== {} ? video._id : null, roomRecord);
    }

    setCurrentVideoToPlay(videoLink) {
        this.videoLink = videoLink;
    }

    closeVideo() {
        this.videoLink = null;
    }
}
