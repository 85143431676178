import * as Sentry from '@sentry/browser';
import * as SentryBrowser from '@sentry/browser';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import startsWith from 'lodash/startsWith';
// @ts-ignore
import {getBackendUrl} from '@techsee/techsee-common/lib/utils';

const resolveApiUrl = (url: string) =>
    getBackendUrl(url, {
        hostname: window.location.hostname,
        ENV: {}
    });

const sendSentryFailedEventLog = (err: any) => {
    try {
        const error = err && err.toString();
        const req = new XMLHttpRequest();
        const apiUrl = STATS_API_URL ? resolveApiUrl(STATS_API_URL) : resolveApiUrl(API_URL);

        if (startsWith(error, 'Uncaught Error: [$rootScope:infdig]')) {
            return;
        }

        req.open('POST', `${apiUrl}/api/eventLog`);
        req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        req.send(
            JSON.stringify({
                type: 'UNCAUGHT_EXCEPTION',
                userId: 'none',
                room: 'none',
                sentBy: PlatformType.dashboard,
                meta: {
                    clientType: PlatformType.dashboard,
                    error,
                    url: window.location.href,
                    description: 'sentry instrumentation failed'
                }
            })
        );
    } catch (e) {
        console.error(e);
    }
};

try {
    if (ENABLE_SENTRY && SENTRY_DSN) {
        const apiHostName = new URL(resolveApiUrl(API_URL)).hostname;
        const sentryUrl = SENTRY_DSN.replace('{hostname}', apiHostName);
        const integrations = [];

        if (ENABLE_SENTRY_BROWSER_TRACING) {
            integrations.push(SentryBrowser.browserTracingIntegration());
        }

        Sentry.init({
            dsn: sentryUrl,
            integrations,
            tracesSampleRate: SENTRY_TRACING_SAMPLE_RATE
        });
    }
} catch (error) {
    sendSentryFailedEventLog(error);
}

export const sendSentryLog = (err: any) => {
    // @ts-ignore
    if (ENABLE_SENTRY) {
        Sentry.captureException(err);
    }
};
