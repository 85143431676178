import React, {FC, Fragment, ReactElement} from 'react';

import {ProductFruits} from 'react-product-fruits';

interface IProductFruitsContainerProps {
    username: string;
    workspaceCode: string;
    language?: string;
}

const ProductFruitsContainer: FC<IProductFruitsContainerProps> = ({
    username = '',
    workspaceCode = '',
    language = 'en'
}): ReactElement => {
    const productFruitsProps = {
        user: {
            username: username
        },
        workspaceCode: workspaceCode,
        language: language
    };

    return (
        <Fragment>
            <ProductFruits {...productFruitsProps} />
        </Fragment>
    );
};

export default ProductFruitsContainer;
