import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {LeftBarItemComponent} from './StateItem';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import {ILeftBarItemData, ILeftBarSection, LeftBarControllerBase, AlignEnum, DashboardSectionTypes} from './controller';
import {ContextMenuWrapper} from '@techsee/techsee-ui-common/lib/menus/context-menu';
import {TextWithIconMenuItem} from '@techsee/techsee-ui-common/lib/menus/menu-items/TextWithIconMenuItem';
import {LARGE_SCREEN_TOP_VALUE, SMALL_SCREEN_TOP_VALUE} from './LeftBarBase.settings';
import {LARGE_SCREEN} from '../../../states/helper/general.helper';

import './styles.scss';

export interface ILeftBarComponent {
    controller: LeftBarControllerBase;
}

export interface ISectionLeftBarItems {
    controllerLeftBarSection: ILeftBarSection;
    controllerLeftBar: LeftBarControllerBase;
}

@observer
export class SectionLeftBar extends TechseeBaseComponent<ISectionLeftBarItems> {
    renderInternal() {
        const {leftBarItems, alignItems, displaySeparator, sectionType, agentInfoController, windowWidth} =
            this.props.controllerLeftBarSection;

        return (
            <div
                className={classNames(
                    'section-left-bar ',
                    {separator: displaySeparator},
                    {'top-seperator': sectionType === DashboardSectionTypes.OBSERVER}
                )}>
                {leftBarItems.map((item: ILeftBarItemData, index: number) => {
                    if (agentInfoController && item.icon() === 'profile') {
                        const menuItems = agentInfoController.getContextMenuItems();
                        let paddingStyle = menuItems.length - 1;

                        paddingStyle *= windowWidth > LARGE_SCREEN ? LARGE_SCREEN_TOP_VALUE : SMALL_SCREEN_TOP_VALUE;

                        return (
                            <ContextMenuWrapper
                                key={index}
                                uid={'logout-wizard'}
                                itemComponent={TextWithIconMenuItem}
                                menuItemsData={menuItems}>
                                <style>{`.context-menu-wrapper .dropdown-menu {top: -${paddingStyle}px}`}</style>
                                <LeftBarItemComponent
                                    itemData={item}
                                    alignItems={alignItems}
                                    controller={this.props.controllerLeftBar}
                                    sectionType={sectionType}
                                />
                            </ContextMenuWrapper>
                        );
                    }

                    return (
                        <LeftBarItemComponent
                            itemData={item}
                            key={index}
                            alignItems={alignItems}
                            controller={this.props.controllerLeftBar}
                            sectionType={sectionType}
                        />
                    );
                })}
            </div>
        );
    }
}

@observer
export class LeftBarComponent extends TechseeBaseComponent<ILeftBarComponent> {
    renderInternal() {
        const {leftBarSections, cleanSelectedStates} = this.props.controller;

        return (
            <RootCloseWrapper onRootClose={cleanSelectedStates}>
                <div className={'app-left-bar'}>
                    <div className='app-left-bar-top'>
                        {leftBarSections
                            .filter((item: ILeftBarSection) => item.alignItems === AlignEnum.TOP)
                            .map((item: ILeftBarSection, index: number) => (
                                <SectionLeftBar
                                    controllerLeftBarSection={item}
                                    controllerLeftBar={this.props.controller}
                                    key={index}
                                />
                            ))}
                    </div>
                    <div className='app-left-bar-bottom'>
                        {leftBarSections
                            .filter((item: ILeftBarSection) => item.alignItems === AlignEnum.BOTTOM)
                            .map((item: ILeftBarSection, index: number) => (
                                <SectionLeftBar
                                    controllerLeftBarSection={item}
                                    controllerLeftBar={this.props.controller}
                                    key={index}
                                />
                            ))}
                    </div>
                </div>
            </RootCloseWrapper>
        );
    }
}
