import assign from 'lodash/assign';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {
    MouseButtonPosition,
    mouseClickType,
    MousePressStatus
} from '@techsee/techsee-common/lib/constants/remote-control.constants';
import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';

export class RemoteControlController {
    protected updateCursorPosition: any;
    private isMobile?: boolean;
    private isPhone?: boolean;
    private _frame: {[key: string]: number} = {
        left: 0,
        top: 0,
        width: 1,
        height: 1
    };
    private _pointer: any;
    private mousePressStatus: Nullable<MousePressStatus>;
    private mouseButtonPosition: MouseButtonPosition;
    private sharedScreenVideoContainer?: HTMLElement;
    private scrollScreen: any;
    private mouseClickType?: mouseClickType;
    private mouseClicks: number = 0;
    private clrTimeout?: NodeJS.Timeout;
    private updateMousePositionFrameLength = 15; // mouse updates per second >= standard refresh rate of 60Hz (16.66 ms)
    private isActive = false;
    private isLivePointerConnected = false;

    constructor(
        private readonly _tsChatApi: any,
        private _environmentService: ITsEnvironmentDetect
    ) {
        this.updateCursorPosition = debounce(this._updateCursorPosition.bind(this), 5);
        this._scrollScreenOnce = this._scrollScreenOnce.bind(this);
        this.scrollScreen = debounce(this._scrollScreenOnce, 5, {
            leading: true,
            trailing: false
        });
        this.mouseButtonPosition = MouseButtonPosition.Left;
        this.mousePressStatus = null;
    }

    public init(sharedScreenVideoElement: HTMLElement) {
        if (this.sharedScreenVideoContainer) {
            return;
        }

        this.sharedScreenVideoContainer = sharedScreenVideoElement;

        this.isMobile = this._environmentService.isMobile();
        this.isPhone = this._environmentService.isPhone();

        const [start, move, cancel, end] = ['mousedown', 'mousemove', 'mouseleave', 'mouseup'];

        this.sharedScreenVideoContainer.addEventListener(start, (e: any) => {
            if (!this.enableRemoteControl) {
                return;
            }
            this._mouseClick(e);
            const extraCursorData = {type: this.mouseClickType};

            this.mousePressStatus = MousePressStatus.Down;
            this.updateCursorPosition(e, extraCursorData);
        });

        const throttledMouseMoveHandler = throttle(
            (e) => this.updateCursorPosition(e),
            this.updateMousePositionFrameLength
        );

        this.sharedScreenVideoContainer.addEventListener(move, throttledMouseMoveHandler);

        this.sharedScreenVideoContainer.addEventListener(end, (e: any) => {
            if (!this.enableRemoteControl) {
                return;
            }
            this._handleClickType();
            this._mouseClick(e);
            const extraCursorData = {type: this.mouseClickType};

            this.mousePressStatus = MousePressStatus.Up;
            this.updateCursorPosition(e, extraCursorData);
        });

        const setBorder = (e: any) => {
            this._tsChatApi.client.agentHasRemoteControl &&
                !!this.sharedScreenVideoContainer &&
                this.isLivePointerConnected &&
                this.isActive &&
                this.sharedScreenVideoContainer.setAttribute('style', 'border: 5px solid #EF4F00');
        };
        const removeBorder = (e: MouseEvent) => {
            !!this.sharedScreenVideoContainer && this.sharedScreenVideoContainer.setAttribute('style', 'unset');
        };

        this.sharedScreenVideoContainer.addEventListener('mouseenter', setBorder.bind(this));
        this.sharedScreenVideoContainer.addEventListener('mouseleave', removeBorder.bind(this));

        const keyboardEventHandler = (event: {
            altKey: any;
            ctrlKey: any;
            shiftKey: any;
            key: any;
            keyCode: any;
            type: any;
        }) => {
            if (!(this.isActive && this.enableRemoteControl)) {
                return;
            }
            this._tsChatApi.setStatusFast('keyboard', {
                altKey: event.altKey,
                ctrlKey: event.ctrlKey,
                shiftKey: event.shiftKey,
                key: event.key,
                keyCode: event.keyCode,
                type: event.type
            });
        };

        //chrome focus issues, no key events were registered.
        //see https://stackoverflow.com/questions/26509291/jquery-keydown-event-not-working-in-chrome
        this.sharedScreenVideoContainer.tabIndex = 1;

        this.sharedScreenVideoContainer.addEventListener('keyup', keyboardEventHandler);
        this.sharedScreenVideoContainer.addEventListener('keydown', keyboardEventHandler);

        //TODO react best practice to address window
        window.addEventListener('wheel', this.scrollScreen);

        this.isActive = true;
    }

    _getElementCSSSize(element: HTMLElement) {
        const cs = getComputedStyle(element);
        const containerHeight = parseInt(cs.getPropertyValue('height'), 10);
        const containerWidth = parseInt(cs.getPropertyValue('width'), 10);
        //IMPORTANT height:width aspect ratio assumed to be 1:1 for this to work
        //Basically, we are solving an equation with one variable. if the ratio between width/height follows this
        //it means we have equal padding top/bot, otherwise we have padding left/right

        if (this.videoStreamHeight / this.videoStreamWidth <= containerHeight / containerWidth) {
            const videoWidth = containerWidth;
            const videoHeight = this.videoStreamHeight / (this.videoStreamWidth / videoWidth);

            return {videoWidth, videoHeight, containerHeight, containerWidth};
        }
        const videoHeight = containerHeight;
        const videoWidth = this.videoStreamWidth / (this.videoStreamHeight / videoHeight);

        return {videoWidth, videoHeight, containerHeight, containerWidth};
    }

    _getCursorPos = (e?: any): any => {
        if (this.sharedScreenVideoContainer) {
            const ev = e.originalEvent;

            const pageX = get(e, 'touches[0].pageX') || get(ev, 'changedTouches[0].pageX') || e.pageX;
            const pageY = get(e, 'touches[0].pageY') || get(ev, 'changedTouches[0].pageY') || e.pageY;

            const computedSize = this._getElementCSSSize(
                this.sharedScreenVideoContainer.getElementsByTagName('video')[0]
            );
            const scaleX = this.videoStreamWidth / computedSize.videoWidth;
            const scaleY = this.videoStreamHeight / computedSize.videoHeight;
            const boundingRect = this.sharedScreenVideoContainer
                .getElementsByTagName('video')[0]
                ?.getBoundingClientRect();

            //here we remove the padding calculated above ((computedSize.containerWidth - computedSize.videoWidth) / 2), if any.
            const x =
                (e.clientX - (computedSize.containerWidth - computedSize.videoWidth) / 2 - boundingRect.left) * scaleX;
            const y =
                (e.clientY - (computedSize.containerHeight - computedSize.videoHeight) / 2 - boundingRect.top) * scaleY;

            return {
                x,
                y,
                originalX: pageX,
                originalY: pageY
            };
        }
    };

    _mouseClick(e: any) {
        switch (e.button) {
            case 0:
                this.mouseButtonPosition = MouseButtonPosition.Left;
                break;
            case 1:
                this.mouseButtonPosition = MouseButtonPosition.Middle;
                break;
            case 2:
                this.mouseButtonPosition = MouseButtonPosition.Right;
                break;
            default:
                this.mouseButtonPosition = MouseButtonPosition.Left;
        }
    }

    _handleClickType() {
        if (this.mouseClicks === null) {
            this.mouseClicks = 0;
        }

        this.mouseClicks++;

        if (this.mouseClicks === 1) {
            this.mouseClickType = mouseClickType.Click;
            this.clrTimeout = setTimeout(() => {
                this.mouseClicks = 0;
            }, 200);
        } else {
            if (this.clrTimeout) {
                clearTimeout(this.clrTimeout);
                this.clrTimeout = undefined;
            }

            this.mouseClickType = mouseClickType.DblClick;
            this.mouseClicks = 0;
        }
    }

    _updateCursorPosition(e: any, extraCursorData: any) {
        if (!this.isActive || !this.isLivePointerConnected) {
            return;
        }

        const cursorPosition = this._getCursorPos(e);
        const cursorData = assign({...extraCursorData}, cursorPosition, {
            image: {
                width: this.videoStreamWidth,
                height: this.videoStreamHeight
            },
            mousePressStatus: this.mousePressStatus,
            buttonPosition: this.mouseButtonPosition
        });

        if (this._pointer) {
            this._displayLivePointerForAgent(cursorPosition);
        }

        this._tsChatApi.setStatusFast('cursor', cursorData);
    }

    _displayLivePointerForAgent(cursorPosition: {[key: string]: number}) {
        const maxPointerPositionX = Math.min(cursorPosition.originalX, this._frame.width + this._frame.left);

        const updatedCursor = {
            x: Math.max(this._frame.left, maxPointerPositionX) - this._frame.left,
            y: Math.max(0, Math.min(cursorPosition.originalY, this._frame.top + this._frame.height) - this._frame.top)
        };

        this._pointer
            .css({
                left: Math.round(updatedCursor.x) + 'px',
                top: Math.round(updatedCursor.y) + 'px'
            })
            .show();
    }

    _scrollScreenOnce(event: any) {
        if (!(this.isActive && this.enableRemoteControl)) {
            return;
        }
        const scrollData = {x: 0, y: (event.deltaY + event.wheelDeltaY) / 2};

        this._tsChatApi.setStatusFast('mouseScroll', scrollData, true);
    }

    get videoStreamWidth() {
        return this.sharedScreenVideoContainer?.getElementsByTagName('video')[0]?.videoWidth || 0;
    }

    get videoStreamHeight() {
        return this.sharedScreenVideoContainer?.getElementsByTagName('video')[0]?.videoHeight || 0;
    }

    setIsActive(val: boolean) {
        this.isActive = val;
        if (!this.isActive) {
            this.sharedScreenVideoContainer?.setAttribute('style', 'unset');
        }
    }

    setLivePointerConnectedStatus(val: boolean) {
        this.isLivePointerConnected = val;
    }

    get enableRemoteControl() {
        return get(this._tsChatApi, 'accountSettings.enableRemoteControl', false);
    }
}
