import {IReactComponent} from 'mobx-react';
import {BasicWizardController} from '@techsee/techsee-ui-common/lib/wizard/controllers/BasicWizardController';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {DeepReadonly} from '@techsee/techsee-common/lib/core/data-structures';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';

import {
    ChangeNumberOrigins,
    IInviteFlowManager,
    IInviteWizardController,
    InviteStepsEnum,
    InviteWizardHeaderStep
} from '../_contracts/InviteContracts';
import {InviteState} from '../_contracts/InviteState';
import {GeneralSettings, InviteMethodSettings} from '../../../models/AccountSettings';
import {IInviteMethodController} from './components/invite-method-form/controller';
import {IChangeNumberController} from './components/change-number/controller';
import {IMainLayoutController} from '../../../layouts/main-layout/layout.controller';
import {IAgentSettingsController} from '../../../layouts/main-layout/agent-settings/controller';
import {ITranslate} from '../../../services/LocalizationService';
import {IDbRooms, IDbUser} from '../../../services/AngularServices/AngularServices';

import {InviteGenericStep, InviteStepParams} from './steps/_InviteGenericStep';
import {InviteInfoStepController} from './steps/invite-info-step/controller';
import {InviteByCodeStepController} from './steps/invite-by-code-step/controller';
import {TermsStepController} from './steps/terms-step/controller';

import {CameraStep} from './steps/CameraStep';
import {PreCameraStep} from './steps/PreCameraStep';
import {StepSpeakerTabs} from './steps/SpeakerStep';
import {SmsStepController} from './steps/SendSmsStep';
import {ScreenShareCaptureGuidanceStep} from './steps/ScreenShareCaptureGuidanceStep';
import {VideoApplicationGuidanceTosStep} from './steps/VideoApplicationGuidanceTosStep';
import {VideoApplicationGuidanceCameraDialogStep} from './steps/VideoApplicationGuidanceCameraDialogStep';

import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';
import {SpeakerWifiStepToolbar} from './steps/_Shared';
import {StepWifiTabs} from './steps/WifiStep';
import {IJoinOfflineController} from './components/join-offline-form/controller';
import {IOfflineSessionsController} from './components/offline-sessions/controller';
import {IPendingRoomSessionsController} from './components/PendingRoomSessions/controller';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {ScreenShareGuidanceStep} from './steps/ScreenShareGuidanceStep';
import {EmailStepController} from './steps/EmailStep';
import {AppShareGuidance} from './steps/AppShareGuidance';
import {IAgentPerformanceController} from '../../../components/agent-performance/controller';
import {IObservableController} from './components/observable-form/controller';
import {IObservableSessionsController} from './components/observable-sessions/controller';
import {ISimpleModalController} from '../../../components/simple-modal/controller';

export class InviteWizardController extends BasicWizardController implements IInviteWizardController {
    private _flowManager: IInviteFlowManager;

    private _changeNumberController: IChangeNumberController;

    private _mainLayoutController: IMainLayoutController;

    private _agentSettingsController: IAgentSettingsController;

    private _agentPerformanceController: Nullable<IAgentPerformanceController>;

    readonly unauthorizedModal: ISimpleModalController;

    private _accGeneralSettings: GeneralSettings;

    private _accInviteSettings: InviteMethodSettings;

    private _browserUtilsService: IBrowserUtilsService;

    private _domUtilsService: IDomUtilsService;

    private _dbRoomsService: IDbRooms;

    private _inviteState: DeepReadonly<InviteState>;

    private inviteMethodStep: InviteInfoStepController;

    private speakerStep: InviteGenericStep;

    private inviteByCodeStep: InviteGenericStep;

    private wifiStep: InviteGenericStep;

    private smsStep: InviteGenericStep;

    private emailStep: InviteGenericStep;

    private readonly _currentUser: any;

    private termsAndConditionsStep: InviteGenericStep;

    private preCameraApprovalStep: InviteGenericStep;

    private cameraApprovalStep: InviteGenericStep;

    private appShareGuidanceStep: InviteGenericStep;

    private screenShareGuidanceStep: InviteGenericStep;

    private screenShareCaptureGuidanceStep: InviteGenericStep;

    private VideoApplicationGuidanceTosStep: InviteGenericStep;

    private VideoApplicationGuidanceCameraDialogStep: InviteGenericStep;

    readonly wizardHeaderGroups: InviteWizardHeaderStep[];

    constructor(
        flowManager: IInviteFlowManager,
        mainLayoutController: IMainLayoutController,
        inviteMethodController: IInviteMethodController,
        joinOfflineController: IJoinOfflineController,
        offlineSessionsController: IOfflineSessionsController,
        pendingRoomSessionsController: IPendingRoomSessionsController,
        changeNumberController: IChangeNumberController,
        translate: ITranslate,
        accGeneralSettings: GeneralSettings,
        accInviteSettings: InviteMethodSettings,
        agentSettingsController: IAgentSettingsController,
        browserUtilsService: IBrowserUtilsService,
        currentUser: any,
        dbRoomsService: IDbRooms,
        inviteState: DeepReadonly<InviteState>,
        domUtilsService: IDomUtilsService,
        agentPerformanceController: Nullable<IAgentPerformanceController>,
        observableController: IObservableController,
        observableSessionsController: IObservableSessionsController,
        dbUserService: IDbUser,
        unauthorizedModal: ISimpleModalController
    ) {
        super();

        this.wizardHeaderGroups = [
            {
                title: translate('REACT.INVITE.CUSTOMER_INFO.STEP_NAME'),
                steps: [InviteStepsEnum.InviteMethod]
            },
            {
                title: translate('REACT.INVITE.SPEAKER_WIFI.STEP.NAME'),
                steps: [
                    InviteStepsEnum.SpeakerGuidance,
                    InviteStepsEnum.WifiGuidance,
                    InviteStepsEnum.SendSmsFlow,
                    InviteStepsEnum.InviteByCode
                ]
            },
            {
                title: translate('REACT.INVITE.PERMISSIONS.STEP.NAME'),
                steps: [
                    InviteStepsEnum.TermsApproval,
                    InviteStepsEnum.PreCameraApproval,
                    InviteStepsEnum.CameraApproval,
                    InviteStepsEnum.ScreenShareCaptureGuidance,
                    InviteStepsEnum.VideoApplicationGuidance
                ]
            }
        ];

        this._flowManager = flowManager;
        this.unauthorizedModal = unauthorizedModal;
        this._inviteState = inviteState;
        this._changeNumberController = changeNumberController;
        this._agentSettingsController = agentSettingsController;
        this._agentPerformanceController = agentPerformanceController;
        this._mainLayoutController = mainLayoutController;
        this._accGeneralSettings = accGeneralSettings;
        this._accInviteSettings = accInviteSettings;
        this._browserUtilsService = browserUtilsService;
        this._domUtilsService = domUtilsService;
        this._dbRoomsService = dbRoomsService;
        this._currentUser = currentUser;
        this.bindPublicMethods();

        const createGenericStep = (type: InviteStepsEnum, content: IReactComponent, toolbar?: IReactComponent) => {
            const params: InviteStepParams = {
                stepType: type,
                content: content,
                toolbar: toolbar
            };

            return new InviteGenericStep(this._flowManager, this._mainLayoutController, domUtilsService, params);
        };

        this.inviteMethodStep = new InviteInfoStepController(
            flowManager,
            mainLayoutController,
            inviteMethodController,
            joinOfflineController,
            offlineSessionsController,
            pendingRoomSessionsController,
            accGeneralSettings,
            this._accInviteSettings,
            this._domUtilsService,
            observableController,
            observableSessionsController,
            this._currentUser,
            dbUserService,
            this._browserUtilsService
        );

        this.smsStep = new SmsStepController(flowManager, mainLayoutController, domUtilsService);
        this.emailStep = new EmailStepController(flowManager, mainLayoutController, domUtilsService);
        this.termsAndConditionsStep = new TermsStepController(flowManager, mainLayoutController, domUtilsService);
        this.inviteByCodeStep = new InviteByCodeStepController(flowManager, mainLayoutController, domUtilsService);
        this.speakerStep = createGenericStep(InviteStepsEnum.SpeakerGuidance, StepSpeakerTabs, SpeakerWifiStepToolbar);
        this.wifiStep = createGenericStep(InviteStepsEnum.WifiGuidance, StepWifiTabs, SpeakerWifiStepToolbar);
        this.preCameraApprovalStep = createGenericStep(InviteStepsEnum.PreCameraApproval, PreCameraStep);
        this.cameraApprovalStep = createGenericStep(InviteStepsEnum.CameraApproval, CameraStep);
        this.appShareGuidanceStep = createGenericStep(InviteStepsEnum.AppShareGuidance, AppShareGuidance);
        this.screenShareGuidanceStep = createGenericStep(InviteStepsEnum.ScreenShareGuidance, ScreenShareGuidanceStep);
        this.screenShareCaptureGuidanceStep = createGenericStep(
            InviteStepsEnum.ScreenShareCaptureGuidance,
            ScreenShareCaptureGuidanceStep
        );
        this.VideoApplicationGuidanceTosStep = createGenericStep(
            InviteStepsEnum.VideoApplicationGuidance,
            VideoApplicationGuidanceTosStep
        );
        this.VideoApplicationGuidanceCameraDialogStep = createGenericStep(
            InviteStepsEnum.VideoApplicationGuidance,
            VideoApplicationGuidanceCameraDialogStep
        );

        this.addStepsToWizard();

        if (
            this._browserUtilsService.getFromLocalStorage('checkForcePasswordChange') &&
            this._currentUser.forcePasswordChange
        ) {
            this._agentSettingsController.openAgentPasswordSettings();
        }
        this._browserUtilsService.saveToLocalStorage('checkForcePasswordChange', false);
    }

    get enableNewInviteSpeakersStep() {
        return this._accGeneralSettings.enableNewInviteSpeakersStep;
    }

    get enableNewInviteWifiStep() {
        return this._accGeneralSettings.enableNewInviteWifiStep;
    }

    get displayAgentPerformance() {
        return !!this._agentPerformanceController && this.currentStep === this.inviteMethodStep;
    }

    onChangeNumberClick(): void {
        this._changeNumberController.showChangeNumberDialog(ChangeNumberOrigins.MANUAL);
    }

    isOnWifiStep(): boolean {
        return this.currentStep === this.wifiStep;
    }

    isOnSpekersStep(): boolean {
        return this.currentStep === this.speakerStep;
    }

    isOnSmsStep(): boolean {
        return this.currentStep === this.smsStep;
    }

    private showCorrectStepToGo() {
        switch (this.addressType) {
            case AddressTypesEnum.EMAIL:
                return this.emailStep;
            default:
                return this.smsStep;
        }
    }

    onNextClick(): void {
        const goTo = this.showCorrectStepToGo();

        if (this.isOnWifiStep()) {
            this._dbRoomsService.setReportedField(this._inviteState.sessionInfo.sessionRoomId, {
                data: {event: {key: 'disableWifiNextTimestamp', value: new Date()}}
            });

            this.goToStep(goTo);

            return;
        }

        if (this.isOnSpekersStep()) {
            this._dbRoomsService.setReportedField(this._inviteState.sessionInfo.sessionRoomId, {
                data: {event: {key: 'enableSpeakerNextTimestamp', value: new Date()}}
            });

            if (!this.enableNewInviteWifiStep) {
                this.goToStep(goTo);

                return;
            }
        }

        this.goNext();
    }

    onBackClick(): void {
        this.goBack();
    }

    displayInviteForm() {
        this.goToStep(this.inviteMethodStep);
    }

    displaySpeakerGuidance() {
        if (!this.enableNewInviteSpeakersStep) {
            this.displayWifiGuidance();
        } else {
            this.goToStep(this.speakerStep);
        }
    }

    displayWifiGuidance() {
        if (!this.enableNewInviteWifiStep) {
            this.addressType === AddressTypesEnum.EMAIL ? this.displayEmailFlow() : this.displaySmsFlow();
        } else {
            this.goToStep(this.wifiStep);
        }
    }

    get addressType() {
        return this._flowManager.inviteState.inviteMethodInfo.addressType;
    }

    displaySmsFlow() {
        this.goToStep(this.smsStep);
    }

    displayEmailFlow() {
        this.goToStep(this.emailStep);
    }

    displayTosGuidance() {
        if (!this._flowManager.inviteState.sessionInfo.isOffline) {
            this.goToStep(this.termsAndConditionsStep);
        }
    }

    displayPreCameraGuidance() {
        this.goToStep(this.preCameraApprovalStep);
    }

    displayCameraGuidance() {
        this.goToStep(this.cameraApprovalStep);
    }

    displayAppShareTOSGuidance() {
        this.goToStep(this.appShareGuidanceStep);
    }

    displayScreenShareTOSGuidance() {
        this.goToStep(this.screenShareGuidanceStep);
    }

    displayScreenShareCaptureGuidance() {
        this.goToStep(this.screenShareCaptureGuidanceStep);
    }

    displayVideoApplicationTOSGuidance() {
        this.goToStep(this.VideoApplicationGuidanceTosStep);
    }

    displayVideoApplicationCameraAudioDialog() {
        this.goToStep(this.VideoApplicationGuidanceCameraDialogStep);
    }

    displayInviteByCode() {
        this.goToStep(this.inviteByCodeStep);
    }

    protected afterStepLeft(leftStep: InviteGenericStep, newStep: InviteGenericStep): void {
        super.afterStepLeft(leftStep, newStep);
        this._changeNumberController.hideChangeNumberDialog();
    }

    protected afterStepEnter(currentStep: InviteGenericStep, prevStep: Nullable<InviteGenericStep>): void {
        super.afterStepEnter(currentStep, prevStep);
        if (currentStep !== this.inviteMethodStep && prevStep !== null) {
            this._flowManager.inviteLogger.inviteWizardStepChanged(currentStep.stepType, prevStep.stepType);
        }
    }

    private addStepsToWizard() {
        this.addStep(this.inviteMethodStep);
        this.enableNewInviteSpeakersStep && this.addStep(this.speakerStep);
        this.enableNewInviteWifiStep && this.addStep(this.wifiStep);
        this.addStep(this.smsStep);
        this.addStep(this.emailStep);
        this.addStep(this.termsAndConditionsStep);
        this.addStep(this.preCameraApprovalStep);
        this.addStep(this.cameraApprovalStep);
        this.addStep(this.appShareGuidanceStep);
        this.addStep(this.screenShareGuidanceStep);
        this.addStep(this.screenShareCaptureGuidanceStep);
        this.addStep(this.VideoApplicationGuidanceTosStep);
        this.addStep(this.VideoApplicationGuidanceCameraDialogStep);
        this.addStep(this.inviteByCodeStep);
    }

    private bindPublicMethods() {
        this.displayInviteForm = this.displayInviteForm.bind(this);
        this.displaySpeakerGuidance = this.displaySpeakerGuidance.bind(this);
        this.displayWifiGuidance = this.displayWifiGuidance.bind(this);
        this.displaySmsFlow = this.displaySmsFlow.bind(this);
        this.displayEmailFlow = this.displayEmailFlow.bind(this);
        this.displayTosGuidance = this.displayTosGuidance.bind(this);
        this.displayPreCameraGuidance = this.displayPreCameraGuidance.bind(this);
        this.displayCameraGuidance = this.displayCameraGuidance.bind(this);
        this.displayScreenShareCaptureGuidance = this.displayScreenShareCaptureGuidance.bind(this);
        this.displayVideoApplicationTOSGuidance = this.displayVideoApplicationTOSGuidance.bind(this);
        this.displayVideoApplicationCameraAudioDialog = this.displayVideoApplicationCameraAudioDialog.bind(this);
    }
}
