import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ITranslate} from '../../services/LocalizationService';
import {ISessionHistoryController} from './controller';
import {GenericForm} from '../common-forms/form';

import './styles.scss';

export interface ISessionHistory {
    ctrl: ISessionHistoryController;
    translate: ITranslate;
    onSubmit?: () => void;
}

@observer
export class SessionHistory extends TechseeBaseComponent<ISessionHistory> {
    renderInternal() {
        const {ctrl, translate} = this.props;

        return (
            <GenericForm
                ctrl={ctrl.formController}
                translate={translate}
                formErrorText={'INVITE.VIEW.HISTORY_SEARCH.NO_RESULTS'}
                labelText={'INVITE.VIEW.HISTORY_SEARCH.INVITE_HISTORY'}
                buttonText={'INVITE.VIEW.SEARCH'}
            />
        );
    }
}
