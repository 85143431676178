'use strict';

import {EventEmitter} from 'events';

import {SCANNERS} from './ts-scan.settings.js';

/* Abstract */
class Scanner {
    constructor({name, endpoint, ...settings}, roomId, db, options = {}) {
        //We use `this.constructor` instead of `new.target` because it's not supported in IE11 (even after babel compilation).
        if (this.constructor === Scanner) {
            throw new TypeError('Scanner is abstract; cannot be instantiated');
        }

        this._db = db;

        this._name = name;
        this._endpoint = endpoint;

        this._roomId = roomId;
        this._options = options;

        this.settings = settings;
    }

    get type() {
        return this._name;
    }

    scan(image, takenFrom, notifyExternal, imageUrl, thumbnail) {
        return this._db.Scanner.scan(this._endpoint, {
            data: {
                image,
                thumbnail,
                options: {notifyExternal, imageUrl, ...this._options},
                meta: {
                    takenFrom,
                    roomId: this._roomId
                }
            }
        }).then((response) => response.data);
    }
}

class TextScanner extends Scanner {
    constructor(settings, roomId, db, scanner = SCANNERS.TEXT, options = {}) {
        options.language = settings.language;

        super(scanner, roomId, db, options);
    }
}

export class TsScanService extends EventEmitter {
    constructor(db, tsCanvasAnnotate) {
        'ngInject';

        super();

        this.db = db;

        tsCanvasAnnotate.on('areaSelected', (...args) => {
            this.emit('areaSelected', ...args);
        });
    }

    getScanners(accountData, roomData) {
        if (!accountData.protectedSettings || !accountData.protectedSettings.imageAnalysis) {
            return [];
        }

        const scanners = [];
        const settings = accountData.settings;
        let roomId = '';

        if (roomData) {
            roomId = roomData._id;
        }

        if (settings.textScanner) {
            scanners.push(new TextScanner(settings, roomId, this.db));
        }

        return scanners;
    }
}
