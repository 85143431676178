import React from 'react';
import {IMenuItemData} from '@techsee/techsee-ui-common/lib/menus/context-menu';
import {ITranslate} from '../../../services/LocalizationService';
import {EventLogConstant} from '../../../../constants/events/event-log.constant';
import {action, computed, observable} from 'mobx';
import {onCopyClick} from '@techsee/techsee-common/lib/utils/window-utils';
import {
    IMainTopBarController,
    MainTopBarControllerBase,
    TempAccountSettings
} from '../../../layouts/main-layout/main-top-bar/controller.base';
import {DashboardState} from '../_contracts/DashboardState';
import {ILocationInfoController, LocationInfoController} from './components/location-info/controller';
import {liveSdkAppIDs} from '@techsee/techsee-common/lib/constants/account.constants';
import {AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';

interface ICallback {
    (): void;
}

export interface TopBarGeneralOptions {
    nativeAppId?: string;
    usingApplication?: boolean;
    isDesktopSharing?: boolean;
}

export interface IDashboardTopBarController extends IMainTopBarController<DashboardState> {
    readonly isOfflineRoom: boolean;
    readonly showImagesManagement: boolean;
    readonly showSharingButton: boolean;
    readonly showSaveButton: boolean;
    readonly showSendButton: boolean;
    readonly showSaveInHistoryButton: boolean;
    readonly disabledSharingButton: boolean;
    readonly disabledSendButton: boolean;
    readonly disabledSaveButton: boolean;
    readonly shouldOpenShareMenu: boolean;
    readonly usingApplication: boolean;
    readonly options: TopBarGeneralOptions;
    readonly applicationId: string;
    readonly clientType: string;
    readonly clientTypeIcon: string;
    readonly enableMobileGeolocation: boolean;
    readonly locationInfoController: ILocationInfoController;
    readonly clientLanguage: string;
    readonly isObserve: boolean;
    readonly isOfflineMode: boolean;

    onSaveResource: ICallback;
    onSendImage: ICallback;
    onCopyToClipboardClicked: ICallback;
    onDownloadClicked: ICallback;
    onEmailClicked: ICallback;
    onLeaveOfflineRoom: ICallback;
    onSaveImageCloud: ICallback;

    onAbortSession(): void;

    onCopyClick(str: string): void;

    onShareClosed(e: React.FormEvent<HTMLInputElement>): void;

    getContextMenuItems(): IMenuItemData[];
}

export class DashboardTopBarController
    extends MainTopBarControllerBase<DashboardState>
    implements IDashboardTopBarController
{
    private _onAbort: (preventRedirection: boolean, closedBy: string) => void;

    private _dashboardState: DashboardState;

    @observable private _locationInfoController: ILocationInfoController;

    @observable private _contextMenuItems?: IMenuItemData[];

    @observable private _shouldOpenShareMenu: boolean = false;

    @observable private _options: TopBarGeneralOptions = {};

    @observable showImagesManagement: boolean = false;

    @observable showSendButton: boolean = false;

    @observable showSaveButton: boolean = false;

    @observable showSharingButton: boolean = false;

    @observable showSaveInHistoryButton: boolean = false;

    @observable disabledSharingButton: boolean = false;

    @observable disabledSaveButton: boolean = false;

    @observable disabledSendButton: boolean = false;

    onSaveResource: ICallback;

    onSendImage: ICallback;

    onCopyToClipboardClicked: ICallback;

    onDownloadClicked: ICallback;

    onEmailClicked: ICallback;

    onLeaveOfflineRoom: ICallback;

    onSaveImageCloud: ICallback;

    // TODO: To avoid max prams issue, better to pass an object as constructor argument.

    constructor(
        accountSettings: TempAccountSettings,
        dashboardState: DashboardState,
        translate: ITranslate,
        onAbort: (preventRedirection: boolean, closedBy: string) => void,
        sendImage: () => void = () => undefined,
        saveResource: () => void = () => undefined,
        copyToClipboardClicked: () => void = () => undefined,
        emailClicked: () => void = () => undefined,
        downloadClicked: () => void = () => undefined,
        saveImageCloud: () => void = () => undefined,
        leaveOfflineRoom: () => void = () => undefined
    ) {
        super(accountSettings, dashboardState, translate);

        this._onAbort = onAbort;
        this._dashboardState = dashboardState;

        this._locationInfoController = new LocationInfoController(dashboardState, this.translate);

        this.onCopyClick = this.onCopyClick.bind(this);
        this.onShareClosed = this.onShareClosed.bind(this);
        this.onAbortSession = this.onAbortSession.bind(this);

        this.onSendImage = sendImage;
        this.onCopyToClipboardClicked = copyToClipboardClicked;
        this.onDownloadClicked = downloadClicked;
        this.onEmailClicked = emailClicked;
        this.onLeaveOfflineRoom = leaveOfflineRoom;
        this.onSaveImageCloud = saveImageCloud;
        this.onSaveResource = saveResource;
    }

    get isOfflineRoom() {
        return this.stateObject.isOfflineRoom;
    }

    get isObserve() {
        return this.stateObject.isObserve;
    }

    get isOfflineMode() {
        return this.stateObject.isOfflineMode;
    }

    @computed
    get options() {
        return this._options;
    }

    @computed
    get shouldOpenShareMenu() {
        return this._shouldOpenShareMenu;
    }

    @computed
    get clientType() {
        return this._options.isDesktopSharing ? `${this.clientOSName} ${this.clientOSVersion}` : this.clientDevice;
    }

    get applicationId() {
        return this.getAppId(liveSdkAppIDs, this._options.nativeAppId!);
    }

    @computed
    get usingApplication() {
        return !this._options.isDesktopSharing && this._options.usingApplication!;
    }

    @computed
    get clientTypeIcon() {
        const clientTypeIcon = this.inviteMethodInfo.addressType === AddressTypesEnum.EMAIL ? 'email' : 'mobile';

        return this._options.isDesktopSharing ? 'desktop-sharing' : clientTypeIcon;
    }

    @computed
    get locationInfoController() {
        return this._locationInfoController;
    }

    @computed
    get enableMobileGeolocation() {
        return this.accountSettings.generalSettings.enableMobileGeolocation;
    }

    @computed
    get clientLanguage() {
        return this.inviteMethodInfo.clientLanguage;
    }

    onCopyClick(str: string): void {
        onCopyClick(str);
    }

    onAbortSession() {
        this._onAbort(false, EventLogConstant.closedByTypes.dashboard);
    }

    @action
    getContextMenuItems(): IMenuItemData[] {
        this._contextMenuItems = [];

        this._contextMenuItems.push({
            data: {text: this.translate('REACT.DASHBOARD.VIEW.COPY_TO_CLIPBOARD')},
            onClick: this.onCopyToClipboardClicked
        });

        if (this.accountSettings.generalSettings.localImageSaving) {
            this._contextMenuItems.push({
                data: {text: this.translate('REACT.DASHBOARD.VIEW.DOWNLOAD')},
                onClick: this.onDownloadClicked
            });
        }

        if (this.showSaveInHistoryButton) {
            this._contextMenuItems.push({
                data: {text: this.translate('REACT.DASHBOARD.VIEW.SAVE_IN_HISTORY')},
                onClick: this.onSaveImageCloud
            });
        }

        if (this.accountSettings.generalSettings.emailImageSharing) {
            this._contextMenuItems.push({
                data: {text: this.translate('REACT.DASHBOARD.VIEW.EMAIL')},
                onClick: this.onEmailClicked
            });
        }

        return this._contextMenuItems;
    }

    @action
    onShareClosed(e: React.FormEvent<HTMLInputElement>) {
        const eventClassName = (e.target as HTMLInputElement).className;

        if (
            eventClassName &&
            typeof eventClassName.includes === 'function' &&
            eventClassName.includes('floating-toolbar')
        ) {
            return;
        }

        this._shouldOpenShareMenu = false;
    }

    @action
    setOptions(options: Partial<TopBarGeneralOptions>) {
        this._options = {
            ...this._options,
            ...options
        };
    }
}
