import {action} from 'mobx';
import {IWebRtcDetector} from '@techsee/techsee-client-infra/lib/services/DetectWebRtcService';
import {ClientInfo} from '@techsee/techsee-common/lib/data-contracts/ClientInfo';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {getBrowserInfo} from '@techsee/techsee-client-infra/lib/shared/browser-info';
import {
    GeneralSettings,
    InviteMethodSettings,
    IPasswordSettings,
    ISessionSettings,
    VirtualBackgroundItem
} from '../../models/AccountSettings';
import {SessionService} from '../../services/Session/SessionService';
import {SmsService} from '../../services/SmsService';
import {AgentInfoController} from '../../layouts/main-layout/agent-info-menu/controller';
import {AgentSettingsController} from '../../layouts/main-layout/agent-settings/controller';
import {AppState, IAppUser} from '../../app.state';
import {getRootStore, createFieldModel} from '../../app.bootstrap';
import {InviteFlowManager} from './invite.flow.manager';
import {InviteEventsLogger} from './invite.flow.logger';
import {InviteStateManager} from './invite.persister';
import {WizardLeftBarController} from './left-bar/controller';
import {AgentPerformanceController} from '../../components/agent-performance/controller';
import {
    RolesConstant,
    VideoFilterType,
    VideoBackgroundOptions
} from '@techsee/techsee-common/lib/constants/account.constants';
import {LookupNumberController} from './invite-wizard/components/lookup-number/controller';

import get from 'lodash/get';
import {AccountSwitchController} from '../../layouts/main-layout/account-switch/controller';
import {SelectVideoPublisherController} from '../../components/select-video-publisher/controller';
import {SimpleModalController} from '../../components/simple-modal/controller';
import {getDashboardLanguage} from '../helper/general.helper';

interface Stores {
    [name: string]: object;
}

export class InviteStores {
    public stores?: Stores;

    private readonly _currentAccount: any;

    private readonly _currentUser: any;

    private _accountSettings: any;

    //We use this type of injection, because ng-annotate-loader not runs on TypeScript files.
    static $inject = ['$scope', 'accountData', 'currentUser'];

    constructor($scope: any, accountData: any, currentUser: any) {
        this._currentAccount = accountData;
        this._currentUser = currentUser;

        this.initStores().then((stores: Stores) => {
            $scope.$apply(() => (this.stores = stores));
        });
    }

    private async initStores(): Promise<Stores> {
        const stores: Stores = {};

        const {
            appState,
            applicationService,
            localizationService,
            dbShortUrlService,
            mobileClientURL,
            redirectionService,
            dbSmsService,
            dbRoomsService,
            dbObservationService,
            dbHistoryService,
            dbSsoService,
            dbVideoRecordingsService,
            domUtilsService,
            dbUserService,
            socketManager,
            browserUtilsService,
            eventsLogService,
            webRtcDetector,
            dbReportsService,
            accountSocketService,
            authExpiryService,
            dbEmailService,
            environmentService,
            dbOttService,
            authService
        } = getRootStore();

        this.updateAppState(appState);
        this.updateAccountSettings();

        localizationService.setAccountData(appState.accountId, appState.dashboardLanguage);

        const inviteFlowLogger = new InviteEventsLogger(eventsLogService, appState.accountId, appState.agentId, {
            keepCustomerMobile:
                this._accountSettings.generalSettings && this._accountSettings.generalSettings.keepCustomerMobile,
            keepCustomerEmailAddress:
                this._accountSettings.generalSettings && this._accountSettings.generalSettings.keepCustomerEmailAddress
        });
        const clientInfo: ClientInfo = await this.getClientInfo(webRtcDetector);
        const sessionService = new SessionService(
            clientInfo,
            dbRoomsService,
            dbShortUrlService,
            this._accountSettings.sessionSettings,
            socketManager.sessionSocket,
            inviteFlowLogger
        );

        const smsService = new SmsService(
            dbRoomsService,
            dbSmsService,
            this._accountSettings.smsSettings,
            inviteFlowLogger,
            this._accountSettings.inviteMethodSettings.allowSendByWhatsApp
        );
        const inviteStateManager = new InviteStateManager(browserUtilsService);

        await localizationService.init();

        let agentPerformanceController = null;
        let selectVideoPublisherController = null;

        if (
            this._accountSettings.inviteMethodSettings.enableAgentPerformance &&
            [RolesConstant.USER, RolesConstant.MANAGER].includes(this._currentUser.role) &&
            !browserUtilsService.getFromSessionStorage('shouldNotDisplayAgentPerformance')
        ) {
            const enableCustomerSurvey =
                get(this._currentAccount, 'settings.customerSurvey.enabled') &&
                get(this._currentAccount, 'protectedSettings.allowCustomerSurveys');

            const enableAgentSurvey =
                get(this._currentAccount, 'settings.enableSurveys') &&
                get(this._currentAccount, 'protectedSettings.feedbackSurveys');

            agentPerformanceController = new AgentPerformanceController(
                localizationService.translate,
                dbReportsService,
                this._currentUser.role === RolesConstant.USER,
                browserUtilsService,
                authExpiryService,
                eventsLogService,
                enableCustomerSurvey,
                enableAgentSurvey,
                this._currentUser
            );
            agentPerformanceController.setElementWrapper('app-middle-area');

            agentPerformanceController.init();
        }

        if (
            this._currentUser.role === RolesConstant.FIELD_SERVICES_ENGINEER &&
            get(this._currentAccount, 'protectedSettings.enableFieldServices')
        ) {
            selectVideoPublisherController = new SelectVideoPublisherController(localizationService.translate);
        }

        const agentSettingsController = new AgentSettingsController(
            localizationService.translate,
            createFieldModel,
            dbUserService,
            this._currentUser,
            appState,
            this._accountSettings.passwordSettings,
            this._accountSettings.generalSettings,
            browserUtilsService,
            this._currentAccount.settings.language || 'en_US'
        );

        const lookupNumberController = new LookupNumberController(
            localizationService.translate,
            eventsLogService,
            this._accountSettings.inviteMethodSettings.keepCustomerMobile
        );

        const unauthorizedModal = new SimpleModalController(
            'unauthorized-modal',
            '',
            localizationService.translate('REACT.INVITE.VIEW.UNAUTHORIZED_ERROR_CONTENT'),
            '',
            localizationService.translate('REACT.INVITE.VIEW.UNAUTHORIZED_ERROR_BUTTON')
        );

        const inviteFlowManager = new InviteFlowManager(
            this._accountSettings,
            inviteStateManager,
            inviteFlowLogger,
            localizationService,
            sessionService,
            smsService,
            redirectionService,
            dbHistoryService,
            dbVideoRecordingsService,
            dbSsoService,
            dbRoomsService,
            dbObservationService,
            appState.accountId,
            browserUtilsService,
            agentSettingsController,
            this._currentUser,
            domUtilsService,
            mobileClientURL,
            appState,
            agentPerformanceController,
            lookupNumberController,
            accountSocketService,
            dbEmailService,
            dbUserService,
            selectVideoPublisherController,
            unauthorizedModal,
            environmentService,
            dbOttService,
            authService
        );

        const agentInfoController = new AgentInfoController(
            applicationService,
            this._accountSettings.generalSettings,
            localizationService.translate,
            redirectionService,
            agentSettingsController,
            appState
        );

        const switchUserController = new AccountSwitchController(
            applicationService,
            this._currentUser,
            this._currentAccount.companyName,
            dbUserService
        );

        const leftBarController = new WizardLeftBarController(
            localizationService.translate,
            environmentService,
            this._accountSettings.generalSettings,
            this._currentUser,
            agentInfoController,
            switchUserController
        );

        await inviteFlowManager.onReady;

        const defineStore = this.defineStores(stores);

        defineStore('localizationService', localizationService);
        defineStore('inviteFlowManager', inviteFlowManager);
        defineStore('inviteTopBarController', inviteFlowManager.inviteTopBarController);
        defineStore('changeNumberController', inviteFlowManager.changeNumberController);
        defineStore('lookupNumberController', lookupNumberController);

        defineStore('inviteWizardController', inviteFlowManager.inviteWizardController);
        defineStore('sessionAbortController', inviteFlowManager.sessionAbortController);
        defineStore('roomInfoController', inviteFlowManager.roomInfoController);
        defineStore('mainLayoutController', inviteFlowManager.mainLayoutController);

        defineStore('applicationState', appState);
        defineStore('leftBarController', leftBarController);
        defineStore('selectVideoPublisherController', selectVideoPublisherController);
        defineStore('agentPerformanceController', agentPerformanceController);
        defineStore('agentInfoController', agentInfoController);
        defineStore('agentSettingsController', agentSettingsController);
        defineStore('sessionHistoryController', inviteFlowManager.sessionHistoryController);
        defineStore('startWithModesController', inviteFlowManager.startWithModesController);
        defineStore('vjHistoryFilterController', inviteFlowManager.vjHistoryFilterController);
        defineStore('wifiAnalyzerFilterController', inviteFlowManager.wifiAnalyzerFilterController);

        return stores;
    }

    @action
    private updateAppState(appState: AppState): void {
        appState.accountId = this._currentAccount._id;
        appState.dashboardLanguage = getDashboardLanguage(this._currentAccount);
        appState.accountName = this._currentAccount.companyName;
        appState.setAppUser(this._currentUser as IAppUser);
    }

    //#region Account Settings Mapping (TODO: Create "SettingsAdapter" to encapsulate settings mapping)

    private updateAccountSettings(): void {
        this._accountSettings = {
            inviteMethodSettings: this.getAccInviteMethodSettings(),
            generalSettings: this.getAccGeneralSettings(),
            sessionSettings: this.getSessionSettings(),
            passwordSettings: this.getPasswordSettings(),
            smsSettings: this._currentAccount.protectedSettings.smsSettings,
            v3: this._currentAccount.protectedSettings.v3,

            v2_1: this._currentAccount.protectedSettings.v2_1,
            showRoomIdInUrl: this._currentAccount.protectedSettings.showRoomIdInUrl
        };
    }

    private getAccInviteMethodSettings(): InviteMethodSettings {
        return {
            addCountryCodeToSms: this._currentAccount.protectedSettings.smsSettings.addCountryCodeToSms,
            phoneCountryCodes: this._currentAccount.protectedSettings.selectedCountries,
            customerIdValidationRules: {
                display: this._currentAccount.settings.customerId.display,
                displayForInviteByCode: this._currentAccount.settings.customerId.displayForInviteByCode,
                displayForInviteByEmail: this._currentAccount.settings.customerId.displayForInviteByEmail,
                minLength: this._currentAccount.settings.customerId.minLength,
                maxLength: this._currentAccount.settings.customerId.maxLength
            },
            disablePhoneNumberForInviteByCode: this._currentAccount.settings.disablePhoneNumberForInviteByCode,
            selectVideoPublisher: this._currentAccount.protectedSettings.selectVideoPublisher,
            disableEmailForInviteByCode: this._currentAccount.settings.disableEmailForInviteByCode,
            multiLanguage: this._currentAccount.settings.multiLanguage,
            clientLanguages: this._currentAccount.settings.clientLanguages,
            showRoomIdInUrl: this._currentAccount.protectedSettings.showRoomIdInUrl,
            dashboardLanguage: this._currentAccount.settings.language || 'en_US',
            keepCustomerMobile: this._currentAccount.protectedSettings.keepCustomerMobile,
            defaultCountryCode: this._currentAccount.settings.countryCode,
            startWithModes: this._currentAccount.protectedSettings.startWithModes,
            accountOfflineSessions: this._currentAccount.settings.accountOfflineSessions,
            accountPendingRoomSessions: this._currentAccount.protectedSettings.warmTransfer.enable,
            showAlertsToSelectedGroups: this._currentAccount.settings.showAlertsToSelectedGroups,
            pendingRoomShowAlertsToSelectedGroups:
                this._currentAccount.protectedSettings.warmTransfer.showAlertsToSelectedGroups,
            selectedGroupsForOfflineAlerts: this._currentAccount.settings.selectedGroupsForOfflineAlerts,
            selectedGroupsForPendingRoomAlerts:
                this._currentAccount.protectedSettings.warmTransfer.selectedGroupsForAlerts,
            allowSearchOfflineSessionsById: this._currentAccount.protectedSettings.allowSearchOfflineSessionsById,
            allowDesktopSharing: this._currentAccount.protectedSettings.desktopSharing.enabled,
            showWizard: this._currentAccount.settings.showWizard,
            enableAgentPerformance: this._currentAccount.protectedSettings.enableAgentPerformance,
            enableQuickLaunch: this._currentAccount.protectedSettings.enableQuickLaunch,
            allowExternalCustomerDetails: this._currentAccount.protectedSettings.allowExternalCustomerDetails,
            audioStartMode: this._currentAccount.protectedSettings.audioStartMode,
            mediaType: this._currentAccount.protectedSettings.mediaType,
            newHeaderFooterLeftbar: this._currentAccount.protectedSettings.newHeaderFooterLeftbar,
            enableSessionToolbar: this._currentAccount.protectedSettings.enableSessionToolbar,
            visualJourney: {
                enabled: get(this._currentAccount, 'protectedSettings.visualJourney.enabled', false),
                useFlowManagementV2: get(
                    this._currentAccount,
                    'protectedSettings.visualJourney.useFlowManagementV2:',
                    false
                ),
                available: get(this._currentAccount, 'protectedSettings.visualJourney.available', [])
            },
            virtualBackground: {
                enabledBlurAgent: get(
                    this._currentAccount,
                    'protectedSettings.faceMeet.virtualBackground.enabledBlurAgent',
                    false
                ),
                enabledImageReplacementAgent: get(
                    this._currentAccount,
                    'protectedSettings.faceMeet.virtualBackground.enabledImageReplacementAgent',
                    false
                ),
                default: get(
                    this._currentAccount,
                    'protectedSettings.faceMeet.virtualBackground.defaultBackground',
                    VideoFilterType.NONE
                )
            },
            coBrowsing: {
                enabled: get(this._currentAccount, 'protectedSettings.coBrowsing.enabled', false),
                defaultURL: get(this._currentAccount, 'protectedSettings.coBrowsing.defaultURL', ''),
                surflyWidgetKey: get(this._currentAccount, 'protectedSettings.coBrowsing.surflyWidgetKey', '')
            },
            allowInviteBySms: this._currentAccount.settings.allowInviteBySms,
            allowSendByEmail: this._currentAccount.settings.allowSendByEmail,
            allowSendByWhatsApp: this._currentAccount.settings.allowSendByWhatsApp,
            emailSendBy: get(this._currentAccount, 'protectedSettings.emailSendBy'),
            observationEnabled: get(this._currentAccount, 'protectedSettings.enableSilentObserve'),
            desktopShareOnlyMode: get(this._currentAccount, 'protectedSettings.desktopShareOnlyMode'),
            measurement: get(this._currentAccount, 'protectedSettings.liveSdk.measurement'),
            referralConfiguration: get(this._currentAccount, 'protectedSettings.liveSdk.referralConfiguration'),
            v3: {
                enabled: get(this._currentAccount, 'protectedSettings.v3.enabled')
            },
            groups: this._currentAccount.groups,
            v2_1: {
                enabled: get(this._currentAccount, 'protectedSettings.v2_1.enabled')
            },
            agentDefaultCamera: get(this._currentAccount, 'protectedSettings.faceMeet.agentDefaultCamera'),
            videoResolution: get(this._currentAccount, 'protectedSettings.videoResolution'),
            mediaServiceType: get(this._currentAccount, 'protectedSettings.mediaServiceType'),
            enableMediaServiceTypeBySession: get(
                this._currentAccount,
                'protectedSettings.enableMediaServiceTypeBySession'
            ),
            agentStartSessionWithVideo: get(
                this._currentAccount,
                'protectedSettings.faceMeet.agentStartSessionWithVideo'
            ),
            copilot: get(this._currentAccount, 'protectedSettings.copilot')
        };
    }

    private getSessionSettings(): ISessionSettings {
        return {
            cameraModeOnly: this._currentAccount.settings.cameraModeOnly,
            chromeDetection: this._currentAccount.protectedSettings.chromeDetection,
            fallbackModeNotification: this._currentAccount.protectedSettings.fallbackModeNotification
        };
    }

    private getAccGeneralSettings(): GeneralSettings {
        const {enableHistory, enableHistorySearch, enableUserHistorySearch} = this._currentAccount.protectedSettings;

        const isCurrentUserEligibleForHistorySearch = [
            RolesConstant.USER,
            RolesConstant.KNOWLEDGE_EXPERT,
            RolesConstant.FIELD_SERVICES_ENGINEER
        ].includes(this._currentUser.role);
        const isEligibleForHistorySearch =
            enableHistory && enableHistorySearch && (enableUserHistorySearch || !isCurrentUserEligibleForHistorySearch);

        return {
            enableLinkToAdmin: this._currentAccount.protectedSettings.enableLinkToAdmin,
            logoUrl: this._currentAccount.settings.logoUrl,
            nativeAppId: this._currentAccount.protectedSettings.liveSdk.nativeAppId,
            enableNewInviteWifiStep: this._currentAccount.protectedSettings.enableNewInviteWifiStep,
            enableNewInviteSpeakersStep: this._currentAccount.protectedSettings.enableNewInviteSpeakersStep,
            isHistorySearchEnabled: isEligibleForHistorySearch,
            isVJHistorySearchEnabled:
                this._currentAccount.protectedSettings.interactionSummary.enableVJInteractionSummarySearch,
            enableWifiScan: this._currentAccount.protectedSettings.enableWifiScan,
            localImageSaving: this._currentAccount.protectedSettings.localImageSaving,
            emailImageSharing: this._currentAccount.protectedSettings.emailImageSharing,
            subDomain: get(this._currentAccount, 'subDomain.name'),
            domainZoneSuffix: get(this._currentAccount, 'subDomain.domainZoneSuffix'),
            guidPrefix: this._currentAccount.guidPrefix,
            keepCustomerMobile: this._currentAccount.protectedSettings.keepCustomerMobile,
            keepCustomerEmailAddress: this._currentAccount.protectedSettings.keepCustomerEmailAddress,
            reportsDateFormat: this._currentAccount.protectedSettings.reportsDateFormat,
            verboseLogging: get(this._currentAccount, 'protectedSettings.verboseLogging.enabled'),
            enableMultiLanguage: this._currentAccount.protectedSettings.allowAgentToChangeHisLanguage,
            appLauncher: this._currentAccount.protectedSettings.appLauncher?.invitePage,
            enabledVirtualBackgroundInFaceMeet:
                this._currentAccount.protectedSettings.faceMeet?.virtualBackground?.enabledInFaceMeet,
            enabledImageReplacementAgent:
                this._currentAccount.protectedSettings.faceMeet?.virtualBackground?.enabledImageReplacementAgent,
            enabledBlurAgent: this._currentAccount.protectedSettings.faceMeet?.virtualBackground?.enabledBlurAgent,
            defaultBackground: this._currentAccount.protectedSettings.faceMeet?.virtualBackground?.defaultBackground,
            accountType: this._currentAccount.accountType,
            interactionSummaryHistoryPerPage:
                this._currentAccount.protectedSettings.interactionSummary.historySearchPerPage
        };
    }

    private getPasswordSettings(): IPasswordSettings {
        return {
            ...this._currentAccount.settings.passwordSettings,
            allowCustomPasswordPolicy: this._currentAccount.protectedSettings.allowCustomPasswordPolicy
        };
    }

    private async getClientInfo(detectRTC: IWebRtcDetector): Promise<ClientInfo> {
        const webRtcInfo = await detectRTC.getWebRtcInfo();
        const clientInfo: ClientInfo = {
            platformType: PlatformType.dashboard,
            clientVersion: CLIENT_VERSION,
            browserInfo: getBrowserInfo(),
            webRtcInfo: webRtcInfo
        };

        return clientInfo;
    }

    //#endregion

    /*
        Because of technical issue with Angular digest loop,
        We define stores as non enumerable properties. This helps to avoid infinite digest of angular.
    */
    private defineStores(stores: Stores) {
        return (storeName: string, value: any) => {
            Object.defineProperty(stores, storeName, {
                value: value,
                enumerable: false
            });
        };
    }
}
