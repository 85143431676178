import {action, computed, observable} from 'mobx';
import {ITranslate} from '../../services/LocalizationService';
import moment from 'moment-timezone';
import {getRootStore} from '../../app.bootstrap';
import {
    IWifiAnalyzerController,
    scanTypeMapping,
    WifiAnalyzerController
} from '../../components/wifi-analyzer/controller';
import {NATIVE_PLATFORM_TYPE} from '@techsee/techsee-common/lib/constants/room.constants';
import {capitalize, get, toLower, toUpper, isEmpty} from 'lodash';
import {IWifiAnalyzerResults} from '../../services/AngularServices/WifiAnalyzerService';
// @ts-ignore
import {
    ForwardBirthCertificateReportModalController,
    IForwardBirthCertificateReportModalController
} from './forward-birth-certificate-report-modal/controller';
// @ts-ignore
import {reportToMomentDateFormat} from '@techsee/techsee-common/lib/utils';

const PAGE_SIZE = 25;

export interface IWifiAnalyzerHistoryController {
    dateFormat(date: string, format: string): string;
    selectSession(session: IWifiAnalyzerResults): void;
    formatScanTypeLabels(session: IWifiAnalyzerResults): string;
    nextPage(): void;
    prevPage(): void;
    goToInvitePage?: () => void;
    getDateFormattedByAcc: (date: string, withHours?: boolean) => string;
    searchHistory: () => Promise<void>;
    updateWifiAnalyzer: (newList: any) => void;
    wifiAnalyzerController: IWifiAnalyzerController;
    forwardBirthCertificateReportModalController: IForwardBirthCertificateReportModalController;
    platformNameFormatted: (deviceName: NATIVE_PLATFORM_TYPE) => string;
    downloadBirthCertificateReport(e: React.MouseEvent, downloadUrl: string): void;
    openForwardBirthCertificateReportModal(e: React.MouseEvent, scanId: string): void;
    isBirthCertificateReportEnabled(session: IWifiAnalyzerResults): boolean;
    open3dHeatMap(e: React.MouseEvent, session: IWifiAnalyzerResults): void;
    is3dHeatMapEnabled(session: IWifiAnalyzerResults): boolean;

    readonly translate: ITranslate;
    readonly wifiAnalyzerList: IWifiAnalyzerResults[];
    readonly endDate: string;
    readonly startDate: string;
    readonly customerRef: string;
    readonly selectedSession: any;
    readonly currentPage: number;
    readonly totalPages: number;
    readonly isNextAvailable: boolean;
    readonly isPrevAvailable: boolean;
    readonly getDataOnInitialLoad: boolean;
    readonly openModal: boolean;
}

type SearchCriteria = {
    startDate?: string;
    endDate?: string;
    customerRef?: string;
    roomId?: string;
};

export class WifiAnalyzerHistoryController implements IWifiAnalyzerHistoryController {
    private readonly _translate: ITranslate;
    private readonly _startDate: string;
    private readonly _endDate: string;
    private readonly _customerRef: string;
    private readonly _roomId: string;
    private readonly accountId: string;
    private readonly _wifiAnalyzerController: IWifiAnalyzerController;
    private readonly _forwardBirthCertificateReportModalController: IForwardBirthCertificateReportModalController;
    private readonly _token: string;

    @observable private _wifiAnalyzerList: IWifiAnalyzerResults[] = [];
    @observable private _selectedSession: any = {} as any;
    @observable private _totalPages: number = 1;
    @observable private _currentPage: number = 1;
    @observable private _getDataOnInitialLoad: boolean = false;
    @observable private readonly _accDateFormat: string = '';
    @observable private readonly _timeZone: string = '';
    @observable private readonly _language: string = '';
    @observable private _openModal: boolean = false;

    readonly goToInvitePage?: () => void;

    constructor(
        translate: ITranslate,
        accountId: string,
        accDateFormat: string,
        timeZone: string,
        language: string,
        searchCriteria: SearchCriteria,
        goToInvitePage?: () => void
    ) {
        this.selectSession = this.selectSession.bind(this);
        this.formatScanTypeLabels = this.formatScanTypeLabels.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.getDateFormattedByAcc = this.getDateFormattedByAcc.bind(this);
        this.downloadBirthCertificateReport = this.downloadBirthCertificateReport.bind(this);
        this.openForwardBirthCertificateReportModal = this.openForwardBirthCertificateReportModal.bind(this);
        this.isBirthCertificateReportEnabled = this.isBirthCertificateReportEnabled.bind(this);
        this.open3dHeatMap = this.open3dHeatMap.bind(this);
        this.is3dHeatMapEnabled = this.is3dHeatMapEnabled.bind(this);
        this._translate = translate;
        this.goToInvitePage = goToInvitePage;
        this._startDate = searchCriteria.startDate ? this.dateFormat(searchCriteria.startDate, 'MM/DD/YYYY') : '';
        this._endDate = searchCriteria.endDate ? this.dateFormat(searchCriteria.endDate, 'MM/DD/YYYY') : '';
        this._customerRef = searchCriteria.customerRef ?? '';
        this._roomId = searchCriteria.roomId ?? '';
        this.accountId = accountId;
        this._accDateFormat = accDateFormat;
        this._timeZone = timeZone;
        this._language = language.replace('_', '-');
        this._wifiAnalyzerController = new WifiAnalyzerController();
        this._forwardBirthCertificateReportModalController = new ForwardBirthCertificateReportModalController(
            translate
        );
        this._token = getRootStore().authService.getAuthToken();
    }

    get translate() {
        return this._translate;
    }

    get wifiAnalyzerController() {
        return this._wifiAnalyzerController;
    }

    get forwardBirthCertificateReportModalController() {
        return this._forwardBirthCertificateReportModalController;
    }

    @computed
    get wifiAnalyzerList() {
        return this._wifiAnalyzerList;
    }

    @computed
    get startDate() {
        return this._startDate;
    }

    @computed
    get endDate() {
        return this._endDate;
    }

    @computed
    get customerRef() {
        return this._customerRef;
    }

    @computed
    get selectedSession() {
        return this._selectedSession;
    }

    @computed
    get getDataOnInitialLoad() {
        return this._getDataOnInitialLoad;
    }

    @computed
    get isNextAvailable() {
        return this.currentPage < this._totalPages;
    }

    @computed
    get isPrevAvailable() {
        return this._currentPage > 1;
    }

    @computed
    get totalPages() {
        return this._totalPages;
    }

    @computed
    get currentPage() {
        return this._currentPage;
    }

    @computed
    get openModal() {
        return this._openModal;
    }

    @computed
    get roomId() {
        return this._roomId;
    }

    @action
    selectSession(session: IWifiAnalyzerResults) {
        if (
            !isEmpty(get(session, 'channelAnalysis')) ||
            !isEmpty(get(session, 'networkDiscoveryDevices')) ||
            !isEmpty(get(session, 'networkSpeed'))
        ) {
            this._selectedSession = session;
            this._getDataOnInitialLoad = true;
            this._wifiAnalyzerController.show();
            this._wifiAnalyzerController.setResults(session);
        }
    }

    @action
    open3dHeatMap(e: React.MouseEvent, session: IWifiAnalyzerResults) {
        e.stopPropagation();
        this.goToConnectivityGuruPage(
            session._id,
            session.connectivityGuruData,
            session.createdOn,
            session.roomId,
            session.customerId
        );
    }

    @action
    nextPage() {
        this._currentPage++;
        this.searchHistory();
    }

    @action
    prevPage() {
        this._currentPage--;
        this.searchHistory();
    }

    @action
    dateFormat(date: string, format: string) {
        return moment(date).format(format);
    }
    @action
    public async searchHistory(): Promise<void> {
        const pageNumber = this._currentPage;
        const startDateValue = this.startDate ? new Date(this.startDate) : undefined;
        const endDateValue = this.endDate ? new Date(this.endDate) : new Date();
        const customerRef = this.customerRef || undefined;
        const roomId = this.roomId || undefined;

        startDateValue?.setHours(0, 0, 0, 0);
        endDateValue.setHours(23, 59, 59, 999);

        if (startDateValue || customerRef || roomId) {
            const response = await getRootStore().wifiAnalyzerService.getWifiAnalyzerHistory(
                this.accountId,
                customerRef,
                startDateValue,
                endDateValue,
                PAGE_SIZE,
                pageNumber - 1
            );

            this.updateWifiAnalyzer(response);
        }
    }
    @action
    public updateWifiAnalyzer(newList: any): void {
        this._totalPages = Math.ceil((newList?.totalResults || 1) / PAGE_SIZE);
        this._wifiAnalyzerList = newList.previews;
    }

    @action
    getDateFormattedByAcc(date: string, withHours: boolean = true) {
        const rawFormat = withHours ? this._accDateFormat : this._accDateFormat.split(' ')[0];
        const momentFormat = reportToMomentDateFormat(rawFormat);

        const userLocale = (navigator.language || navigator.languages?.[0] || 'en').toLowerCase().replace('_', '-');

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || this._timeZone;

        return moment.tz(date, userTimeZone).locale(userLocale).format(momentFormat);
    }

    platformNameFormatted(deviceName: NATIVE_PLATFORM_TYPE) {
        return deviceName === NATIVE_PLATFORM_TYPE.android
            ? capitalize(deviceName)
            : toLower(deviceName.charAt(0)) + toUpper(deviceName.slice(1));
    }

    isBirthCertificateReportEnabled(session: IWifiAnalyzerResults): boolean {
        return session.connectivityGuruData === undefined;
    }

    is3dHeatMapEnabled(session: IWifiAnalyzerResults): boolean {
        return !!session.connectivityGuruData;
    }

    formatScanTypeLabels(session: IWifiAnalyzerResults): string {
        let formattedScanTypeToReturn = '';

        Object.entries(scanTypeMapping).forEach(([key, value]) => {
            if (!isEmpty(get(session, key))) {
                const formattedValue = value
                    .split(' ')
                    .map((word) => {
                        if (!/\d/.test(word)) {
                            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                        }

                        return word;
                    })
                    .join(' ');

                formattedScanTypeToReturn += `${formattedValue}, `;
            }
        });

        return formattedScanTypeToReturn.slice(0, -2);
    }

    goToConnectivityGuruPage = (
        scanId: string,
        connectivityGuruData: object,
        createdOn: Date,
        roomId: string,
        customerId?: string
    ) => {
        getRootStore().browserUtilsService.saveToLocalStorage('connectivityGuru', {
            createdOn: this.getDateFormattedByAcc(createdOn.toString()),
            customerId,
            accountId: this.accountId,
            roomId,
            scanId
        });

        getRootStore().redirectionService.goToConnectivityGuruData();
    };

    downloadBirthCertificateReport(e: React.MouseEvent, downloadUrl: string): void {
        e.stopPropagation();

        window.open(`${downloadUrl}?token=${this._token}`, '_blank');
    }

    openForwardBirthCertificateReportModal(e: React.MouseEvent, scanId: string): void {
        e.stopPropagation();

        this._forwardBirthCertificateReportModalController.show(scanId);
    }
}
