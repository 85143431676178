import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ITranslate} from '../../services/LocalizationService';
import {IRemoteControlPopupController} from './controller';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {CloserButton} from '@techsee/techsee-ui-common/lib/forms/buttons/closer';
import {RemoteControlModal} from './RemoteControlModal';

import './styles.scss';

export interface IRemoteControlPopup {
    ctrl: IRemoteControlPopupController;
    translate: ITranslate;
    clientIsSharing: boolean;
    requestRemoteControl?: () => void;
    stopRemoteControl?: () => void;
    closePopover: () => void;
}

@observer
export class RemoteControlPopup extends TechseeBaseComponent<IRemoteControlPopup> {
    renderInternal() {
        const {ctrl, translate, closePopover} = this.props;
        const {isInControl} = ctrl;
        const className = 'use-common-styles';

        if (isInControl) {
            ctrl.setStopControlModalVisible(true);
        }

        return !isInControl ? (
            <div className={className}>
                <CloserButton onClick={closePopover} className={'close-popover'} />
                <div className={'header'}>{translate('REACT.MULTIPARTY_DASHBOARD.REMOTE_CONTROL.START.TITLE')}</div>
                <div className={'description'}>{translate('REACT.MULTIPARTY_DASHBOARD.REMOTE_CONTROL.START.INFO')}</div>
                <div className={'remote-control-button'}>
                    <PrimaryButton disabled={ctrl.isRemoteControlButtonDisabled} onClick={ctrl.toggleRequestControl}>
                        {translate('REACT.MULTIPARTY_DASHBOARD.REMOTE_CONTROL.START.BUTTON')}
                    </PrimaryButton>
                </div>
            </div>
        ) : (
            <RemoteControlModal ctrl={ctrl} translate={translate} />
        );
    }
}
