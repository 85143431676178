import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ITranslate} from '../../services/LocalizationService';
import {VJForm} from '../vj-form/form';
import {WifiAnalyzerFilterController} from './controller';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';

export interface IWifiAnalyzerFilter {
    ctrl: WifiAnalyzerFilterController;
    translate: ITranslate;
    onSubmit?: () => void;
}

@observer
export class WifiAnalyzerFilter extends TechseeBaseComponent<IWifiAnalyzerFilter> {
    componentDidMount() {
        const {ctrl} = this.props;

        ctrl.eventLog(LOG_EVENTS.wifiScanSearchSummaryModal);
    }

    renderInternal() {
        const {ctrl, translate} = this.props;

        return (
            <VJForm
                ctrl={ctrl.formController}
                translate={translate}
                formErrorText={'INVITE.VIEW.HISTORY_SEARCH.NO_RESULTS'}
                labelText={'INVITE.VIEW.WIFI_ANALYZER_HISTORY_SEARCH.LABEL_HISTORY'}
                buttonText={'INVITE.VIEW.SEARCH'}
            />
        );
    }
}
