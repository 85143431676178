'use strict';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import EventEmitter from 'events';
import {Nullable} from '@techsee/techsee-common';

export enum BandwidthLevel {
    UNCERTAIN = 'uncertain',
    LOW_BANDWIDTH = 'lowBandwidth',
    HIGH_BANDWIDTH = 'highBandwidth'
}

export enum BANDWIDTH_LAST_SAMPLE {
    INIT = 'init',
    STABLE = 'stable',
    LOW = 'low'
}

export interface ISample {
    bytesReceived: number;
    timestamp: number;
}

export interface IBufferSample {
    measurement: number;
    timestamp: number;
}

export interface BandwidthCalculationResult {
    status: BandwidthLevel;
    bandwidthSamples: Array<number>;
}

export class BandwidthMeasurementService extends EventEmitter {
    private bandwidthSamples: Array<number>;

    private bandwidthSamplesBuffer: Array<IBufferSample>;

    private countSufficientBandwidthSamples: number;

    private countLowBandwidthSamples: number;

    private bandwidthLastSample: String;

    private previousSample?: ISample;

    constructor(
        private enableNetworkBandwidthMeasurement: boolean,
        private minimalBandwidthKbps: number,
        private bandwidthSamplesBufferMaxSize: number,
        private videoBandwidthSampleCountDuringSession: number
    ) {
        'ngInject';

        super();
        this.enableNetworkBandwidthMeasurement = enableNetworkBandwidthMeasurement;
        this.minimalBandwidthKbps = minimalBandwidthKbps;
        this.bandwidthSamplesBufferMaxSize = bandwidthSamplesBufferMaxSize;
        this.videoBandwidthSampleCountDuringSession = videoBandwidthSampleCountDuringSession;

        this.bandwidthSamples = [];
        this.bandwidthSamplesBuffer = [];
        this.countSufficientBandwidthSamples = 0;
        this.countLowBandwidthSamples = 0;
        this.bandwidthLastSample = BANDWIDTH_LAST_SAMPLE.INIT;
        this.previousSample = undefined;
    }

    calculateVideoBandwidth(userVideoStats: any): Nullable<BandwidthCalculationResult> {
        const bytesReceived = get(userVideoStats.parsedTrackStats, 'video.bytesReceived');

        let currentSample: Nullable<ISample> = null;

        if (bytesReceived) {
            currentSample = {
                timestamp:
                    userVideoStats.parsedTrackStats.timestamp ||
                    get(userVideoStats.parsedTrackStats, 'video.timestamp'),
                bytesReceived: bytesReceived
            };
        }

        if (currentSample && this.previousSample) {
            const bandwidth = BandwidthMeasurementService.calcBandwidthFromSamples(currentSample, this.previousSample);

            if (!bandwidth) {
                return null;
            }

            this.bandwidthSamplesBuffer.push({
                measurement: bandwidth,
                timestamp: new Date().getTime()
            });

            if (this.bandwidthSamplesBuffer.length >= this.bandwidthSamplesBufferMaxSize) {
                this.flushBandwidthSampleBuffer();
            }

            if (this.enableNetworkBandwidthMeasurement) {
                this.bandwidthSamples.push(bandwidth);

                if (bandwidth < this.minimalBandwidthKbps) {
                    this.countSufficientBandwidthSamples = 0;
                    this.countLowBandwidthSamples++;

                    if (
                        this.countLowBandwidthSamples === this.videoBandwidthSampleCountDuringSession &&
                        this.bandwidthLastSample !== BANDWIDTH_LAST_SAMPLE.LOW
                    ) {
                        return {status: BandwidthLevel.LOW_BANDWIDTH, bandwidthSamples: this.bandwidthSamples};
                    }
                } else {
                    this.countLowBandwidthSamples = 0;
                    this.countSufficientBandwidthSamples++;

                    if (
                        this.countSufficientBandwidthSamples === this.videoBandwidthSampleCountDuringSession &&
                        this.bandwidthLastSample !== BANDWIDTH_LAST_SAMPLE.STABLE
                    ) {
                        return {status: BandwidthLevel.HIGH_BANDWIDTH, bandwidthSamples: this.bandwidthSamples};
                    }
                }

                if (this.bandwidthSamples.length === this.videoBandwidthSampleCountDuringSession) {
                    this.bandwidthSamples.shift();
                }

                return {status: BandwidthLevel.UNCERTAIN, bandwidthSamples: this.bandwidthSamples};
            }

            this.previousSample = currentSample;
        }

        if (!this.previousSample && currentSample) {
            this.previousSample = currentSample;
        }

        return null;
    }

    bandwidthParamCleanup = (flushBuffer: boolean) => {
        this.bandwidthSamples = [];
        this.countSufficientBandwidthSamples = 0;
        this.countLowBandwidthSamples = 0;
        this.previousSample = undefined;
        this.bandwidthLastSample = BANDWIDTH_LAST_SAMPLE.STABLE;

        if (flushBuffer) {
            this.bandwidthSamplesBuffer = [];
        }
    };

    bandwidthSamplesCleanup = () => {
        this.bandwidthSamples = [];
    };

    flushBandwidthSampleBuffer() {
        if (isEmpty(this.bandwidthSamplesBuffer)) {
            return;
        }

        this.emit('bandwidthMeasurementSamples', {
            data: this.bandwidthSamplesBuffer
        });

        this.bandwidthSamplesBuffer = [];
    }

    private static calcBandwidthFromSamples(sample: ISample, previousSample: ISample) {
        if (sample && previousSample) {
            const bitsDelta = (sample.bytesReceived - previousSample.bytesReceived) * 8;
            const timeDelta = (sample.timestamp - previousSample.timestamp) / 1000;
            const bandwidthBps = bitsDelta / timeDelta;

            return bandwidthBps / 1000;
        }

        return;
    }
}
