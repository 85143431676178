import {
    LoginConfirmationController,
    ILoginConfirmationController
} from '../../_react_/states/login-new/components/login-confirmation/controller';
import {LoginController, ILoginController} from '../../_react_/states/login-new/components/login-form/controller';
import {
    LoginModalController,
    ILoginModalController
} from '../../_react_/states/login-new/components/login-modal/controller';
import {ITranslate} from '../../_react_/services/LocalizationService';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {IRedirectionService} from '../../_react_/services/RedirectionService';
import {IApplicationService} from '../../_react_/services/ApplicationService';
import {IFieldFactory} from '../../_react_/app.contracts';
// @ts-ignore
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {IEventLogsService} from '../../_react_/services/EventsLogService';
import {IDbUser} from '../../_react_/services/AngularServices/AngularServices';

export interface InitParams {
    isNotificationEnabled: boolean;
    roomId: string;
    userId: string;
    roomUserId: string;
    accountId: string;
}

export interface IAuthExpiryService {
    readonly isNotificationEnabled: boolean;
    readonly isloginModalVisible: boolean;
    readonly isSessionExpired: boolean;
    readonly loginConfirmationController: ILoginConfirmationController;
    readonly loginFormController: ILoginController;
    readonly loginModalController: ILoginModalController;

    init(initParams: InitParams, callback: () => void): void;
    setSessionAsExpired(): void;
}

export class AuthExpiryService implements IAuthExpiryService {
    private _isNotificationEnabled: boolean = false;

    private _isSessionExpired: boolean = false;

    private _loginConfirmationController!: ILoginConfirmationController;

    private _loginFormController!: ILoginController;

    private _loginModalController!: ILoginModalController;

    private _userId: string = '';

    private _accountId: string = '';

    private _roomId: string = '';

    private _roomUserId: string = '';

    constructor(
        private _translate: ITranslate,
        private _appService: IApplicationService,
        private _redirectionService: IRedirectionService,
        private _browserUtilsService: IBrowserUtilsService,
        private _createFieldModel: IFieldFactory,
        private _eventLogService: IEventLogsService,
        private _dbUserService: IDbUser
    ) {}

    get isloginModalVisible(): boolean {
        return this.loginModalController && this.loginModalController.isLoginModalVisible;
    }

    get loginConfirmationController(): ILoginConfirmationController {
        return this._loginConfirmationController;
    }

    get loginFormController(): ILoginController {
        return this._loginFormController;
    }

    get loginModalController(): ILoginModalController {
        return this._loginModalController;
    }

    get isNotificationEnabled(): boolean {
        return this._isNotificationEnabled;
    }

    get isSessionExpired(): boolean {
        return this._isSessionExpired;
    }

    init({isNotificationEnabled, roomId, userId, accountId, roomUserId}: InitParams, callback: () => void): void {
        this._isNotificationEnabled = isNotificationEnabled;
        this._roomId = roomId;
        this._userId = userId;
        this._accountId = accountId;
        this._roomUserId = roomUserId;

        if (this._isNotificationEnabled) {
            this.initControllers();

            return callback();
        }
    }

    setSessionAsExpired(): void {
        if (!this._isSessionExpired) {
            if (this.loginModalController) {
                this._loginModalController.show();
                this._eventLogService.info(this.getLogParams(LOG_EVENTS.loginExpirationNotificationShowed));

                this._loginModalController.onSuccessLogin(() => {
                    this._dbUserService.find('current').then((user) => {
                        if (user._id !== this._roomUserId) {
                            this._redirectionService.goToInvite();
                        } else {
                            this.setSessionAsValid();
                            this._eventLogService.info(this.getLogParams(LOG_EVENTS.loginExpirationSuccessLogin));
                        }
                    });
                });

                this._loginModalController.onErrorLogin((err) => {
                    this._eventLogService.error(this.getLogParams(LOG_EVENTS.loginExpirationErrorLogin, err));
                });
            }

            this._isSessionExpired = true;
        }
    }

    private setSessionAsValid(): void {
        this._isSessionExpired = false;
    }

    private initControllers() {
        this._loginConfirmationController = new LoginConfirmationController(this._translate);

        this._loginFormController = new LoginController(
            this._translate,
            this._appService,
            this._redirectionService,
            this._createFieldModel,
            this._loginConfirmationController,
            this._browserUtilsService,
            this._dbUserService
        );

        this._loginFormController.setConfig({
            redirectToInvite: false,
            showMissedAccountInfo: false,
            submitButtonText: this._translate('REACT.LOGIN.VIEW.LOGIN_BUTTON')
        });

        this._loginFormController.setErrorMessage(this._translate('REACT.LOGIN.VIEW.SESSION_EXPIRED_ERROR'));

        this._loginModalController = new LoginModalController(
            this._translate,
            this._loginFormController,
            this._loginConfirmationController
        );
    }

    private getLogParams(logType: string, meta?: any) {
        return {
            logType: logType,
            accountId: this._accountId,
            userId: this._userId,
            roomId: this._roomId,
            meta: meta
        };
    }
}
