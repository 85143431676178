import EventEmitter from 'events';
import {Nullable} from '@techsee/techsee-ui-common/lib/_shared/reusable-types';
import {MeetingMode, UserType, AddressTypesEnum} from '@techsee/techsee-common/lib/constants/room.constants';
import {ITechseeRoomChannel} from '@techsee/techsee-client-infra/lib/infra/RoomChannelContracts';
import {OperationResult} from '../../models/OperationResult';
import {IRoom, ISetCustomer} from '../../models/Room';
import {IDbRooms, IDbShortUrl} from '../AngularServices/AngularServices';
import {getAppTracer} from '../../../app.tracer';

import {LogMessageNames, SentByServerEvents, StatusEventParams} from './SessionSocketEvents';

import {
    DataListener,
    DeviceDetails,
    ISessionService,
    JoinSessionParams,
    NotificationListener,
    SessionAccSettings,
    SessionConnectResult,
    SocketVersionConfig,
    StartSessionParams,
    SubscriptionDisposer
} from './SessionContracts';

import {ISessionSettings} from '../../models/AccountSettings';
import {ClientState} from '../../states/invite-new/_contracts/ClientState';
import {EventLogConstant} from '../../../constants/events/event-log.constant';
import {JoinRoomInfo} from '@techsee/techsee-common/lib/data-contracts/JoinRoomInfo';
import {ClientInfo} from '@techsee/techsee-common/lib/data-contracts/ClientInfo';
import {NetworkInfo} from '../../models/LiveSessionState';
import {ISessionServiceFlowLogger} from '../../states/invite-new/invite.flow.logger';

enum INTERNAL_EVENTS {
    onChannelDisconnect = 'onChannelDisconnect',
    onClientConnect = 'onClientConnect',
    onClientDisconnect = 'onClientDisconnect',
    onClientTosView = 'onClientTosView',
    onClientTosResult = 'onClientTosResult',
    onClientPreCameraView = 'onClientPreCameraView',
    onClientPreCameraResult = 'onClientPreCameraResult',
    onClientCameraView = 'onClientCameraView',
    onScreenShareCapturingPermission = 'onScreenShareCapturingPermission',
    onClientDeviceDetails = 'onClientDeviceDetails',
    onHandshakeSuccess = 'onHandshakeSuccess',
    onClientNetworkInfo = 'onClientNetworkInfo',
    onClientEndedMeeting = 'onClientEndedMeeting',
    onClientUnsupportedDevice = 'onClientUnsupportedDevice',
    onDesktopSharingUnsupported = 'onDesktopSharingUnsupported',
    onSocketVersionChanged = 'onSocketVersionChanged',
    onVideoApplicationCameraAudioDialog = 'onVideoApplicationCameraAudioDialog',
    onForceTimeout = 'onForceTimeout'
}

class SessionState {
    currentRoom: IRoom;

    accountSettings: Nullable<SessionAccSettings> = null;

    clientState: Nullable<ClientState> = null;

    clientDeviceDetails: Nullable<DeviceDetails> = null;

    dashboardState: any = null;

    sessionShortId: any = null;

    sessionLinkUrl: any = null;

    prefixLength: any = null;

    constructor(room: IRoom) {
        this.currentRoom = room;
    }
}

const ERR_SESSION_INPROGRESS = (act: string) => {
    const message = `Cannot ${act} session, while session is active or already initiating.`;
    const failedResult = OperationResult.getFailResult(message);

    return Promise.reject(failedResult) as Promise<OperationResult<SessionConnectResult>>;
};

const trace = getAppTracer('SessionService');

export class SessionService implements ISessionService {
    private _emitter: EventEmitter;

    private _clientInfo: ClientInfo;

    private _roomsService: IDbRooms;

    private _shortUrlService: IDbShortUrl;

    private _sessionSettings: ISessionSettings;

    private _sessionSocket: ITechseeRoomChannel;

    private _eventLogService: ISessionServiceFlowLogger;

    private _sessionInitPromise: Nullable<Promise<OperationResult<any>>> = null;

    private _sessionInitResolver: Nullable<(result: OperationResult<any>) => void> = null;

    private _sessionInitRejector: Nullable<(result: OperationResult<any>) => void> = null;

    private _sessionState: Nullable<SessionState> = null;

    constructor(
        clientInfo: ClientInfo,
        roomService: IDbRooms,
        shortUrlService: IDbShortUrl,
        sessionSettings: ISessionSettings,
        sessionSocket: ITechseeRoomChannel,
        inviteFlowLogger: ISessionServiceFlowLogger
    ) {
        this._clientInfo = clientInfo;
        this._roomsService = roomService;
        this._shortUrlService = shortUrlService;
        this._sessionSettings = sessionSettings;
        this._sessionSocket = sessionSocket;
        this._eventLogService = inviteFlowLogger;

        this._emitter = new EventEmitter();

        this.subscribeSocketEvents();
    }

    get clientState() {
        this.throwIfNotActiveSession('clientState');

        return this._sessionState!.clientState!;
    }

    async startSession(params: StartSessionParams): Promise<OperationResult<SessionConnectResult>> {
        this._eventLogService.createSessionRequest(params);

        if (this.isSessionInitiating) {
            return ERR_SESSION_INPROGRESS('start');
        }

        if (this.isSessionActive) {
            trace.warn('Starting a new session while session is already active. Closing existing session first');
        }

        this.createInitFlowPromise();

        await this.endSession();

        const createRoomParams = {
            customerId: params.customerId,
            clientLanguage: params.clientLanguage,
            customerNumber: params.customerNumber,
            customerEmail: params.customerEmail,
            startWithAgentType: params.startWithAgentType,
            offline: params.offline,
            initiateWithAudio: params.audio,
            initiateWithVideo: params.video,
            initiateWithMeasure: params.measure,
            hdEnabled: params.hdEnabled,
            videoFilterType: params.videoFilterType,
            startUrlCobrowsing: params.startUrlCobrowsing
        };

        this._roomsService
            .create(createRoomParams)
            .then((room: IRoom) => {
                this._sessionState = new SessionState(room);
                this._eventLogService.setRoomId(room._id);
                this._eventLogService.sessionCreated();

                const shortUrlParams = {
                    audio: params.audio,
                    roomId: this._sessionState.currentRoom._id,
                    chromeDetection: this._sessionSettings.chromeDetection,
                    cameraModeOnly: this._sessionSettings.cameraModeOnly,
                    offline: params.offline,
                    mobileClientURL: params.mobileClientURL,
                    startWithAgentType: params.startWithAgentType,
                    ...(params.referralRegion && {referralRegion: params.referralRegion})
                };

                return this._shortUrlService.create(shortUrlParams).then((shortUrlResult: any) => {
                    this._sessionState!.sessionShortId = shortUrlResult.shortId;
                    this._sessionState!.sessionLinkUrl = shortUrlResult.url;
                    this._sessionState!.prefixLength = shortUrlResult.prefixLength;

                    if (params.offline && this._sessionInitResolver && this._sessionState) {
                        const resultModel: SessionConnectResult = {
                            sessionRoomId: this._sessionState.currentRoom._id,
                            sessionShortId: this._sessionState.sessionShortId,
                            sessionLinkUrl: this._sessionState.sessionLinkUrl,
                            prefixLength: this._sessionState.prefixLength
                        };

                        //Offline session
                        return this._sessionInitResolver(OperationResult.getSuccessResult(resultModel));
                    }

                    return this.joinSessionRoom(params.userType);
                });
            })
            .then(() => {
                this.finalizeSessionInitFlow();
            })
            .catch((err) => {
                this._eventLogService.sessionCreationFailed({error: err});
                this._sessionState = null;
                this._sessionInitRejector!(OperationResult.getFailResult('Failed to create session', err));
                this.finalizeSessionInitFlow();
            });

        return this._sessionInitPromise!;
    }

    async joinSession(params: JoinSessionParams): Promise<OperationResult<SessionConnectResult>> {
        if (this.isSessionInitiating) {
            return ERR_SESSION_INPROGRESS('join');
        }

        this.createInitFlowPromise();

        await this.endSession();

        const {byShortId} = params;

        this._roomsService
            .find(byShortId ? params.sessionId : params.roomId, {params: {byShortId}})
            .then((room: IRoom) => {
                this._sessionState = new SessionState(room);

                this._sessionState.sessionLinkUrl = params.sessionUrl;
                this._sessionState.sessionShortId = params.sessionId;

                return this.joinSessionRoom(params.userType);
            })
            .then(() => {
                this.finalizeSessionInitFlow();
            })
            .catch(() => {
                this._sessionState = null;
                this._sessionInitRejector!(OperationResult.getFailResult('Failed to join session'));
                this.finalizeSessionInitFlow();
            });

        return this._sessionInitPromise!;
    }

    repromptClientForTos(): void {
        this.setSelfStatus('isTosRepromptSent', true);
    }

    updateCustomerInfo(address: string, addressType: AddressTypesEnum): void {
        if (!this.isSessionActive) {
            return;
        }

        const room = this._sessionState!.currentRoom;
        const data: ISetCustomer = {
            customerId: room.customerId
        };

        switch (addressType) {
            case AddressTypesEnum.INVITE_BY_CODE:
            case AddressTypesEnum.OFFLINE:
            case AddressTypesEnum.LINK:
            case AddressTypesEnum.SMS:
            case AddressTypesEnum.WHATSAPP:
                data.customerNumber = address;
                data.customerCountryCode = room.customerCountryCode;
                break;
            case AddressTypesEnum.EMAIL:
                data.customerEmail = address;
                break;
            default:
                throw new Error('wrong details');
        }

        room.setCustomer(data);
    }

    async endSession(keepRoom?: boolean): Promise<void> {
        let roomId = null;

        try {
            if (!keepRoom && this._sessionState && this._sessionState.currentRoom) {
                roomId = this._sessionState.currentRoom._id;

                await this._sessionState.currentRoom.end(EventLogConstant.closedByTypes.dashboard);
            }
        } catch (e) {
            const eventLogDetails: any = {roomEnding: true, error: e};

            if (roomId) {
                eventLogDetails.roomId = roomId;
            }

            this._eventLogService.endSessionFailed(eventLogDetails);
        }

        try {
            this._sessionSocket.disconnect();
        } catch (e) {
            const eventLogDetails: any = {socketDisconnection: true, error: e};

            if (roomId) {
                eventLogDetails.roomId = roomId;
            }

            this._eventLogService.endSessionFailed(eventLogDetails);
        }

        this._sessionState = null;
    }

    get isSessionInitiating(): boolean {
        return this._sessionInitPromise !== null && this._sessionInitResolver !== null;
    }

    get isSessionActive(): boolean {
        return this._sessionState !== null && this._sessionState.clientState !== null;
    }

    //#region Public Subscribers

    onClientConnect(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onClientConnect, listener);
    }

    onClientDeviceDetails(data: DataListener<DeviceDetails>) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onClientDeviceDetails, data);
    }

    onClientDisconnect(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onClientDisconnect, listener);
    }

    onClientEndedMeeting(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onClientEndedMeeting, listener);
    }

    onClientUnsupportedDevice(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onClientUnsupportedDevice, listener);
    }

    onDesktopSharingUnsupported(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onDesktopSharingUnsupported, listener);
    }

    onSocketVersionChanged(listener: DataListener<SocketVersionConfig>) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onSocketVersionChanged, listener);
    }

    onClientTosView(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onClientTosView, listener);
    }

    onClientTosResult(listener: DataListener<boolean>) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onClientTosResult, listener);
    }

    onClientPreCameraView(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onClientPreCameraView, listener);
    }

    onClientPreCameraResult(listener: DataListener<boolean>) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onClientPreCameraResult, listener);
    }

    onClientCameraView(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onClientCameraView, listener);
    }

    onScreenShareCapturingPermission(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onScreenShareCapturingPermission, listener);
    }

    onClientNetworkInfo(listener: DataListener<NetworkInfo>) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onClientNetworkInfo, listener);
    }

    onForceTimeout(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onForceTimeout, listener);
    }

    onChannelDisconnect(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onChannelDisconnect, listener);
    }

    onHandshakeSuccess(listener: DataListener<string>) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onHandshakeSuccess, listener);
    }

    onVideoApplicationCameraAudioDialog(listener: NotificationListener) {
        return this.subscribeExternalListener(INTERNAL_EVENTS.onVideoApplicationCameraAudioDialog, listener);
    }

    //#endregion

    //#region Socket Events

    private subscribeSocketEvents() {
        this._sessionSocket.onDisconnected(this.disconnectedHandler.bind(this));

        this._sessionSocket.on(SentByServerEvents.SYNC, this.syncHandler.bind(this));
        this._sessionSocket.on(SentByServerEvents.STATUS_CHANGED, this.statusChangedHandler.bind(this));
        this._sessionSocket.on(SentByServerEvents.RECEIVE_MESSAGE, this.receiveMessageHandler.bind(this));
        this._sessionSocket.on(SentByServerEvents.CLIENT_DEVICE_DETAILS, this.clientDeviceHandler.bind(this));
        this._sessionSocket.on(
            SentByServerEvents.DESKTOP_SHARING_UNSUPPORTED,
            this.desktopSharingUnsupportedHandler.bind(this)
        );
        this._sessionSocket.on(SentByServerEvents.SOCKET_MISMATCH, this.socketVersionHandler.bind(this));
        this._sessionSocket.on(SentByServerEvents.MOBILE_NETWORK_TYPE, this.mobileNetworkTypeHandler.bind(this));
        this._sessionSocket.on(SentByServerEvents.EXCEPTION, (event: any) => {
            if (event.reason === 'forceTimeout' || event.reason === 'timeout') {
                this._emitter.emit(INTERNAL_EVENTS.onForceTimeout);
            }
        });
    }

    private mobileNetworkTypeHandler(networkInfo: NetworkInfo) {
        if (!this.isSessionActive || !networkInfo) {
            return;
        }

        this._emitter.emit(INTERNAL_EVENTS.onClientNetworkInfo, NetworkInfo.fromDataObject(networkInfo));
    }

    private disconnectedHandler() {
        this._emitter.emit(INTERNAL_EVENTS.onChannelDisconnect);
    }

    private syncHandler(data: any) {
        if (!this._sessionState) {
            return;
        }

        this._sessionState.accountSettings = data.room.accountSettings;
        this._sessionState.clientState = data.room.status.client;
        this._sessionState.dashboardState = data.room.status.dashboard;
    }

    private statusChangedHandler(statusData: {
        param: string;
        value: any;
        complete: {client: ClientState; dashboard: any};
    }) {
        if (!this._sessionState) {
            return;
        }

        this._sessionState.clientState = statusData.complete.client;
        this._sessionState.dashboardState = statusData.complete.dashboard;

        switch (statusData.param) {
            case StatusEventParams.connected:
                this._emitter.emit(
                    statusData.value === true ? INTERNAL_EVENTS.onClientConnect : INTERNAL_EVENTS.onClientDisconnect
                );
                break;
            case StatusEventParams.isReviewingTOS:
                if (statusData.value === true) {
                    this._emitter.emit(INTERNAL_EVENTS.onClientTosView);
                } else if (!statusData.complete.client.tosAccepted) {
                    this._emitter.emit(INTERNAL_EVENTS.onClientTosResult, false);
                }
                break;
            case StatusEventParams.tosAccepted:
                this._emitter.emit(INTERNAL_EVENTS.onClientTosResult, true);
                break;
            case StatusEventParams.tosRejected:
                this._emitter.emit(INTERNAL_EVENTS.onClientTosResult, false);
                break;
            case StatusEventParams.isOnPreCameraApprovalScreen:
                if (statusData.value === true) {
                    this._emitter.emit(INTERNAL_EVENTS.onClientPreCameraView);
                }
                break;
            case StatusEventParams.isPreCameraApprovedByUser:
                this._emitter.emit(INTERNAL_EVENTS.onClientPreCameraResult, statusData.value);
                break;
            case StatusEventParams.videoHandshakeSuccess:
                this._emitter.emit(INTERNAL_EVENTS.onHandshakeSuccess, MeetingMode.video);
                break;
            case StatusEventParams.faceMeetHandshakeSuccess:
                this._emitter.emit(INTERNAL_EVENTS.onHandshakeSuccess, MeetingMode.faceMeet);
                break;
            case StatusEventParams.coBrowsingHandshakeSuccess:
                this._emitter.emit(INTERNAL_EVENTS.onHandshakeSuccess, MeetingMode.coBrowsing);
                break;
            case StatusEventParams.screenHandshakeSuccess:
                this._emitter.emit(INTERNAL_EVENTS.onHandshakeSuccess, MeetingMode.screen);
                break;
            case StatusEventParams.appSharingHandshakeSuccess:
                this._emitter.emit(INTERNAL_EVENTS.onHandshakeSuccess, MeetingMode.appSharing);
                break;
            case StatusEventParams.videoApplicationHandshakeSuccess:
                this._emitter.emit(INTERNAL_EVENTS.onHandshakeSuccess, MeetingMode.videoApplication);
                break;
            case StatusEventParams.imageUploadHandshakeSuccess:
                this._emitter.emit(INTERNAL_EVENTS.onHandshakeSuccess, MeetingMode.images);
                break;
            case StatusEventParams.oneClickHandshakeSuccess:
                this._emitter.emit(INTERNAL_EVENTS.onHandshakeSuccess, MeetingMode.oneClick);
                break;
            case StatusEventParams.isOnScreenShareCapturingPermission:
                this._emitter.emit(INTERNAL_EVENTS.onScreenShareCapturingPermission, statusData.value);
                break;
            case StatusEventParams.inCameraApprovalDialog:
                if (statusData.value === true) {
                    this._emitter.emit(INTERNAL_EVENTS.onClientCameraView);
                }
                break;
            case StatusEventParams.isReviewingVideoApplicationCameraAudioDialog:
                this._emitter.emit(INTERNAL_EVENTS.onVideoApplicationCameraAudioDialog, MeetingMode.videoApplication);
                break;
            default:
                return;
        }
    }

    private receiveMessageHandler(msg: {data: {type: string; message: any}}) {
        if (this.isSessionActive && msg.data.type === 'log') {
            if (msg.data.message.name === LogMessageNames.CUSTOMER_ENDED_THE_MEETING) {
                this._emitter.emit(INTERNAL_EVENTS.onClientEndedMeeting);
            }

            if (msg.data.message.name === LogMessageNames.UNSUPPORTED_DEVICE) {
                this._emitter.emit(INTERNAL_EVENTS.onClientUnsupportedDevice, true);
            }
        }
    }

    private clientDeviceHandler(data: any): void {
        if (!this.isSessionActive) {
            return;
        }

        const deviceDetails: DeviceDetails = {
            osName: data.clientOsObject && data.clientOsObject.name,
            osVersion: data.clientOsObject && data.clientOsObject.version,
            usingApplication: data.usingApplication,
            clientBrowser: data.clientBrowser,
            clientDevice: data.clientDevice,
            clientType: data.clientType && data.clientType.replace(/_/, ' ')
        };

        this._sessionState!.clientDeviceDetails = deviceDetails;

        this._emitter.emit(INTERNAL_EVENTS.onClientDeviceDetails, deviceDetails);
    }

    desktopSharingUnsupportedHandler() {
        this._emitter.emit(INTERNAL_EVENTS.onDesktopSharingUnsupported);
    }

    socketVersionHandler(socketConfig: SocketVersionConfig) {
        this._emitter.emit(INTERNAL_EVENTS.onSocketVersionChanged, socketConfig);
    }

    //#endregion

    private setSelfStatus(param: string, value: any) {
        if (this._sessionState) {
            const {dashboardState} = this._sessionState;

            if (dashboardState[param] === undefined || dashboardState[param] === value) {
                return;
            }

            dashboardState[param] = value;
            this._sessionSocket.emit('changeStatus', {
                param: param,
                value: value,
                silent: false
            });
        }
    }

    private joinSessionRoom(userType: UserType) {
        if (this._sessionState === null) {
            return Promise.reject('No Active Session');
        }

        const start = new Date().getTime();

        this._eventLogService.joinSessionRoom({sessionId: this._sessionState.sessionShortId});

        const roomParams: JoinRoomInfo = {
            type: userType,
            roomId: this._sessionState.currentRoom._id,
            roomCode: this._sessionState.sessionShortId,
            platformType: this._clientInfo.platformType,
            clientVersion: this._clientInfo.clientVersion,
            modeInfo: {
                userAgent: this._clientInfo.browserInfo.userAgent,
                deviceCaps: this._clientInfo.webRtcInfo
            }
        };

        // @ts-ignore
        return this._sessionSocket
            .joinSessionRoom(roomParams)
            .then(() => {
                if (this.isSessionInitiating) {
                    const resultModel: SessionConnectResult = {
                        sessionRoomId: this._sessionState!.currentRoom!._id,
                        sessionShortId: this._sessionState!.sessionShortId,
                        sessionLinkUrl: this._sessionState!.sessionLinkUrl,
                        prefixLength: this._sessionState!.prefixLength
                    };

                    //Timeout used in order to execute session promise callback in async way.
                    const initResolver = this._sessionInitResolver;

                    setTimeout(() => {
                        const end = new Date().getTime(),
                            totalTime = (end - start) / 1000;

                        if (initResolver) {
                            this._eventLogService.successfullyJoinedSessionRoom({resultModel, totalTime: totalTime});

                            initResolver(OperationResult.getSuccessResult(resultModel));
                        } else {
                            this._eventLogService.joinSessionRoomFailed({
                                guid: resultModel.sessionShortId,
                                totalTime: totalTime
                            });
                            console.error('Something went wrong - please check!');
                        }
                    });
                }
            })
            .catch((err) => {
                const end = new Date().getTime(),
                    totalTime = (end - start) / 1000;

                this._eventLogService.joinSessionRoomFailed({
                    guid: this._sessionState!.sessionShortId,
                    totalTime: totalTime,
                    error: err
                });

                return this._sessionInitRejector!(
                    OperationResult.getFailResult(`Socket failed to join with err: ${err}`)
                );
            });
    }

    joinBySessionId(sessionId: string) {
        const params = {
            roomId: '',
            sessionId,
            sessionUrl: '',
            userType: UserType.dashboard,
            byShortId: true
        };

        return this.joinSession(params);
    }

    private subscribeExternalListener(event: any, listener: any): SubscriptionDisposer {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        this._emitter.on(event, listener);

        return () => {
            self._emitter.removeListener(event, listener);
        };
    }

    private createInitFlowPromise(): void {
        this._sessionInitPromise = new Promise<OperationResult<SessionConnectResult>>((resolve, reject) => {
            this._sessionInitResolver = resolve;
            this._sessionInitRejector = reject;
        }) as Promise<OperationResult<SessionConnectResult>>;
    }

    private finalizeSessionInitFlow() {
        this._sessionInitPromise = null;
        this._sessionInitResolver = null;
        this._sessionInitRejector = null;
    }

    private throwIfNotActiveSession(naProp: string) {
        if (!this.isSessionActive) {
            throw new Error(`${naProp} is not available while there no active session`);
        }
    }
}
