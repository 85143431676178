'use strict';

import forEach from 'lodash/forEach';

import genericDialogView from '../../states/dashboard/main/dialogs/generic-dialog.view.html';
import {GenericDialogController} from '../../states/dashboard/main/dialogs/generic-dialog.controller';
import {DIALOG_TYPES} from '../../states/dashboard/main/dialogs/generic-dialog.settings';
import {NotifiedMessageController} from '../../states/dashboard/dialogs/notified-message.controller';
import notifiedMessageView from '../../states/dashboard/dialogs/notified-message.view.html';
import {getRootStore} from '../../_react_/app.bootstrap';

class GenericDialogModal {
    constructor($modal, isMobile) {
        this.$modal = $modal;
        this.instance = null;
        this.isMobile = isMobile;
        this.isOpened = false;
    }

    show(dialogType, message, dir, isErrorMsg) {
        const isNotificationType = dialogType === DIALOG_TYPES.NOTIFICATION;

        if (!this.isOpened) {
            this.isOpened = true;
            this.instance = this.$modal.open({
                animation: true,
                template: isNotificationType ? notifiedMessageView : genericDialogView,
                controller: isNotificationType ? NotifiedMessageController : GenericDialogController,
                controllerAs: 'vm',
                backdrop: 'static',
                backdropClass: 'backdrop-hidden',
                windowClass: isNotificationType
                    ? 'notified-message-alert'
                    : 'on-show-alert' + (this.isMobile ? ' mobile-modal' : ''),
                resolve: {
                    message: () => message,
                    dir: () => dir,
                    dialogType: () => dialogType,
                    isErrorMsg: () => isErrorMsg
                }
            });

            this.result = this.instance.result.catch(() => false).finally(() => (this.instance = null));
        }
    }

    hide() {
        if (!this.instance) {
            return;
        }

        this.isOpened = false;
        this.instance.close();
    }
}

export class GenericDialogFactory {
    constructor($modal) {
        'ngInject';

        this.$modal = $modal;

        const environmentDetect = getRootStore().environmentService;

        this.isMobile = environmentDetect.isMobile(getRootStore().displayTabletAsDesktop);
        this.trackedDialogs = [];
    }

    show(dialogType, message, dir, isErrorMsg = false, track = true) {
        const dialog = new GenericDialogModal(this.$modal, this.isMobile);

        dialog.show(dialogType, message, dir, isErrorMsg);

        if (track) {
            this.trackedDialogs.push(dialog);
        }

        return dialog;
    }

    hideAll() {
        forEach(this.trackedDialogs, (dialog) => dialog.hide());
    }
}
