// eslint-disable-next-line no-redeclare
/* globals Hammer */
'use strict';

import './main.style.scss';
import './main-mobile.style.scss';
import './main-mini.style.scss';
import './main-mini-mobile.style.scss';
import './main-T&C-camera.style.scss';
import './main-camera-pre-approval.style.scss';
import './guidance/main-photo-selection.style.scss';

import mainWizardView from './main-T&C.view.html';
import mainCameraPreApproveView from './main-camera-pre-approval.view.html';
import mainCameraView from './main-camera.view.html';
import observationView from './main-observation.view.html';
import mainGuidanceView from './guidance/main-photo-selection.view.html';
import mainOneClickGuidanceView from './guidance/one-click-guidance.view.html';
import mainMiniCommonView from './main-mini-common.view.html';
import {reaction, runInAction} from 'mobx';
import get from 'lodash/get';
import find from 'lodash/find';
import last from 'lodash/last';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import some from 'lodash/some';
import map from 'lodash/map';
import concat from 'lodash/concat';
import findIndex from 'lodash/findIndex';
import assign from 'lodash/assign';
import chain from 'lodash/chain';

// Dialog controllers and views
import sendTextView from './dialogs/send-text.view.html';
import {SendTextController} from './dialogs/send-text.controller.js';

import libraryDialogView from './dialogs/library.view.html';
import {LibraryDialogController} from './dialogs/library.controller.js';

import historyDialogView from './dialogs/history.view.html';
import {HistoryDialogController} from './dialogs/history.controller.js';

import scanResultView from './dialogs/scan-result.view.html';
import {ScanResultController} from './dialogs/scan-result.controller.js';
import {SCANNERS} from '../../../services/ts-scan/ts-scan.settings';

import searchResultsDialogView from './dialogs/search-results.view.html';
import {SearchResultsController} from './dialogs/search-results.controller.js';
import {SmartSolutionController} from '../../../_react_/states/dashboard/smart-solution/controller';
import {ConnectionTypesEnum} from '../../../../app/_react_/services/Session/SessionContracts';
import {KnownMediaStream, NO_REQUESTED_STREAM} from '@techsee/techsee-media-service/lib/MediaConstants';
import InteractionSummaryEventService, {
    InteractionSummaryEvents
} from '@techsee/techsee-ui-components/lib/interaction-summary/eventService';
import {CONNECTION_STATUS} from '../../../services/ts-locale-helper/ts-locale-helper.settings.js';
import {
    ANNOTATION_COLORS,
    CLIENT_IN_PHOTO_MODE_STATES,
    IMAGE_MODES,
    IOS_DEVICES,
    PHOTO_SELECTION_STATE,
    RESOURCE_TYPES,
    STORAGE_TYPES,
    VIDEO_MODES,
    MIN_INTERVAL_TO_DRAW_MAGIC_MARKER,
    MIN_ALLOWED_STROKE_LENGTH
} from './main.settings.js';
import {
    MeetingMode,
    UserInteractionTriggerAction,
    UserType
} from '@techsee/techsee-common/lib/constants/room.constants';
import {accountTypes, TriggeredDataCollectionSource} from '@techsee/techsee-common/lib/constants/account.constants';
import {PlatformType, LIBRARY_DIR_DEPTH} from '@techsee/techsee-common/lib/constants/utils.constant';
import utils from '@techsee/techsee-common/lib/utils';
import {getRootStore} from '../../../_react_/app.bootstrap';
import {SpeedtestController} from '../../../components/main/speedtest-modal/SpeedtestModal.controller';
import {senderTypes} from '@techsee/techsee-common/lib/constants/resource.constants';
import {MeetingStateEvents} from '../dashboard.contracts';
import {getAppTracer} from '../../../app.tracer';
import {ArMeasurementService} from '../../../_react_/services/ArMeasurementService';
import {
    AR_MEASUREMENT_DISTANCE_COPIED_MESSAGE_ALERT_COLOR,
    AR_MEASUREMENT_DISTANCE_COPIED_MESSAGE_ALERT_TIMEOUT,
    DS_CONTROL_REQUEST_SENT_TO_CUSTOMER_COLOR,
    DS_CONTROL_REQUEST_SENT_TO_CUSTOMER_TIMEOUT
} from '../dashboard.settings';
import {SCANNER_SRC_TYPES} from '@techsee/techsee-common/lib/constants/scan.constants';
import {MessageType} from '../../../_react_/services/JsApiService';
import {ReliabilityStrategy} from '@techsee/techsee-client-infra/lib/infra/RoomChannelContracts';
import {WifiAnalyzerController} from '../../../_react_/components/wifi-analyzer/controller';
import {WifiAnalyzerMode} from '../../../_react_/components/wifi-analyzer/component';

const trace = getAppTracer('MainController');

const CLASSIFY_EVENT_SOURCES = {
    VIDEO_CLICKED: 'VIDEO_CLICKED',
    MANUAL_ANALYZE: 'MANUAL_ANALYZE',
    IMAGE_UPLOADED_BY_CUSTOMER: 'IMAGE_UPLOADED_BY_CUSTOMER',
    IMAGE_UPLOADED_BY_AGENT: 'IMAGE_UPLOADED_BY_AGENT',
    SCAN: 'SCAN'
};

export class MainController {
    constructor(
        $rootScope,
        $scope,
        $stateParams,
        tsStateHelper,
        tsCanvasAnnotate,
        tsVideoControl,
        tsChatApi,
        $q,
        $modal,
        meetingHistory,
        roomData,
        $translate,
        eventLog,
        tsBusy,
        accountData,
        tsLocaleHelper,
        $localStorage,
        $location,
        $anchorScroll,
        $timeout,
        wizardState,
        tsResponsiveUtils,
        db,
        currentUser,
        $window,
        tsUrlUtils,
        tsVideoUtils,
        preloadedImage,
        preloadedVideo,
        scanners,
        ROLES,
        tsScan,
        ErrorDialogModal,
        tsInterfaceHelper,
        tsSnapshotReminderService,
        tsScanArea,
        tsCobrowsingService,
        tsDeviceClassificationService,
        SharingService,
        tsMeasure,
        messagesService,
        dashboardMediaService,
        AudioService,
        AutoDataCollectionServices
    ) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.dashboardMediaService = dashboardMediaService;
        this.videoSubscriber = null;
        this.audioService = AudioService;
        this.autoDataCollectionServices = AutoDataCollectionServices;
        trace.info('Constructing main controller');
        this.syncMeeting = this.syncMeeting.bind(this);
        this._snapshotToImg = this._snapshotToImg.bind(this);
        this.onVideoSubscriberChanged = this.onVideoSubscriberChanged.bind(this);
        this.deregisterMeetingSyncRequest = $rootScope.$on(MeetingStateEvents.MeetingSyncRequest, () =>
            this.syncMeeting()
        );

        this.classificationService = tsDeviceClassificationService;
        this.UserType = UserType;
        this.MeetingMode = MeetingMode;

        this.views = {
            wizardView: mainWizardView,
            cameraPreApproveView: mainCameraPreApproveView,
            cameraView: mainCameraView,
            observationView: observationView,
            guidanceView: mainGuidanceView,
            oneClickGuidanceView: mainOneClickGuidanceView
        };

        this.PHOTO_SELECTION_STATE = PHOTO_SELECTION_STATE;
        this.annotateService = tsCanvasAnnotate;
        this.videoControlService = tsVideoControl;
        this.setupColors();
        this.connectionTypes = ConnectionTypesEnum;

        this.tsScsan = tsScan;
        this.tsSharingService = SharingService;
        this.chatApi = tsChatApi.service;
        this.messagesService = messagesService;
        this.$q = $q;
        this.$modal = $modal;
        this.$scope = $scope;
        this.stateHelper = tsStateHelper;
        this.$timeout = $timeout;
        this.observe = $stateParams.observe;
        this.showWizardSetting = wizardState.showWizard && !wizardState.finished;
        this.environmentDetect = getRootStore().environmentService;
        this.browserUtilsService = getRootStore().browserUtilsService;
        this.displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;
        this.isIE11 = this.environmentDetect.isIE11();
        this.isTablet = this.environmentDetect.isTablet(this.displayTabletAsDesktop);
        this.miniDashboard = this._getDashboardScope().miniDashboard;
        this.embeddedDashboard = this._getDashboardScope().embeddedDashboard;
        this.mainMiniCommonView = mainMiniCommonView;
        this.isLivePointerOnMobileDashboardEnabled = get(
            accountData,
            'protectedSettings.enableLivePointerOnMobileDashboard'
        );
        this.isPostImageEnabledOnAccount =
            get(accountData, 'protectedSettings.exposePostMessageApi.enabled') &&
            get(accountData, 'protectedSettings.exposePostMessageApi.image');
        this.$localStorage = $localStorage;
        this.eventLog = eventLog;
        this.$translate = $translate;
        this.$window = $window;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.wizardState = wizardState;
        this.responsiveUtils = tsResponsiveUtils;
        this.db = db;
        this.currentUser = currentUser;
        this.library = [];
        this.libraryTree = this._listToTree('library', []);
        this.expandedLibraryNodes = this._autoExpandEmptyTopLevel(this.libraryTree);
        this._loadLibrary();
        this.urlUtils = tsUrlUtils;
        this.videoUtils = tsVideoUtils;
        this.errorDialogModal = ErrorDialogModal;
        this.offlineMode = this.urlUtils.getParamValue('offline') === 'true';
        this.loadedFromHistory = this.urlUtils.getParamValue('history') === 'true';
        this.resourceTypes = reduce(
            RESOURCE_TYPES,
            (resourceTypes, value) => {
                resourceTypes[value.type.toLowerCase()] = value.type;

                return resourceTypes;
            },
            {}
        );
        this.libraryEnabled = accountData.settings ? accountData.settings.library : false;
        this.libraryTreeOptions = {
            dirSelectable: false
        };
        // We keep track of the last selected image, to reset selection when the
        // library  window is closed without a selection (or a non-image file selection)
        this.$scope.$watch(
            () => this.selectedLibraryNode,
            (newValue, oldValue) => {
                this._getDashboardScope().isFromLibrary = !isEmpty(newValue);
                this.prevSelectedLibraryImg = oldValue && oldValue.type === 'img' ? oldValue : {};
            }
        );

        this.libraryDialogRoot = this.$translate.instant('LIBRARY.VIEW.ROOT');

        this.roomData = roomData;
        this.offlineRoom = roomData.offline && !this.offlineMode;
        this.busy = tsBusy;
        this.accountData = accountData;
        this.allowPredefined = accountData.settings ? accountData.settings.predefinedResources : false;
        this.allowPauseByAgent = accountData.settings ? accountData.settings.allowPauseByAgent : true;
        this.allowScreenPause = get(accountData, 'protectedSettings.liveSdk.allowScreenSharingPaused');
        this.enableVideoUploads = accountData.protectedSettings
            ? accountData.protectedSettings.allowUploadVideoClips
            : false;
        this.enableHistory = accountData.protectedSettings ? accountData.protectedSettings.enableHistory : false;
        this.saveSnapshots = this.enableHistory && accountData.protectedSettings.saveSnapshots;
        this.imageTagging = accountData.protectedSettings ? accountData.protectedSettings.imageTagging : false;
        this.allowUploadImageFileFromDashboard = get(
            accountData,
            'protectedSettings.allowUploadImageFileFromDashboard'
        );
        this.snappedImageAppearOnCanvas = get(this.accountData, 'protectedSettings.snappedImageAppearOnCanvas');
        this.cameraModeOnly = get(this.accountData, 'settings.cameraModeOnly');
        this.allowCoBrowsing = get(accountData, 'protectedSettings.coBrowsing.enabled');
        this.allowSwitchModeDuringInitiation =
            get(this.accountData, 'settings.allowSwitchModeDuringInitiation') && !this.cameraModeOnly;
        this.displayTermsInPhotoStream = get(this.accountData, 'settings.displayTermsInPhotoStream');
        this.allowOneClickPhotoMode = get(this.accountData, 'settings.allowOneClickPhotoMode');
        this.isHighImageSharingQuality = get(this.accountData, 'protectedSettings.imageSharingQuality') === 1;
        this.showScanners = accountData.protectedSettings ? accountData.protectedSettings.imageAnalysis : false;
        this.enableMobileSpeedtest = get(this.accountData, 'protectedSettings.mobileSpeedtest.enabled');
        this.browserUtilsService = getRootStore().browserUtilsService;
        this.displaySpeedtestGuidance = false;
        this.domUtilsService = getRootStore().domUtilsService;
        this.translate = getRootStore().localizationService.translate;
        this.speedtestService = getRootStore().speedtestService;
        this.interactionSummary = null;
        this.interactionSummaryPreviews = [];
        this.onCloseGuidanceSpeedtest = this.onCloseGuidanceSpeedtest.bind(this);
        this.videoFormatResolution = null;
        this.dateFormat = get(this.accountData, 'protectedSettings.reportsDateFormat');

        if (this.enableMobileSpeedtest && this.isToggleEnabled_SessionToolbar()) {
            this.speedtestController = new SpeedtestController(this.translate, this.domUtilsService);
        }

        InteractionSummaryEventService.on(InteractionSummaryEvents.INTERACTION_SUMMARY, (data) => {
            // prettier-ignore
            if (find(this.interactionSummaryPreviews, (preview) => preview.creationTimestamp === data.creationTimestamp)) {
                return;
            }

            this.interactionSummaryPreviews = [data, ...this.interactionSummaryPreviews];
        });

        this.deregisterPreviews = $rootScope.$on('previews', (event, previews) => {
            this.interactionSummaryPreviews = [...previews];
        });

        InteractionSummaryEventService.on(InteractionSummaryEvents.INTERACTION_SUMMARY_LOG, (log) => {
            if (get(log, 'isError')) {
                this.eventLog.getInteractionSummaryFailed(log.data);
            } else {
                this.eventLog.getInteractionSummarySuccess();
            }
        });

        InteractionSummaryEventService.on(InteractionSummaryEvents.SEND_SELECTED_IMAGE, (image) => {
            this.selectClickedResource({url: image});
        });

        this.expandRightBar = false;
        this.isInputTextFocus = false;
        this.dir = tsLocaleHelper.isRTL() ? 'rtl' : 'ltr';
        this.videos = [];
        this.isPaused = false;
        this.preloadedImage = preloadedImage;
        this.preloadedVideo = preloadedVideo;
        this.currentResource = {};
        this.clientDisconnected = false;
        this.tsInterfaceHelper = tsInterfaceHelper;
        this.tsScanArea = tsScanArea;
        this.tsCobrowsing = tsCobrowsingService;
        this.tsMeasure = tsMeasure.service;
        this.clickCanvasTime = null;
        this.videoClickPosition = {};
        this.activeTool = 'arrow';
        this.showLocationPopover = false;
        this.isMobileDashboard = this.environmentDetect.isMobile();
        this.isDesktopView = this.environmentDetect.isDesktop();
        this.magicMarkerSettings = get(this.accountData, 'protectedSettings.magicMarker');
        this.allowMagicMarker =
            get(this.magicMarkerSettings, 'dashboard.enable') &&
            (!this.isMobileDashboard || get(this.magicMarkerSettings, 'dashboard.enableMobile'));
        this.allowCustomerMagicMarker = this.allowMagicMarker && get(this.magicMarkerSettings, 'client.enable');
        this.triggeredDataCollectionOnAgentMagicMarkerEnabled = get(
            this.accountData,
            'protectedSettings.triggeredDataCollection.enableOnAgentMagicMarker'
        );
        this.triggeredDataCollectionOnCustomerMagicMarkerEnabled = get(
            this.accountData,
            'protectedSettings.triggeredDataCollection.enableOnCustomerMagicMarker'
        );
        this.triggeredDataCollectionOnAgentImageSnappedEnabled = get(
            this.accountData,
            'protectedSettings.triggeredDataCollection.enableOnAgentImageSnapped'
        );

        this.IMAGE_MODES = IMAGE_MODES;

        this.video = {
            focus: true,
            isClientVisible: true
        };

        this.video.enableDashboardMagicMarker = this.isMagicMarkerEnabled;
        this.video.enableCustomerMagicMarker = this.isCustomerMagicMarkerEnabled;
        this.video.magicMarker = this.magicMarkerSettings;

        this.streamAction = debounce(this._streamActionOnce, 300, {
            leading: true,
            trailing: false
        });

        this.takeVideoControlScreenshot = debounce(this._takeVideoControlScreenshotOnce, 300, {
            leading: true,
            trailing: false
        });

        this.meetingHistory = meetingHistory;
        this.loaderText = this.translate('MAIN.VIEW.IDLE_CONNECTING');
        this.zoomFactor = 1;
        this.showWizard = !this.offlineRoom && !this.offlineMode;
        this.hideHistory = !get(this.accountData, 'protectedSettings.showHistoryInSession') && !this.offlineMode;

        this.saveScreenshotsOnLocalStorage = utils.isInIframe(window) || (this.miniDashboard && !this.isDesktopView);
        this.initScreenshots();

        this.wifiAnalyzerController = new WifiAnalyzerController();
        this.wifiAnalyzerMode = WifiAnalyzerMode;

        this._initHandlers();

        if (
            !this.hideHistory &&
            !this.offlineRoom &&
            !this.showWizardSetting &&
            !this.offlineMode &&
            !isEmpty(this.chatApi.dashboard) &&
            !(this.chatApi.dashboard.roomStarted && this.chatApi.client.connected)
        ) {
            // If the customer has a history, we show that to the dashboard user,
            // instead of waiting in the entry screen
            this.openCustomerHistoryDialog();
        }

        if (this.accountData && this.allowPredefined) {
            this.initPredefined();
        }

        this.libraryTaggingMode = this.offlineMode && this.currentUser.role === ROLES.KNOWLEDGE_EXPERT;

        this.allowMessages = !get(accountData, 'settings.disableChatMessages') && !this.libraryTaggingMode;

        // Right bar section states
        this.imageSectionOpen = this.offlineMode && !this.videoSectionOpen && !this.libraryTaggingMode;
        this.videoSectionOpen = this.offlineMode && !this.imageSectionOpen && !this.libraryTaggingMode;
        this.msgSectionOpen = false;
        this.libSectionOpen = !this.libraryEmpty() && !this.imageSectionOpen && !this.videoSectionOpen;
        this.isAsianLanguage = this.accountData.settings.language === 'jp_JP';

        if ((this.offlineMode && !this.libraryTaggingMode) || this.observe) {
            this._restoreOfflineHistory();
        }

        this.searchTags = accountData.protectedSettings
            ? accountData.protectedSettings.imageTagging && accountData.protectedSettings.searchTags
            : false;
        this.searchHistoryTags =
            (accountData.protectedSettings ? accountData.protectedSettings.searchHistoryTags : false) &&
            !this.libraryTaggingMode &&
            !this.hideHistory;
        this.searchLibraryTags = accountData.protectedSettings
            ? accountData.protectedSettings.searchLibraryTags
            : false;

        if (this.preloadedImage.resource) {
            this.setResource(this.preloadedImage.resource);
        } else if (this.preloadedVideo.resource) {
            this.setResource(this.preloadedVideo.resource);
        }

        this.scanners = scanners;
        this.textScanner = find(this.scanners, (scan) => get(scan, 'settings.reportName') === SCANNERS.TEXT.reportName);
        this.searchScannedTags =
            this.showScanners && accountData.protectedSettings.searchScannedTags && this.libraryTaggingMode;

        this.agentSnapshotReminder =
            // eslint-disable-next-line no-constant-binary-expression
            {
                ...get(accountData, 'settings.agentSnapshotReminder'),
                roomId: this.roomData._id
            } || {};

        this.tsSnapshotReminderService = tsSnapshotReminderService;
        this.$scope.$watch(
            () => get(this.video, 'focus'),
            (newValue, oldValue) => {
                if ((oldValue && !newValue) || (!oldValue && newValue)) {
                    this.tsSnapshotReminderService.videoFocusChanged(newValue);
                }
            }
        );

        this.autoDataCollection = accountData.protectedSettings && accountData.protectedSettings.autoDataCollection;

        this.autoDataCollectionServices.initAutoDataCollection(
            this.autoDataCollection,
            this._isCaptureAvailable.bind(this),
            this._getSnapshotFromKnownStream.bind(this),
            this._snapshotToImg,
            this.tsSharingService.uploadAutoCollectedImage
        );

        this.video.roomId = this.roomData._id;
        this.video.accountId = this.accountData._id;

        this.CLIENT_IN_PHOTO_MODE_STATES = CLIENT_IN_PHOTO_MODE_STATES;

        this.CLASSIFY_EVENT_SOURCES = CLASSIFY_EVENT_SOURCES;
        this.classifyDeviceData = null;

        this.tsSnapshotReminderService.init(this.agentSnapshotReminder);

        this.measurementEnabled = !!this.roomData.initiateWithMeasure;

        if (this.measurementEnabled) {
            this.initiateMeasurement();
        }

        if (this.miniDashboard) {
            this.miniDashboardScanError = this.$translate.instant('DASHBOARD_MINI.SCAN_ERROR');
        }

        // Handle snapshots that existed before state load, probably page refreshed
        if (!isEmpty(this.screenshots) && !this.currentResource.url) {
            this.setResource(last(this.screenshots), true);
            this.scrollToImagesTop();

            this.imageSectionOpen = true;

            if (this.isIE11) {
                this._ieRedrawRightSidebar();
            }
        }

        this.$scope.$watch(
            () => this.classifyDeviceData,
            () => {
                this.classificationService.imagePreviewClose();
                this.classificationService.solutionClose();
            }
        );

        this.$scope.$watch(
            () => this.screenshots.length,
            () => {
                if (!isEmpty(this.screenshots)) {
                    this._getDashboardScope().hasScreenshots = true;
                }
            }
        );

        this.$scope.$watch(
            () => get(this.currentResource, 'url'),
            () => {
                if (get(this.currentResource, 'url')) {
                    this._getDashboardScope().setCurrentResource({
                        type: 'url',
                        url: get(this.currentResource, 'url'),
                        useOriginalImage: this.isHighImageSharingQuality
                    });
                }
            }
        );

        this.$scope.$watch(
            () => this.isMeetingModesReady(this._getDashboardScope().cameraApproval),
            () => {
                const leftBarController = this._getDashboardScope().leftBarController;

                if (leftBarController) {
                    leftBarController.setDeviceClassificationDisabled(
                        this.isMeetingModesReady(
                            !this._getDashboardScope().isAssurantDeviceSet && this._getDashboardScope().cameraApproval
                        ) || this.offlineRoom
                    );
                }
            }
        );

        this.requestMode = this.requestMode.bind(this);

        this.$scope.$watch(
            () => this.modesEnabled,
            () => {
                if (this.isToggleEnabled_newHeaderFooterLeftbar() && !this.observe) {
                    const leftBarController = this._getDashboardScope().leftBarController;

                    if (leftBarController) {
                        leftBarController.disableEnableModes(this.modesEnabled);

                        if (this.modesEnabled) {
                            leftBarController.setSwitchModes(this.requestMode);
                        }
                    }
                }
            }
        );

        this.$scope.$watch(
            () => this.isDesktopSharingSession,
            () => {
                runInAction(() => {
                    this._getDashboardScope().dashboardState.sessionInfo.isDesktopSharing =
                        this.isDesktopSharingSession;
                });
            }
        );

        if (this.miniDashboard) {
            this.liveVideoText = this.$translate.instant('DASHBOARD_MINI.VIEW.CAMERA_LIVE');
        }

        this.smartSolutionController = new SmartSolutionController(this.translate);

        const onClickStep = (step) => {
            if (get(step, 'image.url')) {
                this.setResource({url: step.image.url});
                $rootScope.safeApply();
            }
        };

        this.$scope.$watch(
            () => this._getDashboardScope().selfServiceController,
            () => {
                if (this._getDashboardScope().selfServiceController && !this.alreadySetClickStepSelfService) {
                    this._getDashboardScope().selfServiceController.onClickStep(onClickStep);
                    this.alreadySetClickStepSelfService = true;
                }
            }
        );

        this.$scope.$watch(
            () => get(this.dashboardMediaService, 'isVoipEnabled'),
            () => {
                this.audioService.checkIfVoipIsNotEnable();
                this.$rootScope.safeApply();
            }
        );

        this.$scope.$watch(
            () => this.audioService.hasAudioStream() && get(this.chatApi, 'client.audioHandshakeSuccess') !== false,
            () => {
                this.audioService.establishAudioStream();
                this.$rootScope.safeApply();
            }
        );

        this.smartSolutionController.onClickStep(onClickStep);

        this.smartSolutionController.onSendPreparationSteps((steps) => {
            this.sendSmartSolutionSteps(steps);
        });

        this.smartSolutionController.onSendSolutionSteps((steps) => {
            this.sendSmartSolutionSteps(steps);
        });

        this.isFlashlightAvailableOnClient = this.isToggleEnabled_flashLight();

        this.resourcesListener();

        $rootScope.$emit(MeetingStateEvents.MeetingStateConstructed);
    }

    _loadLibrary() {
        if (this.currentUser.accountId) {
            return this.db.Resource.findAll(
                {
                    accountId: this.currentUser.accountId
                },
                {
                    bypassCache: true
                }
            )
                .then((library) => {
                    this.library = library;

                    this.libraryTree = this._listToTree('library', this.library);

                    this.expandedLibraryNodes = this._autoExpandEmptyTopLevel(this.libraryTree);

                    trace.info(
                        `Library loading success - currentUser: ${this.currentUser._id}, library array size: ${this.library.length}`
                    );
                })
                .catch((err) => {
                    this.eventLog.libraryLoadingFailed({
                        currentUserId: this.currentUser._id,
                        error: err?.message || JSON.stringify(err).slice(0, 1000)
                    });
                });
        }
    }

    _getSnapshotFromKnownStream(options) {
        return this.dashboardMediaService
            .getSnapshotFromKnownStream(KnownMediaStream.USER_VIDEO_STREAM, options)
            .catch((err) => {
                // Silently fail on failures due to lack of stream
                if (err && err.message === NO_REQUESTED_STREAM) {
                    return null;
                }

                throw err;
            });
    }

    initiateMeasurement() {
        const measurementAccountSettings = get(this.accountData, 'protectedSettings.liveSdk.measurement');

        this.arMeasurement = new ArMeasurementService(
            this.chatApi,
            this.tsSharingService,
            this.eventLog,
            measurementAccountSettings
        );

        this.arMeasurement.on('distancesCopied', () => {
            this.displayToastMessage(
                'DASHBOARD_MEASUREMENT_DISTANCE_COPIED_SUCCESSFULLY',
                AR_MEASUREMENT_DISTANCE_COPIED_MESSAGE_ALERT_COLOR,
                AR_MEASUREMENT_DISTANCE_COPIED_MESSAGE_ALERT_TIMEOUT
            );
        });
    }

    toggleInteractionSummaryWidget(params) {
        InteractionSummaryEventService.toggleWidget(params);
    }

    syncMeeting() {
        trace.info('Sync Meeting state start');

        this.syncAudioSubscriber();
        this.syncPauseState();

        this.clientDisconnected = !this.chatApi.client.connected && this.chatApi.client.connected !== undefined;

        if (!this.wizardState.finished && this.platform && this.meetingHistory.length === 0) {
            this.showWizard = true;
            this.wizardState.platform = this.platform;
            this.wizardState.showWizard = true;

            if (this.platform === 'apple' && this.wizardState.step !== 1) {
                this.goToWizardStep(0);
            }
        } else if (
            (this.chatApi.client.mode === MeetingMode.images && !this.displayTermsInPhotoStream) ||
            includes([MeetingMode.oneClick, MeetingMode.coBrowsing], this.chatApi.client.mode)
        ) {
            this.finishWizard();
        }

        const clientMode = this.chatApi.client.mode;

        if (
            !this.chatApi.client.preparing ||
            includes([MeetingMode.images, MeetingMode.oneClick, MeetingMode.coBrowsing], clientMode)
        ) {
            this.busy.free();

            switch (this.chatApi.client.mode) {
                case MeetingMode.video:
                case MeetingMode.screen:
                case MeetingMode.appSharing:
                case MeetingMode.videoApplication:
                    this.wasInVideoMode = true;

                    if (!this.video.enabled) {
                        this.video = {
                            enabled: true,
                            focus: true,
                            isClientVisible: this.video.isClientVisible,
                            mode: VIDEO_MODES.CURSOR_SHARING,
                            sizeTarget: '.dashboard-main-image-container',
                            accountId: this.accountData._id,
                            roomId: this.roomData._id,
                            enableDashboardMagicMarker: this.isMagicMarkerEnabled,
                            enableCustomerMagicMarker: this.isCustomerMagicMarkerEnabled,
                            magicMarker: this.magicMarkerSettings
                        };
                        this.finishWizard();
                    }

                    break;

                case MeetingMode.coBrowsing:
                case MeetingMode.oneClick:
                case MeetingMode.images:
                    this.video = {enabled: false};

                    if (get(this.chatApi, 'client.mode') === MeetingMode.coBrowsing) {
                        this.hideWidgets = true;
                        this.tsCobrowsing.start(
                            this.accountData._id.toString(),
                            this.roomData._id.toString(),
                            get(this.accountData, 'protectedSettings.coBrowsing.surflyWidgetKey')
                        );
                    }

                    break;

                default:
            }
        }

        this._checkModesAvailability();
    }

    syncAudioSubscriber() {
        if (
            this.audioService.clientStream() ||
            !this.dashboardMediaService.isVoipEnabled ||
            !this.audioService.isAudioSupportedByDashboard
        ) {
            return;
        }

        if (!this.syncAudio) {
            this.syncAudio = true;
            this.audioService.initAudioAlert();
            this.$rootScope.safeApply();
        }

        this.audioService.createSubscriber();
    }

    syncPauseState() {
        if (this.chatApi.client.pausedBy === UserType.dashboard) {
            this.videoPausedByDashboard = true;
            this.isPaused = true;
        } else if (this.chatApi.client.pausedBy === UserType.client) {
            this.videoPausedByDashboard = false;
            this.isPaused = true;
        } else {
            this.videoPausedByDashboard = false;
            this.isPaused = false;
        }

        this.video.isPaused = this.isPaused;
        this._pagePlacementPause(this.isPaused);
    }

    onVideoSubscriberChanged(videoSubscriber) {
        this.videoSubscriber = videoSubscriber;
    }

    _setScreenshots(value) {
        this.saveScreenshotsOnLocalStorage
            ? this.browserUtilsService.saveToLocalStorage(`screenshots_${this.roomData._id}`, value)
            : this.browserUtilsService.saveToSessionStorage('screenshots', value);
    }

    _getScreenshots() {
        return this.saveScreenshotsOnLocalStorage
            ? this.browserUtilsService.getFromLocalStorage(`screenshots_${this.roomData._id}`)
            : this.browserUtilsService.getFromSessionStorage('screenshots');
    }

    _setScreenshotsRoomId(value) {
        return this.saveScreenshotsOnLocalStorage
            ? this.browserUtilsService.saveToLocalStorage(`screenshotsRoomId_${value}`, value)
            : this.browserUtilsService.saveToSessionStorage('screenshotsRoomId', value);
    }

    _getScreenshotsRoomId() {
        return this.saveScreenshotsOnLocalStorage
            ? this.browserUtilsService.getFromLocalStorage(`screenshotsRoomId_${this.roomData._id}`)
            : this.browserUtilsService.getFromSessionStorage('screenshotsRoomId');
    }

    initScreenshots() {
        if (!this._getScreenshots() || this._getScreenshotsRoomId() !== this.roomData._id) {
            this._setScreenshots([]);
            this._setScreenshotsRoomId(this.roomData._id);
        }

        this.screenshots = this._getScreenshots();

        forEach(this.screenshots, (screenshot) => {
            if (screenshot.dataUrl) {
                screenshot.url = this._snapshotToImg(screenshot.dataUrl).img;
            }
        });
    }

    get isMagicMarkerEnabled() {
        // TODO: Instead of !this.mode should consider the starting mode

        return this.allowMagicMarker && (!this.mode || this.mode === MeetingMode.video) && !this.observe;
    }

    get isCustomerMagicMarkerEnabled() {
        return (
            this.allowCustomerMagicMarker &&
            !(this.isDesktopSharingSession || this.isApplicationSharing || this.isScreenSharing)
        );
    }

    get isTitleShown() {
        // The title should be shown, when:
        // we are in live video and if video paused then it should be paused by dashboard;
        // if StretchedVideoMode and RoundedFloatingVideo are turned on the show the title;
        // Not show the title if guidance showing;
        // Show the title only if not in guidance and canvasReady or videoReady
        // (because, after session ended, the guidance props in some cases is true, but not canvasReady or videoReady);
        // if ScanCaptureDesign is turned on and we are in scan mode then show the title;

        const isSharingMode =
            this.isScreenSharing || this.isApplicationSharing || this.isDesktopSharing || this.isVideoApplication;
        const isCanvas = this.isCanvas && !this.offlineMode;

        return (
            (((this.isLiveVideo || isCanvas || isSharingMode) &&
                this.isToggleEnabled_StretchedVideoMode() &&
                this.isToggleEnabled_RoundedFloatingVideo() &&
                !this.isInGuidnace &&
                (this.annotateService.canvasReady || (this.video.focus && this.video.enabled))) ||
                (this.isScanMode && this.isToggleEnabled_ScanCaptureDesign()) ||
                this.isPhotoTitle) &&
            !this._getDashboardScope().isEnableWifiScan()
        );
    }

    get isInGuidnace() {
        return this.displayPhotoStreamGuidnace() || this.displayOneClickGuidnace();
    }

    get mode() {
        return get(this.chatApi, 'client.mode');
    }

    get isSupportTextAnnotation() {
        return get(this.accountData, 'protectedSettings.isSupportTextAnnotation');
    }

    get isPhotoTitle() {
        return (this.mode === MeetingMode.oneClick || this.mode === MeetingMode.images) && !this.screenshots.length;
    }

    get isLiveVideo() {
        return (
            this.mode === MeetingMode.video &&
            this.video.focus &&
            this.video.mode === this.video.MODES.CURSOR_SHARING &&
            this.video.mode !== this.video.MODES.SCANNING
        );
    }

    get isMiniDashboardLoading() {
        const mode = this.chatApi.client.mode;

        const isIdleMode = this._getDashboardScope().connected === CONNECTION_STATUS.IDLE;
        const isOnEditMode = !this.video.focus && this.currentResource && !this.videoControlService.isActive;

        if (
            !isOnEditMode &&
            (mode === MeetingMode.video || mode === MeetingMode.screen) &&
            (!this.video.focus || !this.video.enabled)
        ) {
            return true;
        }

        return mode !== MeetingMode.screen && !isOnEditMode && (isIdleMode || this.video.isClientVisible === false);
    }

    get isScanMode() {
        return this.video.mode === this.video.MODES.SCANNING || this.imageMode === this.IMAGE_MODES.SCANNING;
    }

    get isVideoScan() {
        return this.video.mode === this.video.MODES.SCANNING && !this.isImageScan;
    }

    get isImageScan() {
        return this.imageMode === this.IMAGE_MODES.SCANNING && !this.isVideoScan;
    }

    get isCanvas() {
        return !this.isScanMode && !(this.video.focus && this.mode === MeetingMode.video) && this.showImageWidget;
    }

    get isScreenSharing() {
        return (
            !this.isCanvas &&
            !this.isScanMode &&
            this.mode === MeetingMode.screen &&
            this.roomData.startWithAgentType === MeetingMode.screen &&
            !this.isDesktopSharingSession
        );
    }

    get isVideoApplication() {
        return (
            this.mode === MeetingMode.videoApplication &&
            this.roomData.startWithAgentType === MeetingMode.videoApplication
        );
    }

    get isDesktopSharing() {
        return !this.isCanvas && !this.isScanMode && this.isDesktopSharingSession;
    }

    get isDesktopSharingSession() {
        return this.mode === MeetingMode.screen && this.roomData.clientType === PlatformType.desktop_web;
    }

    get isDesktopSharingRemoteControlSession() {
        return (
            this.isDesktopSharingSession &&
            get(this.accountData, 'protectedSettings.desktopSharing.enableRemoteControl')
        );
    }

    get isApplicationSharing() {
        return (
            !this.isCanvas &&
            !this.isScanMode &&
            this.mode === MeetingMode.appSharing &&
            this.roomData.startWithAgentType === MeetingMode.appSharing
        );
    }

    get isScreenVideo() {
        return (
            this.isApplicationSharing &&
            this.video.focus &&
            this.video.mode === this.video.MODES.CURSOR_SHARING &&
            this.video.mode !== this.video.MODES.SCANNING
        );
    }

    get isTitleActionHidden() {
        const screenSharePause =
            (!this.allowScreenPause && includes([MeetingMode.appSharing], this.mode)) || this.isScreenSharing;
        // Video paused by customer - isPaused === true and not paused by dashboard
        // Also prevent hiding action button when video paused but we are in canvas
        const videoPausedByCustomer = this.isPaused && !this.videoPausedByDashboard && !this.isCanvas;
        // Canvas mode - isCanvas === true and alos we are in ONE_CLICK or PHOTO modes
        const isCanvas = this.isCanvas && (this.mode === MeetingMode.oneClick || this.mode === MeetingMode.images);

        // X button should be HIDDEN (action blue button) when:
        // Current mode is sharing, video, video paused by customer or canvas

        return this.isDesktopSharing || screenSharePause || videoPausedByCustomer || isCanvas;
    }

    get measureObject() {
        return get(this.accountData, 'protectedSettings.measurementTool.measureObject');
    }

    get isVideoApplicationPaused() {
        return this.isVideoApplication && this.isPaused && this.video.focus;
    }

    desktopSharingToggleControl() {
        const dashboardScope = this._getDashboardScope();

        this.chatApi.setStatus('agentRequestRemoteControl', !dashboardScope.agentHasRemoteControl);

        this.displayToastMessage(
            'DASHBOARD.DESKTOP_SHARING_REQUEST_SENT_TO_CUSTOMER',
            DS_CONTROL_REQUEST_SENT_TO_CUSTOMER_COLOR,
            DS_CONTROL_REQUEST_SENT_TO_CUSTOMER_TIMEOUT
        );

        if (dashboardScope.agentHasRemoteControl) {
            this.eventLog.desktopShareAgentStoppedRemoteControl();
        } else {
            this.eventLog.desktopShareAgentRequestRemoteControl();
        }
    }

    displayToastMessage(labelTextKey, toastColor, toastTimeout) {
        const dashboardScope = this._getDashboardScope();

        if (dashboardScope) {
            dashboardScope.setMessageAlert(this.translate(labelTextKey), toastColor, toastTimeout);
        }
    }

    startMeasure() {
        if (this.isScanMode) {
            this.cancelScanning();
        }

        this.setImageMode(IMAGE_MODES.MEASUREMENT);

        this.annotateService.setTool();
        this.annotateService.setZoom(1);
        this.annotateService.toggleZoom(false);
        this.annotateService.toggleRotation(false);
        this.canvasReadOnly = true;

        this.tsMeasure.start({
            canvas: this.annotateService.canvas,
            imageBlob: this.annotateService.getCleanDataURL(),
            accountId: this.accountData._id,
            userId: this.currentUser._id,
            roomId: this.roomData._id,
            measureObject: this.measureObject
        });
    }

    stopMeasure() {
        this.tsMeasure.stop();
        this.canvasReadOnly = false;
        this.annotateService.toggleZoom(true);
        this.annotateService.toggleRotation(true);
        this.setImageMode(null);
    }

    sendSmartSolutionSteps(steps) {
        const stepCount = steps.filter((step) => step.image).length;
        const setId = this.tsSharingService.uuidv4();

        steps.forEach((step, stepIndex) => {
            if (step.image && step.image.url) {
                this.chatApi.sendImage(step.image.url, {
                    setId: setId,
                    stepIndex: stepIndex + 1,
                    stepCount: stepCount,
                    tags: [],
                    sharedBy: senderTypes.DASHBOARD,
                    mediaFileName: this.urlUtils.extractFileName(step.image.url)
                });
            }
        });
    }

    showCustomAnalysisPanel() {
        this._getDashboardScope().recognizeLoader = false;
    }

    showDrawingCanvas() {
        return this.video.enableDashboardMagicMarker && this.video.focus && !this.isPaused;
    }

    displayMainContent() {
        return (!this.showWizard || this.offlineMode || this.showPhotoSelectionDialog()) && !this.isInInitiationPhase();
    }

    isToggleEnabled_StretchedVideoMode() {
        return get(this.accountData, 'protectedSettings.enableStretchedVideoMode');
    }

    isToggleEnabled_RoundedFloatingVideo() {
        return (
            this.isToggleEnabled_StretchedVideoMode() &&
            get(this.accountData, 'protectedSettings.enableRoundedMinimizedVideo')
        );
    }

    isToggleEnabled_NewLivePointerDesign() {
        return get(this.accountData, 'protectedSettings.enableNewLivePointerDesign');
    }

    isToggleEnabled_ScanCaptureDesign() {
        return (
            this.isToggleEnabled_StretchedVideoMode() &&
            get(this.accountData, 'protectedSettings.enableScanCaptureDesign')
        );
    }

    isToggleEnabled_SessionToolbar() {
        return get(this.accountData, 'protectedSettings.enableSessionToolbar');
    }

    isToggleEnabled_IdleModeUsability() {
        return get(this.accountData, 'protectedSettings.enableIdleModeUsability');
    }

    isToggleEnabled_newHeaderFooterLeftbar() {
        return get(this.accountData, 'protectedSettings.newHeaderFooterLeftbar');
    }

    isToggleEnabled_flashLight() {
        return get(this.accountData, 'protectedSettings.enableFlashlight');
    }

    isToggleEnabled_measurement() {
        return get(this.accountData, 'protectedSettings.measurementTool.enabled');
    }

    runSpeedTestOnClient() {
        this.chatApi.requestAction('runSpeedTest', true);
    }

    runWifiScanOnClient() {
        const options = {
            reliability: ReliabilityStrategy.ClientTimeout,
            secondsToTimeout: 5
        };

        this.wifiAnalyzerController.show();
        this.chatApi.requestAction('runWifiScan', true, options);
    }

    onCloseGuidanceSpeedtest() {
        this.displaySpeedtestGuidance = false;
    }

    displayFloatingToolbar() {
        if (this._getDashboardScope() && this._getDashboardScope().endedTheMeeting) {
            return this.displayMainContent();
        }

        if ([MeetingMode.oneClick, MeetingMode.images].some((mode) => this.mode === mode)) {
            return this.displayMainContent();
        }

        return (
            [MeetingMode.video, MeetingMode.screen, MeetingMode.appSharing, MeetingMode.videoApplication].some(
                (mode) => this.mode === mode
            ) && this.displayMainContent()
        );
    }

    displayFloatingToolbarSpeedtest() {
        return this.enableMobileSpeedtest && ![MeetingMode.screen, MeetingMode.appSharing].includes(this.mode);
    }

    displayFloatingToolbarFlashlight() {
        return (
            this.isToggleEnabled_flashLight() &&
            (this.isLiveVideo || this.isVideoScan) &&
            ![MeetingMode.screen, MeetingMode.appSharing].includes(this.mode)
        );
    }

    showAnnotationBar() {
        return !(this.isToggleEnabled_SessionToolbar() && this.displayFloatingToolbar());
    }

    sendFlashfightGuidance() {
        this.eventLog.sendFlashfightGuidance({turnOnGuidance: !this.isTorchActive});
        this.chatApi.requestAction('runTurnOnFlashlightGuide', true);
    }

    preVideoClick(e) {
        if (!this.video.focus) {
            this.video.focus = true;

            return;
        }

        if (this.isDesktopSharingRemoteControlSession) {
            return;
        }

        return this.videoClicked(e);
    }

    measureClick(e) {
        this.videoClickPosition = {
            x: e.clientX,
            y: e.clientY
        };

        this.clickCanvasTime = moment().valueOf();
    }

    videoClicked(e, forceTreatAsClick = false) {
        const {x, y} = this.videoClickPosition;
        const differenceFromMouseDown = moment().valueOf() - this.clickCanvasTime;
        const isMobileDashboard = this.environmentDetect.isMobile();

        let strokeLength = 0;

        if (this.isMagicMarkerEnabled && x && y && e && e.clientX && e.clientY) {
            strokeLength = Math.sqrt((x - e.clientX) * (x - e.clientX) + (y - e.clientY) * (y - e.clientY));
        }

        if (
            forceTreatAsClick ||
            !this.isMagicMarkerEnabled ||
            isMobileDashboard ||
            (strokeLength < MIN_ALLOWED_STROKE_LENGTH && differenceFromMouseDown < MIN_INTERVAL_TO_DRAW_MAGIC_MARKER)
        ) {
            const videoMode = this.video.mode;
            const snapshotAllowedModes = [VIDEO_MODES.SNAP, VIDEO_MODES.CURSOR_SHARING];

            this.showModeLabel = false;
            this.stopMeasure();

            if (includes(snapshotAllowedModes, videoMode)) {
                this._makeSnapshot(CLASSIFY_EVENT_SOURCES.VIDEO_CLICKED);
            }
        }
    }

    solutionClicked(solution) {
        this.smartSolutionController.showSmartSolution(solution);
    }

    hideSolutionModal() {
        this.smartSolutionController.hideSmartSolution();
    }

    analyzeClicked(customAnalysis = false, recognize = false) {
        if (this.video.focus) {
            this._makeSnapshot(CLASSIFY_EVENT_SOURCES.MANUAL_ANALYZE, customAnalysis, recognize);
        } else if (!this.currentResource.isVideo) {
            this.classifyDeviceData = null;

            const applyNewValue = () => {
                this.classifyDeviceData = {
                    imgData: this.currentResource.url,
                    eventSource: CLASSIFY_EVENT_SOURCES.MANUAL_ANALYZE,
                    customAnalysis: customAnalysis
                };

                this.$scope.$apply();
            };

            setTimeout(applyNewValue.bind(this));
        }
    }

    isMeetingModesReady(cameraApproval) {
        return this.displayMeetingModes() && cameraApproval;
    }

    _makeSnapshot(eventSource, customAnalysis, recognize) {
        this.streamAction(eventSource, recognize, false).then((snapshot) => {
            if (snapshot) {
                if (eventSource === CLASSIFY_EVENT_SOURCES.VIDEO_CLICKED) {
                    this.tsSnapshotReminderService.snapshotTaken();
                }

                this.classifyDeviceData = {
                    imgData: snapshot.imgData,
                    eventSource: eventSource,
                    customAnalysis: customAnalysis
                };
            }
        });
    }

    // Initializes account + group predefined resources
    initPredefined() {
        const accountId = this.accountData._id;
        const owners = this.currentUser.groups.toString();

        this.db.Resource.findAll(
            {
                accountId: accountId,
                owners: owners || undefined,
                predefined: true
            },
            {bypassCache: true}
        ).then((resources) => {
            this.predefined = resources || [];
            this.predefinedMessagesTree = {
                children: [
                    {
                        title: this.$translate.instant('MAIN.VIEW.PREDEFINED'),
                        topLevel: true,
                        type: 'dir',
                        children: this.predefined
                    }
                ]
            };
        });
    }

    _initHandlers() {
        const dashboardScope = this._getDashboardScope();
        const imageSavedListener = (message) => this.saveImage(message),
            toolDeselectListener = () => (this.activeTool = 'hand'),
            zoomChangeListener = (zoom) => (this.zoomFactor = zoom),
            busyListener = () => (this.modesEnabled = false),
            freeListener = () => this._checkModesAvailability(),
            clientConnectionListener = () => {
                this._checkModesAvailability();
            },
            clientPhotoSelectionDialogStateListener = (event, param) => {
                if (this.mode === MeetingMode.images && !this.clientInPhotoModeState && param === 'IN_PHOTO_CHAT') {
                    this.clientInPhotoModeState = this.CLIENT_IN_PHOTO_MODE_STATES.FIRST_TIME;
                } else if (
                    this.clientInPhotoModeState === this.CLIENT_IN_PHOTO_MODE_STATES.FIRST_TIME &&
                    get(this.currentResource, 'url')
                ) {
                    this.clientInPhotoModeState = this.CLIENT_IN_PHOTO_MODE_STATES.RECCURING;
                }
            },
            clientLogListener = (log) => {
                if (this.showWizard) {
                    if (log.name === 'CUSTOMER_OPENED_THE_CAMERA') {
                        this.goToWizardStep(1);
                    } else if (log.name === 'CUSTOMER_UPLOADING_AN_IMAGE') {
                        this.finishWizard();
                    }
                }
            },
            failedImageFromUrlListener = () => this.handleImageDownloadFail(),
            failedVideoFromUrlListener = () =>
                this.errorDialogModal.show('ERROR_DIALOG.VIEW.VIDEO_FAILED_TO_LOAD', this.dir),
            resourceUpdateListener = (message) => this._updateResources(message.data),
            messageReceivedListner = (message) => this.checkIfTextMessage(message),
            clientVisibleListener = (param, state) => {
                this.video.isClientVisible = state;

                if (state) {
                    freeListener();
                } else {
                    busyListener();
                }
            },
            coBrowsingInitErrorListener = (err) => {
                // TODO: Alert error or fallback to another mode
                console.error(err);
            },
            coBrowsingErrorListener = (err) => console.error(err),
            isTorchOnListener = (state) => {
                this.isTorchActive = state;

                if (this.floatingToolbarFlashlightHandlers) {
                    this.isTorchActive
                        ? this.floatingToolbarFlashlightHandlers.activate()
                        : this.floatingToolbarFlashlightHandlers.deactivate();
                }
            },
            isTorchAvailableListener = (isAvailable) => {
                this.isFlashlightAvailableOnClient = isAvailable;
            },
            speedtestResultsListener = (param, data) => {
                this.speedtestService.resultsHandler(data);
            },
            wifiScanResultsListener = (data) => {
                this.wifiAnalyzerController.show();
                this.wifiAnalyzerController.setResults(data);
            },
            postResourceListener = (data) => {
                const {resourceUrl} = data;

                this.setResource({url: resourceUrl});
                this.eventLog.postResourceToSession({url: resourceUrl});
                this._addToScreenShots({url: resourceUrl});
            };

        this.chatApi.on('clientAudioHandshakeSuccess', () => {
            this.audioService.checkClientAudioHandshake();
            this.$rootScope.safeApply();
        });
        this.chatApi.on('log', clientLogListener);
        this.chatApi.on('imageSave', imageSavedListener);
        this.chatApi.on('clientConnected', clientConnectionListener);
        this.chatApi.on('clientPhotoSelectionDialogState', clientPhotoSelectionDialogStateListener);
        this.chatApi.on('clientVisible', clientVisibleListener);
        this.chatApi.on('isTorchOnAction', isTorchOnListener);
        this.chatApi.on('isTorchAvailableAction', isTorchAvailableListener);
        this.chatApi.on('clientSpeedtestResults', speedtestResultsListener);
        this.chatApi.on('resourcesUpdated', resourceUpdateListener);
        this.chatApi.on('wifiScanResultProcessed', wifiScanResultsListener);
        this.chatApi.on('message', messageReceivedListner);
        this.annotateService.on('tool:deselect', toolDeselectListener);
        this.annotateService.on('zoom:change', zoomChangeListener);
        this.annotateService.on('canvas:failedtoloadurl', failedImageFromUrlListener);
        this.videoControlService.on('video:failedtoloadurl', failedVideoFromUrlListener);
        this.busy.on('busy', busyListener);
        this.busy.on('free', freeListener);
        this.tsCobrowsing.on(this.tsCobrowsing.EVENTS.INIT_ERROR, coBrowsingInitErrorListener);
        this.tsCobrowsing.on(this.tsCobrowsing.EVENTS.ERROR, coBrowsingErrorListener);
        dashboardScope.jsApiService.on('postResource', postResourceListener);

        this.$scope.$on('$destroy', () => {
            this.tsSnapshotReminderService.cleanup();
            this._autorunDisposer();

            this.chatApi.removeListener('log', clientLogListener);
            this.chatApi.removeListener('imageSave', imageSavedListener);
            this.chatApi.removeListener('clientConnected', clientConnectionListener);
            this.chatApi.removeListener('clientPhotoSelectionDialogState', clientPhotoSelectionDialogStateListener);
            this.chatApi.removeListener('clientVisible', clientVisibleListener);
            this.chatApi.removeListener('isTorchOn', isTorchOnListener);
            this.chatApi.removeListener('isTorchAvailable', isTorchAvailableListener);
            this.chatApi.removeListener('clientSpeedtestResults', speedtestResultsListener);
            this.chatApi.removeListener('wifiScanResultProcessed', wifiScanResultsListener);
            this.chatApi.removeListener('resourcesUpdated', resourceUpdateListener);
            this.chatApi.removeListener('message', messageReceivedListner);
            this.annotateService.removeListener('tool:deselect', toolDeselectListener);
            this.annotateService.removeListener('zoom:change', zoomChangeListener);
            this.annotateService.removeListener('canvas:failedtoloadurl', failedImageFromUrlListener);
            this.videoControlService.removeListener('video:failedtoloadurl', failedVideoFromUrlListener);
            this.busy.removeListener('busy', busyListener);
            this.busy.removeListener('free', freeListener);
            this.tsCobrowsing.removeListener(this.tsCobrowsing.EVENTS.INIT_ERROR, coBrowsingInitErrorListener);
            this.tsCobrowsing.removeListener(this.tsCobrowsing.EVENTS.ERROR, coBrowsingErrorListener);
            dashboardScope.jsApiService.removeListener('postResource', postResourceListener);
            this.browserUtilsService.saveToSessionStorage('runSpeedTestOnClient', false);
            this._resetSessionStorage();
            this.deregisterMeetingSyncRequest();
            this.deregisterPreviews();
        });

        this.$scope.$on('$viewContentLoaded', () => {
            this._getDashboardScope().mainFinishLoaded();

            if (!this.offlineMode || this.loadedFromHistory) {
                // make the code execute on the next cycle
                setTimeout(() => {
                    const meta = this.loadedFromHistory ? {loadedFromHistory: true} : undefined;

                    this.eventLog.dashboardMainViewLoaded(meta);
                }, 0);
            }
        });
    }

    checkIfTextMessage(msg) {
        if (msg.data.type === 'text') {
            this.receiveMessage(msg);
        }
    }

    sendImageViaPostMessage(image, fileName) {
        if (!this.isPostImageEnabledOnAccount) {
            return;
        }

        const dashboardScope = this._getDashboardScope();

        dashboardScope.jsApiService.Image({
            type: MessageType.image,
            techseeSessionId: this.roomData._id,
            fileName,
            base64encodedImage: image
        });
    }

    resourcesListener() {
        this.messageReceive();

        this._autorunDisposer = reaction(
            () => ({
                messages: this.messagesService.resources.length
            }),
            () => {
                this.messageReceive();
            }
        );
    }

    messageReceive() {
        const resourcesToHandle = filter(
            this.messagesService.resources,
            (msg) => !some(this.screenshots, (screenshot) => screenshot.url === msg.data.message)
        );

        forEach(resourcesToHandle, (msg) => this.receiveMessage(msg));
    }

    _pagePlacementPause(paused) {
        const dashboardScope = this._getDashboardScope();

        if (paused) {
            if (this.isDesktopSharingSession) {
                this.domUtilsService.setPagePlacement('dashboard-desktop-sharing-paused');
            } else if (this.videoPausedByDashboard) {
                this.domUtilsService.setPagePlacement('dashboard-paused');
            } else if (!this.videoPausedByDashboard) {
                this.domUtilsService.setPagePlacement('dashboard-mobile-paused');
            }
        } else if (dashboardScope && dashboardScope.connected === CONNECTION_STATUS.IDLE) {
            this.domUtilsService.setPagePlacement('dashboard-idle-client');
        } else {
            this.domUtilsService.setPagePlacement('dashboard-live-session');
        }
    }

    _resetSessionStorage() {
        this._setScreenshots([]);
    }

    // This is a hack that forces IE11 to redraw right sidebar to fix css issues when adding new images
    _ieRedrawRightSidebar() {
        if (!this.hideRightSidebar) {
            this.hideRightSidebar = true;
            this.$timeout(() => {
                this.hideRightSidebar = false;
            }, 10);
        }
    }

    _addToScreenShots(newImage) {
        this.screenshots.push(newImage);
        this._setScreenshots(this.screenshots);
    }

    _removeLastScreenShot() {
        this.screenshots.pop();
        this._setScreenshots(this.screenshots);
    }

    _updateResources(resources) {
        forEach(resources, (resource) => {
            const storageIndex = resource.storageIndex;
            const msg = find(this.meetingHistory, {storageIndex});

            const imgResource = !msg || msg.type === 'image' ? find(this.screenshots, {storageIndex}) : null;
            const videoResource =
                !imgResource && (!msg || msg.type === 'video') ? find(this.videos, {storageIndex}) : null;

            const updatedResource = imgResource || videoResource;

            if (msg) {
                msg.message = resource.url;
            }

            if (!updatedResource) {
                return;
            }

            if (updatedResource.video) {
                updatedResource.url = resource.extraUrl;
                updatedResource.video = resource.url;
            } else {
                updatedResource.url = resource.url;
            }
        });
    }

    displayLiveVideo() {
        return this.isVideoOrScreensharingMode(this.mode) && this.chatApi.areBothSidesConnected;
    }

    shouldDisplayVideoTools() {
        if ((this.showScanners && this.tsInterfaceHelper.useNewInterface) || this.isScreensharingMode(this.mode)) {
            return false;
        }

        return (
            ((get(this.video, 'focus') && this.video.enabled) || get(this.currentResource, 'isVideo')) && !this.observe
        );
    }

    handleImageDownloadFail() {
        const errModal = this.errorDialogModal.show('ERROR_DIALOG.VIEW.IMAGE_FAILED_TO_LOAD', this.dir, true);

        const failedResource = this.currentResource;

        this.resetResource();

        errModal.result.then(({retryDownload}) => {
            if (retryDownload) {
                this.eventLog.retryingImageDownload({
                    url: failedResource.url
                });

                this.setResource(failedResource);
            }
        });
    }

    isInInitiationPhase() {
        return (
            this.chatApi.isReviewingTOS ||
            get(this.chatApi, 'client.isOnPreCameraApprovalScreen') ||
            (get(this.chatApi, 'client.inCameraApprovalDialog') &&
                !this._getDashboardScope().cameraApprovalIndicationNotRequired)
        );
    }

    _checkModesAvailability() {
        const clientMode = this.chatApi.client.mode;
        const isClientPreparing =
            this.chatApi.client.preparing &&
            !includes([MeetingMode.images, MeetingMode.oneClick, MeetingMode.coBrowsing], clientMode);

        this.modesEnabled =
            !this.observe &&
            this.chatApi.client.connected &&
            !this.isPaused &&
            this.chatApi.client.visible &&
            (!isClientPreparing || (this.allowSwitchModeDuringInitiation && this.isInInitiationPhase()));

        this.videoSupport = this.chatApi.client.videoSupport !== false && this.chatApi.dashboard.videoSupport;

        this.photoSupport = this.chatApi.client.photoSupport;

        // If neither photo/oneclick allowed, no reason to show low bw dialog
        if (!this.photoSupport && !(this.allowOneClickPhotoMode && this.videoSupport)) {
            this.chatApi.dashboard.showLowBandwidthMessage = false;
        }
    }

    _getLocation(href) {
        const match = href.match(/^(https?:)\/\/(([^:\/?#]*)(?::([0-9]+))?)(\/[^?#]*)(\?[^#]*|)(#.*|)$/);

        return (
            match && {
                protocol: match[1],
                host: match[2],
                hostname: match[3],
                port: match[4],
                pathname: match[5],
                search: match[6],
                hash: match[7]
            }
        );
    }

    /**
     * Takes a flat list of urls that is provided by the backend, and parses it
     * into a tree, that can be displayed in the tree directive and also that
     * facilitates browsing the library
     *
     * @param rootName - String, name of root node
     * @param resources - list of Resources
     */
    _listToTree(rootName, resources) {
        // Each node has an array of children, which also contains nodes
        // and an array of parents which contains string names of parents,
        // used for lookups, from the root of the tree.
        const lib = {
            title: rootName,
            parents: [],
            children: [],
            type: 'dir'
        };

        const libraryResources = filter(
            resources,
            (resource) =>
                (resource.type === this.resourceTypes.image || resource.type === this.resourceTypes.pdf) &&
                resource.storageType !== STORAGE_TYPES.ext
        );

        forEach(libraryResources, (resource) => {
            const url = this._getLocation(resource.url),
                type = resource.type === this.resourceTypes.image ? 'img' : 'file',
                path = this._splitPath(
                    url.pathname,
                    utils.isCloudfrontUrlBySignedUrl(resource.url) ? LIBRARY_DIR_DEPTH - 1 : LIBRARY_DIR_DEPTH
                );

            // Handles files in the top-level directory
            if (url.pathname.lastIndexOf('/') <= 0) {
                lib.children.push({
                    title: resource.title,
                    parents: [],
                    url: resource.url,
                    type: type,
                    resource: resource
                });
            } else {
                let lastDir = lib;

                forEach(path, (currentPath, key) => {
                    let index = findIndex(lastDir.children, {title: currentPath});

                    if (index < 0) {
                        lastDir.children.push({
                            title: currentPath,
                            parents: path.slice(0, key),
                            type: 'dir',
                            children: []
                        });
                        index = lastDir.children.length - 1;
                    }
                    lastDir = lastDir.children[index];
                });

                lastDir.children.push({
                    title: resource.title,
                    parents: path,
                    url: resource.url,
                    type: type,
                    resource: resource
                });
            }
        });

        return lib;
    }

    /*
     * Takes the pathname part of a parsed url and converts it
     * to an array of directory names, suitable for the library
     *
     * @param pathname - String, path of the url (e.g. '/techsee.me/api/images/blob1.png')
     *
     * @return String[] - the individual components of the path, in an Array
     */
    _splitPath(pathname, depth) {
        return (
            pathname
                // remove leading slash and filename from path
                .slice(1, pathname.lastIndexOf('/'))
                // separate path to components
                .split('/')
                // remove topLevel directory
                .slice(depth)
                // fix encoded characters the url may contain
                .map((component) => {
                    // decodeURIComponent can throw a URIError
                    try {
                        return decodeURIComponent(component);
                    } catch (e) {
                        return component;
                    }
                })
        );
    }

    /*
     * Same as _listToTree , only creates a single directory (tree node)
     * with all the files under it
     *
     * @param rootName - String, name of root node
     * @param resources - list of Resources
     */
    _createNodeList(rootName, resources) {
        const root = {
            title: rootName,
            parents: [],
            children: [],
            type: 'dir'
        };

        const libraryResources = filter(
            resources,
            (resource) =>
                (resource.type === this.resourceTypes.image || resource.type === this.resourceTypes.pdf) &&
                resource.storageType !== STORAGE_TYPES.ext
        );

        forEach(libraryResources, (resource) => {
            const type = resource.type === this.resourceTypes.image ? 'img' : 'file';

            root.children.push({
                title: resource.title,
                parents: [rootName],
                url: resource.url,
                type: type,
                resource: resource
            });
        });

        return root;
    }

    /**
     * Makes an array with the nodes that need to be open
     * when the tree widget is first rendered.
     *
     * @param tree - tree data structure
     *
     * @return Array - an array with the nodes to be expanded
     */
    _autoExpandEmptyTopLevel(tree) {
        const expanded = [];
        let currentNode = tree;

        // depending on the url, we may have some toplevel dirs that have no files
        // and only contain the next dir, so we want them to be initially open
        while (currentNode.children.length === 1 && currentNode.children[0].type === 'dir') {
            expanded.push(currentNode);
            currentNode = currentNode.children[0];
        }

        // After we open the directories of the path that hold only one subdir each,
        // we also open the first one that has files in it, for quicker access by the user
        if (currentNode.children.length > 1) {
            expanded.push(currentNode);
        }

        return expanded;
    }

    /**
     * Creates an array of the parent node objects of a single node,
     * to allow expanding them and make the child node visible
     *
     * @param tree - tree data structure
     * @param parents - string array that is used to find the parents
     *
     * @return Array - an array with the nodes to be expanded
     */
    _expandParents(tree, parents) {
        const expanded = [];
        let currentNode = tree;

        forEach(parents, (title) => {
            const nextNode = find(currentNode.children, {title: title});

            if (nextNode) {
                expanded.push(nextNode);
                currentNode = nextNode;
            }
        });

        return expanded;
    }

    async receiveMessage(message) {
        if (!this.chatApi.synced) {
            this.syncMeeting();
        }

        if (get(message, 'data.extra.eveAnalysis') && this._getDashboardScope().enableSelfServiceInLeftBar) {
            return;
        }

        const isNewClientMessage = message.sender === UserType.client && message.isNew,
            isNewDashboardMessage = message.sender === UserType.dashboard && message.isNew;

        if (isNewClientMessage) {
            this.eventLog.receivedMessage(assign({side: PlatformType.dashboard}, message.data));
        }

        if (message.data.type === 'image') {
            const newImg = {
                url: message.data.message,
                tags: message.data.meta && message.data.meta.tags,
                storageIndex: message.storageIndex,
                extra: message.data.extra,
                sharedBy: message.data.meta && message.data.meta.sharedBy
            };

            const imageAsBase64 = await this.tsSharingService.imageAsBase64(newImg.url);
            const imageObj = this._snapshotToImg(imageAsBase64);

            if (this._getDashboardScope()._copilotService) {
                this._getDashboardScope()._copilotService.imageCaptured(newImg.url, imageAsBase64);
            }

            if (isNewClientMessage) {
                this.sendImageViaPostMessage(imageAsBase64, this._getResourceName(imageObj.img.toString()));
            }

            if (this.autoDataCollection.enabled && isNewClientMessage) {
                await this.tsSharingService.uploadAutoCollectedImage(imageObj.img);
            }

            if (!message.data.private) {
                newImg.index = this.messagesService.imageCounter;
                newImg.resourceIndex = this.messagesService.videoCounter + this.messagesService.imageCounter;
            }

            if (!this.isToggleEnabled_SessionToolbar()) {
                this.selectTool('arrow', true);
            }

            if (isNewDashboardMessage || isNewClientMessage) {
                this._addToScreenShots(newImg);
            } else if (
                (this.offlineRoom || this.observe) &&
                !find(this.screenshots, (img) => newImg.storageIndex === img.storageIndex)
            ) {
                this._addToScreenShots(newImg);
            }

            this.imageSectionOpen = true;
            this.returnToOneClickGuidance = false;
            this.scrollToImagesTop();

            if (
                isNewDashboardMessage ||
                (!this.currentResource.url && isNewClientMessage) ||
                (this.offlineRoom && this.clientDisconnected)
            ) {
                this.setResource(newImg, !isNewClientMessage);
                this.selectTool('arrow', true);
            }

            if (this.isIE11) {
                this._ieRedrawRightSidebar();
            }

            if (isNewClientMessage) {
                this.classifyDeviceData = {
                    imgData: newImg.url,
                    eventSource: CLASSIFY_EVENT_SOURCES.IMAGE_UPLOADED_BY_CUSTOMER
                };

                this.chatApi.imageReceived(message);
            }
        } else if (message.data.type === 'video') {
            const newVideo = {
                isVideo: true,
                url: message.data.extra,
                tags: message.data.meta && message.data.meta.tags,
                video: message.data.message,
                storageIndex: message.storageIndex,
                sharedBy: message.data.meta && message.data.meta.sharedBy
            };

            if (!message.data.private) {
                newVideo.index = this.messagesService.videoCounter;
                newVideo.resourceIndex = this.messagesService.videoCounter + this.messagesService.imageCounter;
            }

            this.selectTool('arrow', true);
            this.videos.push(newVideo);
            this.videoSectionOpen = true;
            this.scrollToVideosTop();

            if (isNewDashboardMessage || (!this.currentResource.url && isNewClientMessage)) {
                this.setResource(newVideo, !isNewClientMessage);
            }

            if (this.isIE11) {
                this._ieRedrawRightSidebar();
            }

            if (isNewClientMessage) {
                this.chatApi.videoReceived(message);
            }
        } else if (message.data.type === 'text' && message.sender === UserType.client && message.isNew) {
            this.msgSectionOpen = true;
            this.openRightBar();
        }

        // we store the index on the image, since the dashboard displays
        // filtered views of images-only, text-only but it needs to know
        // the real number in order to communicate effectively with the
        // customer. We also store anything else the view is going to need
        message.data.index = filter(this.meetingHistory, (m) => m.type === message.data.type).length;
        message.data.sender = message.sender;
        message.data.isNew = message.isNew;
        message.data.storageIndex = message.storageIndex;

        this.meetingHistory.push(message.data);

        if (this.showWizard) {
            this.finishWizard();
        }
    }

    displayImageEditTools() {
        return (
            !(this.isToggleEnabled_SessionToolbar() && this.displayFloatingToolbar()) &&
            get(this.currentResource, 'url') &&
            (!this.video.focus || !this.video.enabled) &&
            !get(this.currentResource, 'isVideo') &&
            !this.observe &&
            this.annotateService.canvasReady
        );
    }

    saveImage(message) {
        const newImg = {
            url: message.data.message,
            tags: message.data.meta && message.data.meta.tags,
            storageIndex: message.storageIndex
        };

        this._addToScreenShots(newImg);

        if (!(this.currentResource && this.currentResource.isVideo)) {
            this.setResource(newImg, true);
            this.scrollToImagesTop();
        }

        this.imageSectionOpen = true;

        if (this.isIE11) {
            this._ieRedrawRightSidebar();
        }
    }

    setVideoMode(mode) {
        this.showModeLabel = true;

        this.$timeout(() => (this.showModeLabel = false), 2000);

        this.video.mode = mode;

        if (mode !== VIDEO_MODES.SCANNING) {
            this.tsScsan.removeAllListeners('areaSelected');
            this.tsScanArea.removeAllListeners('areaSelected');
        }

        if (mode === this.video.MODES.CURSOR_SHARING) {
            this.eventLog.mouseShareActivated();
        }
    }

    selectedScanMode() {
        this.showModeLabel = false;
        this.scan(this.textScanner);
    }

    setImageMode(mode) {
        this.imageMode = mode;

        const {ANALYZING, SCANNING} = this.IMAGE_MODES;

        if (mode !== SCANNING && this.isCanvas) {
            this.tsScsan.removeAllListeners('areaSelected');
            this.tsScanArea.removeAllListeners('areaSelected');
        }

        if (mode === ANALYZING || mode === SCANNING) {
            this.showModeLabel = true;

            if (!this.isToggleEnabled_ScanCaptureDesign()) {
                this.annotateService.setTool('select');
            } else {
                this.annotateService.clearObjects();
                this.annotateService.setTool(null);
            }
        } else if (!mode) {
            this.annotateService.setTool(this.activeTool);
        }
    }

    uploadLocalImage(img) {
        this.busy.busy();

        // immediately setting the image (using the temporary blob) makes the app
        // seem more responsive by using the temporary blob until it's uploaded
        // to S3. If the upload fails, the previous resource will be restored
        const oldResource = this.currentResource;

        this.canvasReadOnly = true;
        this.setResource({url: img});
        this._addToScreenShots({url: img});

        this.eventLog.uploadingLocalImage();

        this.tsSharingService.uploadRoomImage(
            img,
            {isTemp: true},
            (url) => {
                // replace the blob with the persistent image
                this.setResource({url});
                this._removeLastScreenShot();
                this._addToScreenShots({url});
                this.canvasReadOnly = false;

                this.classifyDeviceData = {
                    imgData: url,
                    eventSource: CLASSIFY_EVENT_SOURCES.IMAGE_UPLOADED_BY_AGENT
                };

                if (this.isIE11) {
                    this._ieRedrawRightSidebar();
                }
                this.busy.free();
            },
            (err) => {
                if (oldResource) {
                    this.setResource(oldResource);
                }
                this._removeLastScreenShot();
                this.canvasReadOnly = false;

                const maxSizeError = get(err, 'data.details.fileSizeExceeded');

                maxSizeError
                    ? this.errorDialogModal.show('ERROR_DIALOG.VIEW.IMAGE_TOO_LARGE', this.dir)
                    : this._handleImageUploadFail();

                this.busy.free();
            }
        );
    }

    failedLocalLoad(err) {
        if (err && err.fileTypeError) {
            this.errorDialogModal.show('ERROR_DIALOG.VIEW.SELECT_CORRECT_FILETYPE', this.dir);
        }
    }

    setResource(resource, keepFocus) {
        this.prevResource = cloneDeep(this.currentResource);
        this.currentResource = resource;
        this.videoControlService.isActive = resource.isVideo;
        this.returnToOneClickGuidance = false;

        if (this.tsMeasure.isActive) {
            this.stopMeasure();
        }

        if (!keepFocus) {
            this.video.mode = VIDEO_MODES.CURSOR_SHARING;
            this.imageMode = null;
        }

        if (this.showWizard) {
            this.finishWizard();
        }

        if (!keepFocus) {
            this.video.focus = false;
        }

        setTimeout(() => this.selectTool('arrow', true), 0);
    }

    resetResource() {
        this.currentResource = this.prevResource;
    }

    selectClickedResource(resource, room, updated) {
        const resourceToUpdate = {...resource};

        this.hideWidgets = false;

        if (this.imageMode === IMAGE_MODES.SCANNING) {
            this.showModeLabel = false;
        }

        if (get(resourceToUpdate, 'security.enabled') && !updated) {
            const isVideo = resourceToUpdate.type === this.resourceTypes.video;

            const promise = isVideo
                ? this.db.HistoryVideo.resign(resourceToUpdate._id, {bypassCache: true})
                : this.db.HistoryImage.resign(resourceToUpdate._id, {bypassCache: true});

            return promise.then((resp) => {
                resourceToUpdate.security = resp.data.security;

                if (isVideo) {
                    resourceToUpdate.video = resp.data.url;
                } else {
                    resourceToUpdate.url = resp.data.url;
                }

                this.selectClickedResource(resourceToUpdate, room, true);
            });
        }

        this.selectedLibraryNode = {};
        this.setResource(resourceToUpdate);

        this.selectTool('arrow', true);

        if (this.offlineMode) {
            this._setPreloadedResource(resourceToUpdate, room);
        }
    }

    isActiveResource(resource) {
        return resource && this.currentResource && this.currentResource.url === resource.url;
    }

    _streamActionOnce(eventSource, recognize, shouldScan) {
        return new Promise((resolve) => {
            if (!this.video.enabled) {
                return resolve();
            }

            if (!this.video.focus) {
                this.video.focus = true;

                return resolve();
            }

            if (this.isPaused) {
                return resolve();
            }

            if (this.observe) {
                return resolve();
            }

            // TODO This is a hack since using a jpeg on IE11+Opetok caused an error. This should be fixed and then
            // remove this override
            const options = {format: 'image/jpeg'};

            if (this.isDesktopSharingSession) {
                options.quality = 1;
            }
            trace.info('Getting snapshot for USER_VIDEO_STREAM');
            this.dashboardMediaService
                .getSnapshotFromKnownStream(KnownMediaStream.USER_VIDEO_STREAM, options)
                .then((snapshotResult) => {
                    trace.info('Snapshot ready. Length:', get(snapshotResult, 'base64img.length'));
                    this.imageSectionOpen = true;

                    if (this._getDashboardScope()._copilotService) {
                        this._getDashboardScope()._copilotService.imageCaptured(
                            snapshotResult.objectUrl,
                            snapshotResult.base64img
                        );
                    }

                    const handleSnapshotPromise = this._handleSnapshot(snapshotResult.base64img).then(
                        (snapshotData) => {
                            this.chatApi.imageSnapped();

                            if (this.snappedImageAppearOnCanvas && !snapshotData.uploadRoomImageFailed) {
                                URL.revokeObjectURL(snapshotData.objectUrl);
                                snapshotResult.objectUrl = snapshotData.imgData;
                                trace.info('Snapshot handled. length:', get(snapshotResult, 'imgData.length'));

                                if (!recognize) {
                                    this.selectClickedResource({url: snapshotResult.objectUrl});
                                }
                            }

                            if (this.embeddedDashboard && shouldScan) {
                                this.snapAndScan(snapshotResult.objectUrl);
                            }

                            if (eventSource === this.CLASSIFY_EVENT_SOURCES.VIDEO_CLICKED && !shouldScan) {
                                this.handleTriggeredAgentImageSnapped(snapshotResult.base64img);
                            }

                            this.sendImageViaPostMessage(
                                snapshotResult.base64img,
                                this._getResourceName(snapshotResult.objectUrl.toString())
                            );

                            resolve(snapshotData);
                        }
                    );

                    return handleSnapshotPromise.finally(() => {
                        this.scrollToImagesTop();
                        this.selectTool('arrow', true);
                        if (this.isIE11) {
                            this._ieRedrawRightSidebar();
                        }
                    });
                })
                .catch((err) => {
                    trace.warn('Error getting snapshot for USER_VIDEO_STREAM', err);
                    this.eventLog.agentScreenshotFailed(err);
                    this.errorDialogModal.show('ERROR_DIALOG.VIEW.SCREENSHOT_FAILED');

                    return resolve({err: 'Error while getting snapshot'});
                });
        });
    }

    triggerLocationPopover() {
        this.showLocationPopover = !this.showLocationPopover;
    }

    restoreFromFocusLoss() {
        this.video.mode = VIDEO_MODES.CURSOR_SHARING;

        this.imageMode = null;
    }

    _takeVideoControlScreenshotOnce(cb) {
        if (this.observe || !this.videoControlService.isActive) {
            return;
        }

        this.imageSectionOpen = true;

        this.videoUtils
            .createSnapshot(this.videoControlService.videoElement)
            .then((img) => {
                if (!img) {
                    this.errorDialogModal.show('ERROR_DIALOG.VIEW.VIDEO_FAILED_TO_SNAPSHOT', this.dir);

                    return;
                }

                this.chatApi.imageSnapped();

                if (cb) {
                    cb(img);
                }

                let handleSnapshotPromise = null;

                if (this.snappedImageAppearOnCanvas) {
                    const snappedImg = this._snapshotToImg(img).img;

                    handleSnapshotPromise = this._handleSnapshot(img).then((snapshotData) => {
                        if (!snapshotData.uploadRoomImageFailed) {
                            this.selectClickedResource({url: snappedImg});
                        }
                    });
                } else {
                    handleSnapshotPromise = this._handleSnapshot(img);
                }

                return handleSnapshotPromise.finally(() => {
                    this.selectTool('arrow', true);

                    if (this.isIE11) {
                        this._ieRedrawRightSidebar();
                    }
                });
            })
            .catch((err) => {
                this.eventLog.agentScreenshotFailed(err);
                this._handleImageUploadFail();

                throw {err: 'Error while getting snapshot'};
            });
    }

    _handleImageUploadFail() {
        this._getDashboardScope().handleImageUploadFail();
    }

    _handleSnapshot(snapshot) {
        return new Promise((resolve, reject) => {
            try {
                const snappedImg = this._snapshotToImg(snapshot).img;

                if (!this.saveSnapshots) {
                    this._addToScreenShots({url: snappedImg, dataUrl: snapshot});

                    return resolve({imgData: snapshot, imgBlob: snappedImg});
                }

                this.busy.busy();
                this.eventLog.savingSnapshot();

                return this.tsSharingService.uploadRoomImage(
                    snappedImg,
                    {isTemp: false},
                    (url) => {
                        this.busy.free();
                        this.chatApi.saveImage(url);
                        resolve({imgData: url});
                    },
                    () => {
                        this.busy.free();
                        this._handleImageUploadFail();

                        resolve({imgData: snapshot, uploadRoomImageFailed: true});
                    }
                );
            } catch (e) {
                reject(e);
            }
        });
    }

    _snapshotToImg(dataUrl) {
        const URL = this.$window.URL,
            blob = this.annotateService.dataUrlToBlob(dataUrl);

        return {
            size: blob.size,
            img: URL.createObjectURL(blob)
        };
    }

    isAndroidOrIphone() {
        const deviceInfo = this.chatApi.client.deviceInfo;

        if (deviceInfo && deviceInfo.match(/iPhone|iPad/)) {
            return IOS_DEVICES.APPLE;
        }

        const isNative = get(this.chatApi, 'clientType');

        return isNative ? IOS_DEVICES.APPLE : IOS_DEVICES.ANDROID;
    }

    isAndroid() {
        return this.isAndroidOrIphone() === IOS_DEVICES.ANDROID;
    }

    displayPhotoStreamGuidnace() {
        return (
            get(this.chatApi, 'client.photoSelectionDialogState') === this.PHOTO_SELECTION_STATE.IN_PHOTO_CHAT &&
            (this.displayTermsInPhotoStream ||
                !this.wasInVideoMode ||
                this.clientInPhotoModeState === this.CLIENT_IN_PHOTO_MODE_STATES.FIRST_TIME)
        );
    }

    displayOneClickGuidnace() {
        return (
            this.allowOneClickPhotoMode &&
            this.mode === MeetingMode.oneClick &&
            isEmpty(this.screenshots) &&
            !get(this.currentResource, 'url') &&
            this.chatApi.areBothSidesConnected
        );
    }

    displayCobrowsingIframe() {
        // if (this.isCoBrowsingModeSurfly) {
        return get(this.chatApi.dashboard, 'coBrowsingSettings.followerLink') && !this.clientDisconnected;
        // }
        //
        // return !this.clientDisconnected;
    }

    inCobrowsingMode() {
        return this.mode === MeetingMode.coBrowsing;
    }

    selectedImageMode(meetingMode) {
        const imgName = meetingMode.toLowerCase();

        if (!this.tsInterfaceHelper.useNewInterface) {
            return `icon-customer-mode-${imgName}.png`;
        }

        return this.mode === meetingMode ? `icon-customer-mode-${imgName}.png` : `unselected-icon-mode-${imgName}.png`;
    }

    showModeButton(meetingMode) {
        if (!this.chatApi.connected || !this.chatApi.synced || !this.chatApi.client) {
            return false;
        }

        if (meetingMode === MeetingMode.images) {
            return (
                !this.isModeAvailable(MeetingMode.oneClick) &&
                !this.isModeAvailable(MeetingMode.screen) &&
                !this.isModeAvailable(MeetingMode.appSharing) &&
                !this.isModeAvailable(MeetingMode.videoApplication)
            );
        }

        return this.isModeAvailable(meetingMode);
    }

    isModeAvailable(meetingMode) {
        const availableModes = get(this.chatApi, 'client.availableModes');
        const clientMode = get(this.chatApi, 'client.mode');
        const sharingModes = [MeetingMode.screen, MeetingMode.appSharing, MeetingMode.videoApplication];

        const isSharingMode = includes(sharingModes, meetingMode);
        const isCurrentModeSharing = includes(sharingModes, clientMode);

        return availableModes && availableModes.indexOf(meetingMode) > -1 && isSharingMode === isCurrentModeSharing;
    }

    requestModeDuringInitiation(mode) {
        this.chatApi.setStatus('allowAgentToSwitchModes', false, true);

        const modeToSwitchTo = mode !== MeetingMode.oneClick || this.isModeAvailable(mode) ? mode : MeetingMode.images;

        this.requestMode(modeToSwitchTo);
    }

    getImageSharingType() {
        const availableModes = get(this.chatApi, 'client.availableModes');
        const nextMode = includes(availableModes, MeetingMode.oneClick) ? MeetingMode.oneClick : MeetingMode.images;

        return this.requestMode(nextMode);
    }

    requestMode(mode) {
        this._checkModesAvailability();

        if (!this.modesEnabled || this.mode === mode) {
            return;
        }

        if (this.imageMode === IMAGE_MODES.SCANNING) {
            this.showModeLabel = false;

            this.setImageMode(null);
        }

        switch (mode) {
            case MeetingMode.images:
                this.eventLog.agentSwitchedToImageUpload();
                break;
            case MeetingMode.video:
                this.eventLog.agentSwitchedToVideoStream();
                break;
            case MeetingMode.oneClick:
                this.eventLog.agentSwitchedToPhoto();
                break;
            case MeetingMode.screen:
            case MeetingMode.appSharing:
            case MeetingMode.videoApplication:
                this.eventLog.agentSwitchedToScreenShare();
                break;
            case MeetingMode.coBrowsing:
                this.eventLog.agentSwitchedToCoBrowsing();
                break;
            default:
        }

        this.busy.busy();

        if (this._clientVisibleListener) {
            this.chatApi.removeListener('clientVisible', this._clientVisibleListener);

            this._clientVisibleListener = null;
        }

        if (this.chatApi.client.visible) {
            this.chatApi.requestSwitchMode(UserInteractionTriggerAction.buttonClickDuringSession, mode);
        } else {
            this._clientVisibleListener = () => {
                this.chatApi.requestSwitchMode(UserInteractionTriggerAction.buttonClickDuringSession, mode);
            };

            this.chatApi.once('clientVisible', this._clientVisibleListener);
        }
    }

    displayMeetingModes() {
        return (
            get(this.chatApi, 'dashboard.allowAgentToSwitchModes') &&
            !this.observe &&
            !this.offlineMode &&
            !this.showWizard &&
            !this.chatApi.isReviewingTOS
        );
    }

    setupColors() {
        this.colors = ANNOTATION_COLORS;
    }

    selectTool(tool, ignoreUnselect) {
        if (this.activeTool === tool && !ignoreUnselect) {
            // deselect
            this.activeTool = 'hand';
        } else {
            this.activeTool = tool;
        }
        this.annotateService.setTool(this.activeTool);
    }

    isActiveTool(tool) {
        return this.activeTool === tool;
    }

    setColor(color) {
        this.annotateService.setColor(color);
    }

    hasAnnotations() {
        return this.annotateService.isAnnotated();
    }

    undo() {
        this.annotateService.undo();
    }

    cancel() {
        this.annotateService.clearObjects();
    }

    _scannerTypeSelected(translateName, option) {
        return find(SCANNERS, (type) => type.name === translateName)[option];
    }

    displayNewScanMode() {
        return (
            this.showScanners &&
            get(this.tsInterfaceHelper, 'useNewInterface') &&
            get(this.chatApi, 'client.mode') === MeetingMode.video
        );
    }

    displayButtonsInVideo() {
        return (
            !this.isPaused &&
            !this.videoPausedByDashboard &&
            !this.observe &&
            this.video.focus &&
            this.video.enabled &&
            (this.video.mode === this.video.MODES.SNAP || this.video.mode === this.video.MODES.CURSOR_SHARING)
        );
    }

    _handleScannerFields(scanner, result) {
        const typeResult = this._scannerTypeSelected(scanner.type, 'reportName');
        const type = this._scannerTypeSelected(scanner.type, 'type');

        if (!result || isEmpty(result[type])) {
            this.db.Rooms.setReportedField(this.roomData._id, {
                data: {
                    event: {
                        key: `${typeResult}.failed`,
                        value: 1,
                        type: 'inc'
                    }
                }
            });
        } else if (!isEmpty(result[type])) {
            this.db.Rooms.setReportedField(this.roomData._id, {
                data: {
                    event: {
                        key: `${typeResult}.success`,
                        value: 1,
                        type: 'inc'
                    }
                }
            });
        }
    }

    setToolbarMeasureStates(activate, deactivate) {
        this.floatingToolbarMeasureHandlers = {activate, deactivate};
    }

    setToolbarScanStates(activate, deactivate) {
        this.floatingToolbarScanHandlers = {activate, deactivate};
    }

    setFlashlightStates(activate, deactivate) {
        this.floatingToolbarFlashlightHandlers = {activate, deactivate};
    }

    _setImageAsScanned(storageIndex) {
        map(this.screenshots, (screenshot) => {
            if (screenshot.storageIndex === storageIndex) {
                screenshot.scanned = true;
            }
        });

        this._setScreenshots(this.screenshots);
    }

    _getResourceName(resourceUrl) {
        const splitImageUrl = resourceUrl.split('?');
        const getFileName = splitImageUrl[0] && splitImageUrl[0].split('/');

        return getFileName[getFileName.length - 1];
    }

    snapAndScan(url) {
        const imageUrl = url || this.currentResource.url;
        let text = '';

        if (this.video.focus && !url) {
            return this.streamAction(this.CLASSIFY_EVENT_SOURCES.SCAN, false, true);
        }

        return this.tsSharingService
            .imageAsBase64(imageUrl)
            .then((base64Img) =>
                this.tsSharingService.imageAsBase64(imageUrl, {width: 48, height: 48}).then((thumbnail) =>
                    this.textScanner
                        .scan(base64Img, SCANNER_SRC_TYPES.EDITOR, this.embeddedDashboard, imageUrl, thumbnail)
                        .then((result) => {
                            if (result.text === null) {
                                return Promise.reject();
                            }
                            text = result.text;
                        })
                        .finally(() => {
                            const imageName = this._getResourceName(this.currentResource.url.toString());

                            const msg = {
                                techseeSessionId: this.roomData._id,
                                text: text.raw,
                                fileName: imageName
                            };
                            const dashboardScope = this._getDashboardScope();

                            dashboardScope.jsApiService.OCRSent(msg);
                            this._setImageAsScanned(this.currentResource.storageIndex);
                        })
                )
            )
            .catch(() =>
                this.miniDashboard
                    ? (this._getDashboardScope().lastEvent = {text: this.miniDashboardScanError})
                    : this.$modal.open({
                          animation: true,
                          template: scanResultView,
                          controller: ScanResultController,
                          controllerAs: 'vm',
                          windowClass: `dashboard-scan-results-modal ${
                              this.embeddedDashboard ? 'embeddedDashboard' : ''
                          }`,
                          resolve: {
                              type: () => 'text',
                              result: () => null,
                              dir: () => this.dir,
                              searchTags: null
                          }
                      })
            );
    }

    scan(scanner = this.textScanner) {
        const isVideo = this.video.focus && this.video.enabled && this.videoSubscriber,
            takenFrom = isVideo ? SCANNER_SRC_TYPES.VIDEO : SCANNER_SRC_TYPES.EDITOR;

        if (this.floatingToolbarScanHandlers) {
            this.isScanMode
                ? this.floatingToolbarScanHandlers.deactivate()
                : this.floatingToolbarScanHandlers.activate();
        }

        const scanNow = (options) => {
            let nextOptions = options;

            Promise.resolve()
                .then(() => {
                    if (isVideo) {
                        let width = this.videoSubscriber.renderWidth;
                        let height = this.videoSubscriber.renderHeight;
                        const {videoWidth, videoHeight} = this.videoSubscriber;

                        // Correct the dimensions orientation
                        if (width > height && videoWidth < videoHeight) {
                            [width, height] = [height, width];
                        }

                        // Check if there is a resolution decrease or if IE
                        //TODO - Alex: I really don't understand what should happen here....and how it is decided
                        //almost always render width and video will be different, so we ask for image from mobile?
                        const isRegularSnapShot = !this.isIE11; // && videoWidth === width && videoHeight === height;

                        if (
                            this.isDesktopSharingSession ||
                            this.roomData.clientType !== 'MOBILE_WEB' ||
                            isRegularSnapShot
                        ) {
                            return this.dashboardMediaService.getSnapshotFromKnownStream(
                                KnownMediaStream.USER_VIDEO_STREAM,
                                options
                            );
                        }

                        if (nextOptions) {
                            const widthRatio = width / videoWidth;
                            const heightRatio = height / videoHeight;
                            const {format, quality} = nextOptions;
                            let {x, y, w, h} = nextOptions;

                            x = x && x * widthRatio;
                            y = y && y * heightRatio;
                            w = w && w * widthRatio;
                            h = h && h * heightRatio;

                            nextOptions = {format, quality, x, y, w, h};
                        }

                        this.chatApi.requestAction('takeSnapshot', nextOptions);

                        return new Promise((resolve, reject) => {
                            this.chatApi.once('handleSnapshotAction', resolve);
                            this.chatApi.once('handleSnapshotFailureAction', reject);
                        });
                    }

                    if (nextOptions) {
                        return this.annotateService.getCleanDataURL(nextOptions);
                    }

                    return this.currentResource.url;
                })
                .then((image) =>
                    scanner
                        .scan(image.base64img || image, takenFrom)
                        .then((result) => {
                            const newHeaderClass = this.isToggleEnabled_newHeaderFooterLeftbar() ? 'new-header' : '';

                            this.$modal
                                .open({
                                    animation: true,
                                    template: scanResultView,
                                    controller: ScanResultController,
                                    controllerAs: 'vm',
                                    windowClass: `dashboard-scan-results-modal ${newHeaderClass}`,
                                    resolve: {
                                        type: () => scanner.type,
                                        result: () => result,
                                        dir: () => this.dir,
                                        searchTags: this.searchScannedTags
                                    }
                                })
                                .result.then((res) => {
                                    if (res && res.search) {
                                        this.submitSearchTagForm(res.searchTerm);
                                    }
                                });

                            return result;
                        })
                        .catch(() => {
                            this.$modal
                                .open({
                                    animation: true,
                                    template: scanResultView,
                                    controller: ScanResultController,
                                    controllerAs: 'vm',
                                    windowClass: 'dashboard-scan-results-modal',
                                    resolve: {
                                        type: () => scanner.type,
                                        result: () => null,
                                        dir: () => this.dir,
                                        searchTags: null
                                    }
                                })
                                .result.catch(() => false);
                        })
                )
                .then((result) => {
                    this._handleScannerFields(scanner, result);
                })
                .finally(() => {
                    this.showModeLabel = false;

                    if (isVideo) {
                        this.video.mode = VIDEO_MODES.CURSOR_SHARING;
                        this.tsScsan.removeAllListeners('areaSelected');
                        this.tsScanArea.removeAllListeners('areaSelected');
                    } else if (this.isToggleEnabled_ScanCaptureDesign()) {
                        this.cancelScanning();
                    }
                });
        };

        if (scanner.settings.select) {
            if (isVideo) {
                if (this.video.mode === VIDEO_MODES.SCANNING) {
                    this.video.mode = VIDEO_MODES.CURSOR_SHARING;
                    this.showModeLabel = false;
                    this.tsScsan.removeAllListeners('areaSelected');
                    this.tsScanArea.removeAllListeners('areaSelected');

                    return;
                }

                this.setVideoMode(VIDEO_MODES.SCANNING);
            } else {
                if (this.imageMode === IMAGE_MODES.SCANNING) {
                    this.showModeLabel = false;
                    this.tsScanArea.destroy();
                    this.tsScanArea.removeAllListeners('areaSelected');

                    return this.setImageMode(null);
                }

                this.setImageMode(IMAGE_MODES.SCANNING);
            }

            this.tsScsan.removeAllListeners('areaSelected');
            this.tsScanArea.removeAllListeners('areaSelected');

            const onAreaSelected = ({rect}) => {
                const {x, y, width: w, height: h} = rect,
                    options = {quality: 1, x, y, w, h};

                scanNow(options);

                if (this.isToggleEnabled_ScanCaptureDesign()) {
                    this.$timeout(() => this.cancelScanning());
                }
            };

            if (this.isToggleEnabled_ScanCaptureDesign()) {
                this.tsScanArea.on('areaSelected', onAreaSelected);
            } else {
                this.tsScsan.on('areaSelected', onAreaSelected);
            }
        } else {
            scanNow();
        }
    }

    cancelScanning() {
        const isVideo = this.video.focus && this.video.enabled;

        if (isVideo) {
            this.video.mode = VIDEO_MODES.CURSOR_SHARING;
            this.tsScsan.removeAllListeners('areaSelected');
            this.tsScanArea.removeAllListeners('areaSelected');
        } else {
            this.setImageMode(null);
        }

        if (this.floatingToolbarScanHandlers) {
            this.floatingToolbarScanHandlers.deactivate();
        }

        this.tsScanArea.destroy();
    }

    sendTextMessage(form) {
        if (!form.textMessage) {
            return;
        }

        this.chatApi.sendText(form.textMessage);

        form.textMessage = '';
    }

    textMessageEnterSend(form, e) {
        if (!(e.shiftKey || e.ctrlKey) && e.keyCode === 13) {
            // checks whether the pressed key is "Enter"
            this.sendTextMessage(form);
        } else if ((e.shiftKey || e.ctrlKey) && e.keyCode === 13) {
            form.textMessage += '\n';
        }
    }

    getTextMessage() {
        return this.messageSendForm.textMessage;
    }

    loadTextMessage(text) {
        this.messageSendForm.textMessage = text;
    }

    libraryFileSelected(node) {
        if (node) {
            return Promise.resolve()
                .then(() => {
                    if (get(node.resource, 'security.enabled')) {
                        return this.db.Resource.resign(node.resource._id, {bypassCache: true}).then((resp) => {
                            node.resource = resp.data;

                            return node;
                        });
                    }

                    return node;
                })
                .then((node) => {
                    if (node.type === 'img') {
                        this.selectTool('arrow', true);
                        this.setResource(node.resource ? node.resource : node);
                        this.hideWidgets = false;

                        if (this.libraryTaggingMode && node.resource) {
                            this._setPreloadedResource(node.resource);
                        }

                        return;
                    }

                    if (node.type === 'file') {
                        // only image files can be persistently selected in the library tree view
                        this.selectedLibraryNode = this.prevSelectedLibraryImg;

                        this.chatApi.sendText(node.resource.url, {file: true});

                        return;
                    }

                    const currentText = this.getTextMessage();

                    this.loadTextMessage(currentText ? `${currentText} ${node.resource.url}` : node.resource.url);
                    this.msgSectionOpen = true;
                });
        }
    }

    libraryNodeToggled(node) {
        if (node.type === 'dir') {
            this.openLibraryDialog(node);
        }
    }

    openLibraryDialog(selectedNode) {
        const selection = selectedNode
            ? {type: selectedNode.type, title: selectedNode.title, url: selectedNode.url}
            : {};

        this.eventLog.openingLibraryDialog({selectedNode: selection});

        this._openImageDialog(this.libraryTree, selectedNode, this.libraryDialogRoot, true);
    }

    _loadCustomerHistory(refresh) {
        if (refresh || !this.customerHistoryLoaded) {
            this.customerHistoryLoaded = true;
            if (this.currentUser.accountId) {
                const now = moment.utc();
                const historyRecords = get(this.customerHistory, 'records') || [];
                const expiredImages = some(historyRecords, (record) =>
                    some(
                        record.images,
                        (resource) => get(resource, 'security.enabled') && now.isAfter(resource.security.expires)
                    )
                );

                const expiredVideos = some(historyRecords, (record) =>
                    some(
                        record.videos,
                        (resource) => get(resource, 'security.enabled') && now.isAfter(resource.security.expires)
                    )
                );

                // we don't need to reload the history if was loaded and there are no images/videos to resign
                if (refresh && this.customerHistory && !expiredImages && !expiredVideos) {
                    return this.$q.when(this.customerHistory);
                }

                const customerId = this.roomData.customerId ? this.roomData.customerId : 'none',
                    customerNumber = this.roomData.customerNumber ? this.roomData.customerNumber : 'none';

                return this.db.History.byCustomerIdOrNumber({
                    params: {
                        customerId: customerId,
                        customerNumber: customerNumber,
                        accountId: this.currentUser.accountId,
                        excludeRoomId: !this.offlineMode && !this.observe && this.roomData._id.toString()
                    }
                })
                    .then((result) => {
                        this.$rootScope.$emit(MeetingStateEvents.isUiReadyToLoadInteractionSummary);

                        return this.videoUtils.createThumbnailsForVideos(result.data);
                    })
                    .catch(() => {
                        this.customerHistoryLoaded = false;

                        return {};
                    });
            }
        }

        return Promise.resolve({});
    }

    openCustomerHistoryDialog() {
        this._loadCustomerHistory(true).then((history) => {
            if (history && history.records.length > 0) {
                this.$modal
                    .open({
                        animation: true,
                        template: historyDialogView,
                        controller: HistoryDialogController,
                        controllerAs: 'vm',
                        windowClass:
                            'dashboard-history-modal' +
                            (this._getDashboardScope().isToggleEnabled_NewHeaderFooterLeftbar() ? ' new-top-bar' : ''),
                        resolve: {
                            customerHistory: history,
                            dir: () => this.dir
                        }
                    })
                    .result.then(({resource, room}) => {
                        this.selectTool('arrow', true);
                        this.setResource(resource);

                        if (this.offlineMode && room._id) {
                            this._setPreloadedResource(resource, room);
                        }
                    })
                    .catch(() => false);
            }
        });
    }

    /**
     * Opens the library dialog, which can be either the account library
     *
     * @param tree - The tree that is displayed in the dialog
     * @param selectedNode - the selected Node
     * @param topLevel - Name of the top level dir of the dialog
     * @param footer - show or hide the footer of the dialog (carousel, actions)
     */
    _openImageDialog(tree, selectedNode, topLevel, footer) {
        const modalInstance = this.$modal.open({
            animation: true,
            template: libraryDialogView,
            controller: LibraryDialogController,
            controllerAs: 'vm',
            windowClass:
                'dashboard-library-modal' +
                (this._getDashboardScope().isToggleEnabled_NewHeaderFooterLeftbar() ? ' new-top-bar' : ''),
            resolve: {
                collection: tree,
                selectedNode: selectedNode,
                topLevel: {name: topLevel},
                footer: footer,
                dashboardScope: this._getDashboardScope(),
                offlineMode: this.offlineMode,
                endMeeting: this.clientDisconnected
            }
        });

        modalInstance.result
            .then((item) => {
                if (item.data.type === 'img') {
                    if (item.send) {
                        this.eventLog.dashboardSendingImage({library: true, mediaFileName: item.data.url});

                        return this.chatApi.sendImage(item.data.url, {
                            tags: [],
                            sharedBy: senderTypes.DASHBOARD,
                            mediaFileName: this.urlUtils.extractFileName(item.data.url)
                        });
                    }

                    this.selectTool('arrow', true);
                    this.setResource(item.data.resource ? item.data.resource : item.data);
                    this.hideWidgets = false;

                    if (this.libraryTaggingMode && item.data.resource) {
                        this._setPreloadedResource(item.data.resource);
                    }

                    // make image appear as selected on the tree control, too
                    if (topLevel === this.libraryDialogRoot) {
                        this.selectedLibraryNode = item.data;
                        // expand all the parent directories
                        this.expandedLibraryNodes = this._expandParents(tree, item.data.parents);
                    }
                } else if (item.data.type === 'video') {
                    this.selectTool('arrow', true);
                    this.setResource(item.data.resource ? item.data.resource : item.data);

                    if (this.libraryTaggingMode && item.data.resource) {
                        this._setPreloadedResource(item.data.resource);
                    }

                    // make image appear as selected on the tree control, too
                    if (topLevel === this.libraryDialogRoot) {
                        this.selectedLibraryNode = item.data;
                        // expand all the parent directories
                        this.expandedLibraryNodes = this._expandParents(tree, item.data.parents);
                    }
                } else {
                    // only image files can be persistently selected in the library tree view
                    this.selectedLibraryNode = this.prevSelectedLibraryImg;

                    return this.chatApi.sendText(item.data.url, {file: true});
                }
            })
            .catch(() => {
                this.selectedLibraryNode = this.prevSelectedLibraryImg;
            });
    }

    changeZoom() {
        this.annotateService.setZoom(Number(this.zoomFactor));
        this.selectTool('hand');
    }

    // In place of Math.sign() which is not supported in IE
    numberSign(num) {
        if (num < 0) {
            return -1;
        }

        return 1;
    }

    wheelChangeZoom(event, delta) {
        this.zoomFactor = Number((Number(this.zoomFactor) + this.numberSign(delta) * 0.1).toFixed(1));

        if (this.zoomFactor < 1) {
            this.zoomFactor = 1;
        } else if (this.zoomFactor > 5) {
            this.zoomFactor = 5;
        }

        this.annotateService.setZoom(this.zoomFactor);
        this.selectTool('hand');
    }

    isColorSelected(color) {
        return this.annotateService.toolkit.color === color;
    }

    getActiveColorName() {
        const index = findIndex(this.colors, {hexCode: this.annotateService.toolkit.color});

        return index >= 0 ? this.colors[index].name : 'unknown';
    }

    openRightBar(isInputTextFocus) {
        this._changeInputTextFocus(isInputTextFocus);

        this.expandRightBar = true;
    }

    closeRightBar(isInputTextFocus) {
        this._changeInputTextFocus(isInputTextFocus);

        this.expandRightBar = false;
    }

    _changeInputTextFocus(isInputTextFocus) {
        if (isInputTextFocus !== undefined) {
            this.isInputTextFocus = isInputTextFocus;
        }
    }

    closeRightBarIfNotFocused() {
        if (!this.isInputTextFocus) {
            this.closeRightBar();
        }
    }

    libraryEmpty() {
        return isEmpty(this.libraryTree.parents) && isEmpty(this.libraryTree.children);
    }

    historyEmpty() {
        return isEmpty(this.customerHistory) || isEmpty(this.customerHistory.records);
    }

    displayHistory() {
        if (
            !this.enableHistory ||
            this.imageSectionOpen ||
            this.videoSectionOpen ||
            this.hideHistory ||
            this.libraryTaggingMode
        ) {
            this.histSectionOpen = false;
        } else {
            this._loadCustomerHistory(false).then((history) => {
                if (isEmpty(this.customerHistory)) {
                    this.customerHistory = history;
                }
                this.histSectionOpen = !this.historyEmpty() && this.screenshots.length === 0;
                this.libSectionOpen = this.libSectionOpen && !this.histSectionOpen;
            });
        }
    }

    // Scrolls to top of images section
    scrollToImagesTop() {
        this.$location.hash('top');
        this.$anchorScroll();

        this.scrollToSideBarTop();
    }

    scrollToSideBarTop() {
        this.$location.hash('sd-top');
        this.$anchorScroll();
    }

    // Scrolls to top of chat section
    scrollToVideosTop() {
        this.$location.hash('videosTop');
        this.$anchorScroll();

        this.scrollToSideBarTop();
    }

    goToWizardStep(step) {
        this.wizardState.step = step;
    }

    goBackToEntry() {
        this.stateHelper.safeGo('dashboard.entry');
    }

    finishWizard() {
        this.showWizard = false;
        this.wizardState.finished = true;
        this.hideWidgets = false;
    }

    toggleMobileDrawer(toggle) {
        if (toggle === undefined) {
            this.drawerOpen = !this.drawerOpen;
        } else {
            this.drawerOpen = toggle;
        }
    }

    swipeMobileDrawer(event) {
        const direction =
            event.direction === Hammer.DIRECTION_LEFT
                ? 'left'
                : event.direction === Hammer.DIRECTION_RIGHT
                  ? 'right'
                  : '';

        if (!direction) {
            return;
        }

        const orientation = this.responsiveUtils.getOrientation();

        if (
            (orientation === 'portrait' && direction === 'right') ||
            (orientation === 'landscape' && direction === 'left')
        ) {
            this.toggleMobileDrawer(false);
        } else if (
            (orientation === 'portrait' && direction === 'left') ||
            (orientation === 'landscape' && direction === 'right')
        ) {
            this.toggleMobileDrawer(true);
        }
    }

    getTypeGlyphicon(type) {
        return find(RESOURCE_TYPES, {type: type.type}).icon;
    }

    openTextDialog(placeholder) {
        const modalInstance = this.$modal.open({
            animation: true,
            template: sendTextView,
            controller: SendTextController,
            controllerAs: 'vm',
            resolve: {
                placeholder: () => placeholder,
                readonly: () => this.observe || this.offlineMode,
                endMeeting: this.clientDisconnected
            }
        });

        modalInstance.result
            .then((text) => {
                if (text && !this.observe) {
                    this.chatApi.sendText(text, {predefined: true});
                }
            })
            .catch(() => false);
    }

    predefinedMessageSelected(resource, updated) {
        if (get(resource, 'security.enabled') && !updated) {
            return this.db.Resource.find(resource._id, {
                bypassCache: true
            }).then((updatedResource) => {
                resource.url = updatedResource.url;
                resource.security = updatedResource.security;

                this.predefinedMessageSelected(resource, true);
            });
        }

        if (resource.message) {
            return this.openTextDialog(`${resource.message}\n\n${resource.url}`);
        }

        this.openTextDialog(`${resource.url}`);
    }

    checkIfTagsExist(searchTerm) {
        return !!this.searchForTags({searchTerm: searchTerm}, true);
    }

    searchForTags(form, onlyCheck) {
        if (!form.searchTerm) {
            this.emptySearchTermSubmitted = true;

            return;
        }

        this.emptySearchTermSubmitted = false;

        const tagSearchResults = {
            history: [],
            library: []
        };

        if (this.searchHistoryTags) {
            const historyPartials = {
                length: 0,
                keys: []
            };

            forEach(this.customerHistory.records, (record) => {
                const partial = this._findTaggedResources([record.images, record.videos], form.searchTerm),
                    roomId = record.roomId ? record.roomId._id : null;

                if (roomId && partial && partial.length > 0) {
                    historyPartials.length += partial.length;
                    historyPartials.keys.push(roomId);
                    historyPartials[roomId] = partial;
                }
            });

            tagSearchResults.history = historyPartials;
        }

        if (this.searchLibraryTags) {
            tagSearchResults.library = this._findTaggedResources([this.library], form.searchTerm);
        }

        this.tagSearchResults = tagSearchResults;

        if (this.tagSearchHasResults()) {
            if (onlyCheck) {
                return true;
            }

            if (this.searchResultsModal) {
                this.searchResultsModal.dismiss();
            }

            this.searchResultsModal = this.$modal
                .open({
                    animation: true,
                    template: searchResultsDialogView,
                    controller: SearchResultsController,
                    controllerAs: 'vm',
                    windowClass: 'dashboard-search-results-modal',
                    resolve: {
                        searchSettings: {
                            searchTerm: form.searchTerm,
                            searchHistoryTags: this.searchHistoryTags,
                            searchLibraryTags: this.searchLibraryTags
                        },
                        tagSearchResults: this.tagSearchResults
                    }
                })
                .result.then(({resource, room}) => {
                    this.selectTool('arrow', true);
                    this.setResource(resource);

                    if (this.offlineMode && room._id) {
                        this._setPreloadedResource(resource, room);
                    }

                    this.searchResultsModal = null;
                })
                .catch(() => {
                    this.searchResultsModal = null;
                });
        }
    }

    _findTaggedResources(resources, searchTerm) {
        const search = new RegExp(searchTerm.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&'), 'i');

        return chain(resources)
            .map((resourcesType) =>
                filter(resourcesType, (resource) => some(resource.tags, (tag) => search.test(tag.text)))
            )
            .flatten()
            .value();
    }

    tagSearchHasResults() {
        return (
            this.tagSearchResults &&
            (this.tagSearchResults.library.length > 0 || this.tagSearchResults.history.length > 0)
        );
    }

    _restoreOfflineHistory() {
        this._loadCustomerHistory(false).then((history) => {
            this.customerHistory = history;
            const roomHistory = find(
                this.customerHistory.records,
                ({roomId}) => roomId && roomId._id === this.roomData._id
            );

            if (roomHistory) {
                this.screenshots = roomHistory.images;
                this.videos = roomHistory.videos;

                this.meetingHistory = concat(
                    map(roomHistory.messages, (message) => ({
                        type: 'text',
                        message
                    })),
                    map(roomHistory.files, (message) => ({
                        type: 'text',
                        message
                    }))
                );
            }
        });
    }

    _setPreloadedResource(resource, room) {
        // FIXME this function sets the parameters, so after a page
        // refresh, the user will be getting the same selected room/resource.
        // this causes the controllers to re-run and the current
        // state is reloaded. when using signed urls the reload
        // can trigger the cached request CORS problem, so for now, the smallest
        // bug is chosen, which is that when refreshing the page in offline
        // history mode, the selected image is the one originally selected
        // in the search results page
        if (get(resource, 'security.enabled')) {
            if (!resource.isVideo) {
                this.preloadedImage.resource = resource;
            } else {
                this.preloadedVideo.resource = resource;
            }

            return;
        }

        if (room && this.roomData._id !== room._id) {
            this.preloadedImage = this.preloadedVideo = this.currentResource = {};

            return this.urlUtils.setParamValues({
                offline: this.offlineMode,
                room: room._id,
                loadImage: resource.isVideo ? null : resource._id,
                loadVideo: resource.isVideo ? resource._id : null
            });
        }

        this.stateHelper.safeGo(
            '.',
            {
                loadImage: resource.isVideo ? null : resource._id,
                loadVideo: resource.isVideo ? resource._id : null
            },
            {
                notify: false
            }
        );

        this.preloadedImage.resource = resource;
    }

    _isCaptureAvailable() {
        return !this.isPaused && this.video.enabled && this.displayLiveVideo() && !this.isDesktopSharingSession;
    }

    pauseVideo(e, value) {
        trace.info('Pause video clicked by dashboard');

        if (e) {
            e.stopPropagation();
        }

        this.cancelScanning();

        this.tsSnapshotReminderService.videoReady(!this.isPaused);
        const newState = isUndefined(value) ? !this.isPaused : value;

        if (this.isPaused === newState) {
            return;
        }

        this.chatApi.requestAction('toggleVideo', !this.isPaused);
        this.$rootScope.$emit(MeetingStateEvents.MobilePause, this.isPaused);
    }

    // bind VIDEO_MODES to the video property
    get video() {
        return this._video;
    }

    set video(video) {
        this._video = video;

        if (video) {
            video.MODES = VIDEO_MODES;
        }
    }

    showPhotoSelectionDialog() {
        const isPhotoChat = this.mode === MeetingMode.images;
        const photoSelectionDialogState = get(this.chatApi, 'client.photoSelectionDialogState');
        const currentResourceUrl = get(this.currentResource, 'url');

        return isPhotoChat && photoSelectionDialogState && !currentResourceUrl;
    }

    get showVideoWidget() {
        return (
            (!this.video.focus || !this.video.enabled) &&
            !(this.libraryTaggingMode && !this.preloadedVideo.resource) &&
            this.videoControlService.isActive
        );
    }

    get showImageWidget() {
        return (
            (!this.video.focus || !this.video.enabled) &&
            !(this.libraryTaggingMode && !this.preloadedImage.resource) &&
            !this.videoControlService.isActive
        );
    }

    imageLoaded(event) {
        const url = get(event, 'target.src');

        if (url) {
            this.chatApi.imageLoaded(url);
        }
    }

    flattenedHistoryRecords() {
        let records = [];

        forEach(get(this.customerHistory, 'records'), (record) => {
            records = concat(records, get(record, 'images'));
        });

        return records;
    }

    getSnapshotReminderLocation() {
        const captureButton = $('#ts-capture-image');

        if (!captureButton) {
            return {};
        }

        return captureButton.position();
    }

    getPreCameraApprovalImage() {
        const osName = get(this.chatApi, 'client.clientOsObject.name');
        const isIOS = !!osName.match(/ios/i);

        return `wizard/${isIOS ? 'iphone' : 'android'}/pre-camera-approval.png`;
    }

    shouldDisplayPause() {
        return (
            this.allowPauseByAgent &&
            (!this.isPaused || this.videoPausedByDashboard) &&
            !this.observe &&
            this.video.focus &&
            this.video.enabled &&
            this.mode !== MeetingMode.screen &&
            !(this.isToggleEnabled_ScanCaptureDesign() && this.video.mode === this.video.MODES.SCANNING)
        );
    }

    isScreenMeetingMode(mode) {
        return includes([MeetingMode.screen], mode);
    }

    isCoBrowsingMode(mode) {
        return includes([MeetingMode.coBrowsing], mode);
    }

    isImagesOrOneClickMode(mode) {
        return includes([MeetingMode.images, MeetingMode.oneClick], mode);
    }

    isScreensharingMode(mode) {
        return utils.isModeSupportNativeVideoSharing(mode);
    }

    isVideoOrScreensharingMode(mode) {
        return utils.isVideoAvailableInMode(mode) || utils.isModeSupportNativeVideoSharing(mode);
    }

    showOneClickGuidance() {
        this.returnToOneClickGuidance = true;
    }

    _getDashboardScope() {
        const parent = this.$scope.$parent;

        return parent && (parent.dashboard || (parent.$parent && parent.$parent.dashboard));
    }

    getNetworkStatus() {
        const connectionType = get(this._getDashboardScope(), 'dashboardState.clientNetworkInfo.connectionType');

        switch (connectionType.toUpperCase()) {
            case ConnectionTypesEnum.CELLULAR:
            case ConnectionTypesEnum.MOBILE_DATA:
                return ConnectionTypesEnum.MOBILE_DATA;
            case ConnectionTypesEnum.WIFI:
                return ConnectionTypesEnum.WIFI;
            default:
                return ConnectionTypesEnum.NETWORK;
        }
    }

    submitSearchTagForm(searchTerm) {
        this.searchTagForm.searchTerm = searchTerm;
        this.searchTagForm.$submitted = true;
        this.searchForTags(this.searchTagForm);
    }

    videoResolutionChanged(resolutionFormat) {
        if (this.chatApi.dashboard.allowHD) {
            this.videoFormatResolution = resolutionFormat;
        }
    }

    async magicMarkerCreated(triggeredByAgent) {
        if (!this.isMagicMarkerEnabled) {
            return;
        }

        if (triggeredByAgent && !this.triggeredDataCollectionOnAgentMagicMarkerEnabled) {
            return;
        }

        if (!triggeredByAgent && !this.triggeredDataCollectionOnCustomerMagicMarkerEnabled) {
            return;
        }

        const snapshot = await this._getSnapshotFromKnownStream({format: 'image/jpeg'});

        if (!snapshot) {
            return;
        }

        const snappedImg = this._snapshotToImg(snapshot.base64img);

        if (!snappedImg.size) {
            return;
        }

        const triggerSource = triggeredByAgent
            ? TriggeredDataCollectionSource.Agent
            : TriggeredDataCollectionSource.Customer;

        this.tsSharingService.uploadTriggeredCollectedImage(snappedImg.img, triggerSource);
    }

    handleTriggeredAgentImageSnapped(base64img) {
        if (!this.triggeredDataCollectionOnAgentImageSnappedEnabled) {
            return;
        }

        const snappedImg = this._snapshotToImg(base64img);

        if (!snappedImg.size) {
            return;
        }

        this.tsSharingService.uploadTriggeredCollectedImage(
            snappedImg.img,
            TriggeredDataCollectionSource.AgentImageSnapped
        );
    }
}
