'use strict';

import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';

import './search-results.style.scss';

export class SearchResultsController {
    constructor($modalInstance, db, $q, searchSettings, tagSearchResults) {
        'ngInject';

        this.$modalInstance = $modalInstance;
        this.searchSettings = searchSettings;
        this.db = db;
        this.$q = $q;

        this.tabs = {
            HISTORY: {
                name: 'history',
                active: false
            },
            LIBRARY: {
                name: 'library',
                active: false
            }
        };

        this._checkForExpiredResources(tagSearchResults).then(() => {
            this.tagSearchResults = tagSearchResults;

            if (this.tagSearchResults.library.length > this.tagSearchResults.history.length) {
                this.tabs.LIBRARY.active = true;
            } else {
                this.tabs.HISTORY.active = true;
            }
        });
    }

    _checkForExpiredResources(tagSearchResults) {
        const {history, library} = tagSearchResults;

        const promises = [].concat(
            this._checkForExpiredHistoryImages(history),
            this._checkForExpiredHistoryVideos(history),
            this._checkForExpiredLibraryResources(library)
        );

        return this.$q.all(promises);
    }

    _checkForExpiredHistoryImages(history = this.tagSearchHasResults.history) {
        const expiredHistory = filter(
            map(history.keys, (key) => filter(history[key], this._checkResourceExpiration)),
            (images) => images.length > 0
        );

        const historyPromises = map(expiredHistory, (resource) =>
            this.db.HistoryImage.find(resource._id, {
                bypassCache: true
            }).then((updatedImage) => {
                resource.url = updatedImage.url;
                resource.security = updatedImage.security;
            })
        );

        return historyPromises || [];
    }

    _checkForExpiredHistoryVideos(history = this.tagSearchHasResults.history) {
        const expiredHistory = filter(
            map(history.keys, (key) => filter(history[key], this._checkResourceExpiration)),
            (videos) => videos.length > 0
        );

        const historyPromises = map(expiredHistory, (resource) =>
            this.db.HistoryVideo.find(resource._id, {
                bypassCache: true
            }).then((updatedVideo) => {
                resource.url = updatedVideo.url;
                resource.security = updatedVideo.security;
            })
        );

        return historyPromises || [];
    }

    _checkForExpiredLibraryResources(library = this.tagSearchHasResults.library) {
        const expiredLibrary = filter(library, this._checkResourceExpiration);

        const libraryPromises = map(expiredLibrary, (resource) =>
            this.db.Resource.find(resource._id, {
                bypassCache: true
            }).then((updatedResource) => {
                resource.url = updatedResource.url;
                resource.security = updatedResource.security;
            })
        );

        return libraryPromises || [];
    }

    _checkResourceExpiration(resource) {
        return get(resource, 'security.enabled') && moment.utc().isAfter(resource.security.expires);
    }

    close() {
        this.$modalInstance.dismiss();
    }

    selectResource(resource, roomId) {
        const room = {_id: roomId};

        const resourceModel = roomId
            ? resource.isVideo
                ? this.db.HistoryVideo
                : this.db.HistoryImage
            : this.db.Resource;

        return resourceModel
            .resign(resource._id, {
                bypassCache: true
            })
            .then((resp) => {
                resource.url = resp.data.url;
                resource.security = resp.data.security;

                this.$modalInstance.close({resource, room});
            });
    }
}
