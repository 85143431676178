import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ITranslate} from '../../services/LocalizationService';
import {IRemoteControlPopupController} from './controller';
import {ConfirmationModal} from '@techsee/techsee-ui-common/lib/modals/confirmation-modal';

import './styles.scss';

export interface IRemoteControlPopup {
    ctrl: IRemoteControlPopupController;
    translate: ITranslate;
}

@observer
export class RemoteControlModal extends TechseeBaseComponent<IRemoteControlPopup> {
    renderInternal() {
        const {ctrl, translate} = this.props;

        return (
            <ConfirmationModal
                contentTitle={translate('REACT.MULTIPARTY_DASHBOARD.REMOTE_CONTROL.STOP.TITLE')}
                content={translate('REACT.MULTIPARTY_DASHBOARD.REMOTE_CONTROL.STOP.INFO')}
                negativeText={translate('REACT.MULTIPARTY_DASHBOARD.REMOTE_CONTROL.STOP.CANCEL')}
                positiveText={translate('REACT.MULTIPARTY_DASHBOARD.REMOTE_CONTROL.STOP.STOP_BUTTON')}
                show={ctrl.isStopControlModalVisible}
                onPositiveClick={() => ctrl.onStopRemoteControl()}
                onNegativeClick={() => ctrl.setStopControlModalVisible(false)}
                isCloseVisible={true}
                onHideHandler={() => ctrl.setStopControlModalVisible(false)}
                iconVisible={false}
            />
        );
    }
}
