/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {InviteGenericStep, InviteStepParams, InviteStepProps} from './_InviteGenericStep';
import {SimpleTabs, ITab} from '@techsee/techsee-ui-common/lib/tabs/simple';
import {AgentGuidance} from '../components/agent-guidance/AgentGuidance';
import {InviteStepsLayout} from './_Shared';
import {IInviteFlowManager, InviteDeviceType, InviteStepsEnum} from '../../_contracts/InviteContracts';
import {IMainLayoutController} from '../../../../layouts/main-layout/layout.controller';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';

interface IEmailStepController {
    sendEmail(): Promise<void>;
}

export class EmailStepController extends InviteGenericStep implements IEmailStepController {
    private _flowManager: IInviteFlowManager;

    constructor(
        flowManager: IInviteFlowManager,
        mainLayoutController: IMainLayoutController,
        domUtilsService: IDomUtilsService
    ) {
        const params: InviteStepParams = {
            stepType: InviteStepsEnum.SendSmsFlow,
            content: () => <EmailStep ctrl={this} />,
            toolbar: () => <React.Fragment />
        };

        super(flowManager, mainLayoutController, domUtilsService, params);

        this._flowManager = flowManager;
    }

    sendEmail() {
        return this._flowManager.sendEmail();
    }
}

@observer
export class EmailStep extends TechseeBaseComponent<InviteStepProps & {ctrl: EmailStepController}> {
    componentDidMount() {
        this.props.ctrl.setPagePlacement('email-sending-step');
        this.props.ctrl.sendEmail();
    }

    renderInternal() {
        const {translate} = this.props.ctrl;
        const tabsList: ITab[] = [];

        tabsList.push({title: 'Android', content: emailTabs(InviteDeviceType.android)});
        tabsList.push({title: 'iOS', content: emailTabs(InviteDeviceType.ios)});

        return (
            <React.Fragment>
                <InviteStepsLayout
                    className={'email-step'}
                    h1Text={translate('REACT.EMAIL.RECEIVED_EMAIL')}
                    h2Text={translate('REACT.EMAIL.VIEW.CONTENT')}>
                    <SimpleTabs tabsList={tabsList} uid={'email-step-tabs'} />
                </InviteStepsLayout>
            </React.Fragment>
        );

        function emailTabs(device: InviteDeviceType) {
            return () => (
                <React.Fragment>
                    <AgentGuidance
                        stepType={'email'}
                        stepNum={1}
                        deviceType={device}
                        stepText={translate('REACT.EMAIL.VIEW.NOTIFICATION')}
                    />
                </React.Fragment>
            );
        }
    }
}
