import {action, observable} from 'mobx';

export interface ISimpleModalController {
    readonly isModalVisible: boolean;
    readonly contentTitle: string;
    readonly content: string;
    readonly negativeText: string;
    readonly positiveText: string;
    readonly onPositiveClick: () => void;
    readonly onNegativeClick: () => void;
    readonly modalClassName: string;

    show(): void;
    hide(): void;
}

export class SimpleModalController implements ISimpleModalController {
    @observable contentTitle: string;

    @observable content: string;

    @observable negativeText: string;

    @observable positiveText: string;

    @observable onPositiveClick: () => void;

    @observable onNegativeClick: () => void;

    @observable modalClassName: string;

    @observable isModalVisible: boolean = false;

    constructor(
        modalClassName?: string,
        contentTitle?: string,
        content?: string,
        negativeText?: string,
        positiveText?: string,
        onPositiveClick?: () => void,
        onNegativeClick?: () => void
    ) {
        this.contentTitle = contentTitle || '';
        this.content = content || '';
        this.negativeText = negativeText || '';
        this.positiveText = positiveText || '';
        this.modalClassName = modalClassName || '';
        this.onPositiveClick = onPositiveClick || this.hide;
        this.onNegativeClick = onNegativeClick || this.hide;

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    @action
    show() {
        this.isModalVisible = true;
    }

    @action
    hide() {
        this.isModalVisible = false;
    }
}
