//Abstract
export class VideoPositioningStrategy {
    getVideoNewSize() {
        //returns VideoSizeResult
        throw 'not implemented';
    }

    updateVideoSize(elVideo, videoNewSize) {
        elVideo.width(videoNewSize.width);
        elVideo.height(videoNewSize.height);
    }

    updateVideoLocation(videoSize, elContainer) {
        elContainer.css({'margin-top': 0});
    }
}

export class VideoMetaData {
    constructor(streamWidth, streamHeight) {
        this.streamWidth = streamWidth;
        this.streamHeight = streamHeight;
    }

    get isPortrait() {
        return this.streamWidth < this.streamHeight;
    }

    get aspectRatio() {
        return this.streamWidth > 0 && this.streamHeight > 0 ? this.streamWidth / this.streamHeight : 1;
    }
}

export class VideoSizeResult {
    constructor(newWidth, newHeight) {
        this.width = newWidth;
        this.height = newHeight;
    }
}
