import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {LeftBarController} from './LeftBarController';
import {BorderRoundedIcon, IconColors, IconSizes} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {SquareRoundedIconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/square-rounded-icon';
import {MiniDashboardPopup} from '../components/popup';
import {Popover} from 'react-bootstrap';

import './leftBar.scss';

export type LeftBarProps = {
    controller: LeftBarController;
};

@observer
export class LeftBarComponent extends TechseeBaseComponent<LeftBarProps> {
    //Todo replace isRemoteControlOn to indicator from the controller
    state = {
        isScreenSharePopupOpen: false,
        isRequestRemoteControlPopupOpen: false,
        isRemoteControlOn: false,
        isShareScreenPopOverOn: false
    };

    toggleIsScreenSharePopupOpen = (): void => {
        this.setState({isScreenSharePopupOpen: !this.state.isScreenSharePopupOpen});
    };

    toggleIsShareScreenPopOverOn = (): void => {
        this.setState({isShareScreenPopOverOn: !this.state.isShareScreenPopOverOn});
    };

    toggleIsRequestRemoteControlPopupOpen = (): void => {
        this.setState({isRequestRemoteControlPopupOpen: !this.state.isRequestRemoteControlPopupOpen});
    };

    onStartShareScreen = (): void => {
        this.toggleIsScreenSharePopupOpen();
        this.props.controller.shareScreen();
    };

    onStopShareScreen = (): void => {
        this.toggleIsScreenSharePopupOpen();
        this.props.controller.stopShareScreen();
    };

    onRequestRemoteControl = (): void => {
        // Todo use the request remote control from the controller and remove the setState
        this.setState({isRemoteControlOn: true});
        this.toggleIsRequestRemoteControlPopupOpen();
        this.props.controller.setLastEvent(this.props.controller.translatedShareScreenText.toastRequestRemoteControl);
    };

    onStopRemoteControl = (): void => {
        // Todo use the stop remote control from the controller and remove the setState
        this.setState({isRemoteControlOn: false});
        this.toggleIsRequestRemoteControlPopupOpen();
        this.props.controller.setLastEvent(this.props.controller.translatedShareScreenText.toastLostRemoteControl);
    };

    renderInternal(): JSX.Element {
        const {controller} = this.props;
        const {isScreenSharePopupOpen, isRequestRemoteControlPopupOpen, isRemoteControlOn, isShareScreenPopOverOn} =
            this.state;
        const {
            endMeeting,
            onSnapshotRequested,
            userConnectionInfo,
            isEnabledDesktopSharingInTwoWay,
            isSharingScreenOn,
            translatedShareScreenText,
            isScreenShareDisabled,
            isEnableRemoteControl,
            isLivePointerRunning,
            isEnableScreenShareButtons,
            isDesktopView
        } = controller;

        const shareScreenStyle =
            isScreenSharePopupOpen && !isSharingScreenOn
                ? 'share-screen-icon-focus'
                : isSharingScreenOn
                  ? 'share-screen-icon-active'
                  : 'share-screen-icon';
        const remoteControlStyle =
            isRequestRemoteControlPopupOpen && !isRemoteControlOn
                ? 'share-screen-icon-focus'
                : isRemoteControlOn
                  ? 'share-screen-icon-active'
                  : 'share-screen-icon';
        /** @TODO add earth/globe and map icons */

        return (
            <div className='mini-dashboard-left-bar use-common-styles'>
                <div className='session-top-buttons'>
                    <div className='session-button'>
                        <BorderRoundedIcon
                            iconName={userConnectionInfo.icon}
                            colorName={IconColors.Green}
                            sizeName={IconSizes.Medium}
                            noBorder></BorderRoundedIcon>
                    </div>
                    <div className='session-button'>
                        {onSnapshotRequested && (
                            <SquareRoundedIconButton
                                className={isEnabledDesktopSharingInTwoWay ? 'camera-fill-icon icon-style' : ''}
                                iconName={isEnabledDesktopSharingInTwoWay ? 'camera_fill' : 'new-snap'}
                                colorName={isEnabledDesktopSharingInTwoWay ? IconColors.White : IconColors.KashmirBlue}
                                onClick={onSnapshotRequested}
                                disabled={!controller.isSnapshotEnabled}
                            />
                        )}
                        {isEnabledDesktopSharingInTwoWay && isEnableScreenShareButtons && isDesktopView && (
                            <div className={'share-screen-container'}>
                                {isScreenSharePopupOpen && (
                                    <div className={'popup-container'}>
                                        <MiniDashboardPopup
                                            title={
                                                isSharingScreenOn
                                                    ? translatedShareScreenText.stopShareScreenTitle
                                                    : translatedShareScreenText.startShareScreenTitle
                                            }
                                            info={
                                                isSharingScreenOn
                                                    ? translatedShareScreenText.stopShareScreenInfo
                                                    : translatedShareScreenText.startShareScreenInfo
                                            }
                                            primaryButtonText={
                                                isSharingScreenOn
                                                    ? translatedShareScreenText.stopShareScreenButton
                                                    : translatedShareScreenText.startShareScreenButton
                                            }
                                            onPrimaryButton={
                                                isSharingScreenOn ? this.onStopShareScreen : this.onStartShareScreen
                                            }
                                            onExitButton={this.toggleIsScreenSharePopupOpen}
                                            isErrorStatus={isSharingScreenOn}
                                        />
                                    </div>
                                )}

                                <div
                                    onMouseEnter={this.toggleIsShareScreenPopOverOn}
                                    onMouseLeave={this.toggleIsShareScreenPopOverOn}
                                    className={'share-screen-button-container'}>
                                    <SquareRoundedIconButton
                                        iconName='Sharing_outline'
                                        colorName={IconColors.White}
                                        onClick={this.toggleIsScreenSharePopupOpen}
                                        disabled={isScreenShareDisabled}
                                        className={`${shareScreenStyle} icon-style share-screen-icon-animation`}
                                    />
                                    {isShareScreenPopOverOn && (
                                        <Popover className={'popover-container'} id={'miniDashboard-popover-buttons'}>
                                            {isScreenShareDisabled
                                                ? translatedShareScreenText.popoverShareScreenDisabled
                                                : translatedShareScreenText.popoverShareScreenAvailable}
                                        </Popover>
                                    )}
                                </div>

                                {isEnableRemoteControl && (
                                    <SquareRoundedIconButton
                                        iconName='Control'
                                        colorName={IconColors.White}
                                        onClick={this.toggleIsRequestRemoteControlPopupOpen}
                                        disabled={!isScreenShareDisabled || !isLivePointerRunning}
                                        className={`${remoteControlStyle} margin-top icon-style`}
                                    />
                                )}

                                {isRequestRemoteControlPopupOpen && (
                                    <div className={'popup-container popup-container-remote-control'}>
                                        <MiniDashboardPopup
                                            title={
                                                isRemoteControlOn
                                                    ? translatedShareScreenText.stopRemoteControlTitle
                                                    : translatedShareScreenText.startRemoteControlTitle
                                            }
                                            info={
                                                isRemoteControlOn
                                                    ? translatedShareScreenText.stopRemoteControlInfo
                                                    : translatedShareScreenText.startRemoteControlInfo
                                            }
                                            primaryButtonText={
                                                isRemoteControlOn
                                                    ? translatedShareScreenText.stopRemoteControlButton
                                                    : translatedShareScreenText.startRemoteControlButton
                                            }
                                            onPrimaryButton={
                                                isRemoteControlOn
                                                    ? this.onStopRemoteControl
                                                    : this.onRequestRemoteControl
                                            }
                                            onExitButton={this.toggleIsRequestRemoteControlPopupOpen}
                                            isErrorStatus={isRemoteControlOn}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className='session-bottom-buttons'>
                    <div className='session-button'>
                        <SquareRoundedIconButton
                            iconName={isEnabledDesktopSharingInTwoWay ? 'End_call' : 'logout'}
                            colorName={IconColors.Red}
                            onClick={endMeeting}
                            className={isEnabledDesktopSharingInTwoWay ? 'icon-style' : ''}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
