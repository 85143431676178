// eslint-disable-next-line no-redeclare
/* globals fabric */

'use strict';

import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import debounce from 'lodash/debounce';
import clone from 'lodash/clone';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import {EventEmitter} from 'events';
import {CanvasMathUtils} from './lib/canvas-math-utils';
import {CanvasHistory} from './lib/canvas-history';
import {CanvasObjectGroups} from './lib/canvas-object-groups';
import {CanvasToolkit} from './lib/canvas-toolkit';
import {TOOLKIT_DEFAULTS} from './lib/canvas-toolkit.settings';
import {CanvasExport} from './lib/canvas-export';
import {getRootStore} from '../../_react_/app.bootstrap';
import {retryPromise} from './retry-promise';

function isImageRotated(img) {
    return !isUndefined(img.angle) && [90, -90].includes(parseInt(img.angle, 10));
}

export class TsCanvasAnnotateService extends EventEmitter {
    constructor($document, $window, $rootScope, $timeout, eventLog, mediaFrameUIParams, tsInterfaceHelper) {
        'ngInject';

        super();
        this.setTool(null);
        this.$document = $document;
        this.$window = $window;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.eventLog = eventLog;
        this.history = new CanvasHistory();
        this.objectGroups = new CanvasObjectGroups();
        this.toolkit = new CanvasToolkit(this.objectGroups);
        this.canvasExport = new CanvasExport(this.$window.URL);
        this.environmentDetect = getRootStore().environmentService;
        this.isMobile = this.environmentDetect.isMobile(getRootStore().displayTabletAsDesktop);
        this.readonly = false;
        this.historyLock = 0;
        this.mediaFrameUIParams = mediaFrameUIParams;
        this.tsInterfaceHelper = tsInterfaceHelper;
        this.disablePadding = false;

        // make borders and corner handles more pronounced on mobile screens
        if (this.isMobile) {
            fabric.Object.prototype.set({
                cornerColor: 'rgb(102,153,255)',
                cornerSize: 24,
                borderColor: 'rgb(102,153,255)',
                borderOpacityWhenMoving: 1,
                borderScaleFactor: 0.5,
                rotatingPointOffset: 60
            });
        }

        this._textEditing = false;
        this._enableZoom = true;
        this._enableRightRotate = true;
        this._enableLeftRotate = true;
        this._filterTagText = debounce(this._filterTagText, 300);

        this._addGlobaleventHandlers();
    }

    // Override funtion for EventEmitter.emit. Ensures that changes in the
    // callabck will be reflected on the scope (i.e. triggers digest loop)
    emit(...args) {
        const phase = this.$rootScope.$$phase;

        if (phase === '$apply' || phase === '$digest') {
            super.emit(...args);
        } else {
            this.$rootScope.$apply(() => super.emit(...args));
        }
    }

    get zoomEnabled() {
        return this._enableZoom;
    }

    get rightRotateEnabled() {
        return this._enableRightRotate;
    }

    get leftRotateEnabled() {
        return this._enableLeftRotate;
    }

    setCanvas(canvas, options) {
        this.options = options;

        // cleanup old event handlers
        if (this.canvas) {
            this.canvas.dispose();
        }

        this.canvas = new fabric.Canvas(canvas, {
            selection: false,
            perPixelTargetFind: !this.isMobile
        });
        this.history.init(this.canvas);
        this.toolkit.init(this.canvas);
        this.canvasExport.init(this.canvas);
        this.canvasReady = false;

        // If provided with default dimensions, we use these
        if (this.options.width && this.options.height) {
            this._setDimensions(this.options.width, this.options.height);
        }
        // We store the window size, to know how much it changes on resize
        this.windowSize = {
            width: this.$window.innerWidth,
            height: this.$window.innerHeight
        };

        // setup new canvas to listen on events
        this._addEventHandlers(this.canvas);

        // reset tools
        this.setTool(null);
    }

    removePadding() {
        this.disablePadding = true;
    }

    undo() {
        if (this.readonly) {
            return;
        }

        if (this._textEditing) {
            this._textEditing = false;

            const obj = this.canvas.getActiveObject();

            if (obj.type === 'i-text' && obj.text.match(/^\s*$/) && obj.changedFlag === undefined) {
                // if we press undo while a new tag is being added, its enough to
                // exit it and it will be removed by the text:editing:exited handler
                obj.exitEditing();

                return;
            }

            this._save();
        }

        this.history.undo(() => {
            this.canvas.setBackgroundImage(this.lastImgObject);
            this.objectGroups.rebuildGroupIndexes(this.canvas.getObjects());
        });
    }

    isFresh() {
        return !(this.isAnnotated() || this._isTagsChanged()) && this.getZoom() === 1;
    }

    /*
     * Clears all objects in canvas but maintains the background image
     */
    clearObjects() {
        if (this.readonly) {
            return;
        }

        const bg = this.canvas.backgroundImage;

        this.canvas.clear();
        this.history.reset();

        if (bg) {
            this.canvas.setBackgroundImage(bg);
        }
    }

    setTool(tool, opts) {
        if (this.readonly) {
            return;
        }

        let nextTool = tool;

        if (tool === 'pen') {
            this.toolkit.setFreeDrawingMode(true);
        } else if (this.tool === 'pen') {
            // if the tool was pen previously, we must now disable free-drawing
            this.toolkit.setFreeDrawingMode(false);
        } else if (tool === 'hand') {
            nextTool = null;
        }

        this.tool = nextTool;
        this.toolOptions = opts;
    }

    getTool() {
        return this.tool;
    }

    setColor(color) {
        if (this.readonly) {
            return;
        }

        this.toolkit.setColor(color);
    }

    getColor() {
        return this.toolkit.getColor();
    }

    /*
     * Return an objectURL of the canvas, for saving/sending purposes
     */
    getObjectURL(options) {
        if (!this.canvas.backgroundImage) {
            return null;
        }

        // deselect all and then return the URL, otherwise controls
        // get rendered, too
        this.canvas.discardActiveObject();
        this.canvas.renderAll();

        return this.canvasExport.getObjectURL(options);
    }

    dataUrlToObjectUrl(dataUrl) {
        return this.canvasExport.dataUrlToObjectUrl(dataUrl);
    }

    getDataURL({useOriginalImage, format, quality, x, y, w, h} = {}) {
        if (useOriginalImage) {
            return this.canvasExport.fabricSrcToDataUrl(this.lastImgObject._originalElement);
        }

        this.canvas.discardActiveObject();
        this.canvas.renderAll();

        //If some issue will appear that related to window.devicePixelRatio,
        //Need to solve it same way as it is solved in this.getCleanDataURL method.
        return this.canvasExport.getDataURL({format, quality, x, y, w, h});
    }

    getCleanDataURL({format, quality, x, y, w, h} = {}) {
        const canvas = this.canvas,
            objects = canvas.getObjects();

        objects.forEach((obj) => (obj.opacity = 0));
        canvas.renderAll();

        const devicePixelRatio = this.$window.devicePixelRatio || 1;
        const dataUrlParams = {
            format,
            quality,
            x: x * devicePixelRatio,
            y: y * devicePixelRatio,
            w: w * devicePixelRatio,
            h: h * devicePixelRatio
        };
        const dataURL = this.canvasExport.getDataURL(dataUrlParams);

        objects.forEach((obj) => (obj.opacity = 1));
        canvas.renderAll();

        return dataURL;
    }

    dataUrlToBlob(dataUrl) {
        return this.canvasExport.dataUrlToBlob(dataUrl);
    }

    /**
     * Sets the primary background image of the current canvas and
     * also resizes canvas according to image aspect ratio
     *
     * @param url {String} new background image
     */
    setBackgroundImage(url) {
        if (this.imageChangeLock) {
            this.nextImageUrl = url;

            return;
        }

        this.imageChangeLock = true;
        this._fetchBackgroundImage(url);
    }

    getTags() {
        return this.customTags || [];
    }

    setTags(tags) {
        this.oldTags = clone(tags);
        this.customTags = tags;
    }

    /**
     * Checks if image has any annotations based on history length
     * @return {Boolean} true if image has any annotations
     */
    isAnnotated() {
        return !this.history.isEmpty();
    }

    /**
     * Changes canvas zoom and makes sure to reset the panning,
     * when canvas is returned to original zoom (1)
     *
     * @param factor - {Number} New zoom (floating point number)
     */
    setZoom(factor) {
        if (!this.canvas) {
            return;
        }

        if (!this._enableZoom) {
            return;
        }

        if (this.canvas.getZoom() > 1 && factor === 1) {
            this.panning = false;
            this.canvas.absolutePan(new fabric.Point(0, 0));
        }

        if (this.canvas.getZoom() > factor) {
            const zoom = factor * this._getContainerScale(),
                posX = -this.canvas.viewportTransform[4],
                posY = -this.canvas.viewportTransform[5],
                maxPosX = (zoom - 1) * this.canvas.getWidth(),
                maxPosY = (zoom - 1) * this.canvas.getHeight(),
                offsetX = posX > maxPosX ? posX - maxPosX : 0,
                offsetY = posY > maxPosY ? posY - maxPosY : 0;

            if (offsetX || offsetY) {
                this.canvas.relativePan(new fabric.Point(offsetX, offsetY));
            }
        }

        this.canvas.setZoom(factor);
    }

    rotateRight(history = true) {
        if (this._enableRightRotate) {
            this._rotateLeftRight('right', history);
        }
    }

    rotateLeft(history = true) {
        if (this._enableLeftRotate) {
            this._rotateLeftRight('left', history);
        }
    }

    toggleRotation(state, direction) {
        if (!direction) {
            this._enableLeftRotate = state;
            this._enableRightRotate = state;
        }

        if (direction === 'left') {
            this._enableLeftRotate = state;
        } else if (direction === 'right') {
            this._enableRightRotate = state;
        }
    }

    toggleZoom(state) {
        this._enableZoom = state;
    }

    _setBackgroundFromDataUrl(url, cb) {
        fabric.Image.fromURL(
            url,
            (img) => {
                this.lastImgObject = img;

                const imageRatio = img.width / img.height;
                const allowedRatios = this._getAllowedRatios();

                let selectedRatio = imageRatio;
                let canvasSize = null;

                // If a list of allowed ratios has been provided, we select the
                // closest one to the background image
                if (allowedRatios) {
                    selectedRatio = CanvasMathUtils.selectBestRatio(imageRatio, allowedRatios);
                }

                if (!this.isMobile && !this.disablePadding) {
                    this.options.maxWidth = this.$window.innerWidth - this.mediaFrameUIParams.WIDTH_PADDING_IMAGES;
                    this.options.maxHeight = this.$window.innerHeight - this.tsInterfaceHelper.mediaFrameHeightPadding;
                }

                // Decide size for the canvas
                if (this.options && this.options.maxWidth && this.options.maxHeight) {
                    const maxDimRatio = this.options.maxWidth / this.options.maxHeight;

                    canvasSize = CanvasMathUtils.fixInOutRatio(
                        maxDimRatio,
                        selectedRatio,
                        'in',
                        this.options.maxWidth,
                        this.options.maxHeight
                    );
                } else {
                    canvasSize = CanvasMathUtils.fixInOutRatio(
                        selectedRatio,
                        imageRatio,
                        'out',
                        img.width - this.mediaFrameUIParams.MOBILE_CANVAS_CONTAINER_PADDING,
                        img.height
                    );
                }

                const imgProps = {
                    top: 0,
                    left: 0,
                    scaleX: canvasSize.width / img.width,
                    scaleY: canvasSize.height / img.height,
                    originX: 'left',
                    originY: 'top'
                };

                img.set(imgProps);

                // clear and resize canvas
                this.canvas.clear();
                this._setDimensions(canvasSize.width, canvasSize.height);

                // finally, set the background and trigger a refresh
                this.canvas.setBackgroundImage(img, () => {
                    this.canvas.renderAll();

                    // start with a clean slate;
                    this.history.reset();
                    this.canvasReady = true;

                    if (this.lastObjectUrl) {
                        URL.revokeObjectURL(url);
                    }

                    if (this.nextImageUrl) {
                        const url = this.nextImageUrl;

                        this.nextImageUrl = null;
                        this._fetchBackgroundImage(url);
                    } else {
                        this.imageChangeLock = false;
                    }

                    this.resizeAnnotationCanvas();
                });

                if (cb) {
                    cb();

                    return;
                }
            },
            {
                crossOrigin: 'Anonymous'
            }
        );
    }

    /**
     *
     * Fetches an image and converts to a dataURL, then proceeds with
     * setting it as the selected background Image.
     *
     * @param url {String} new background image
     */
    async _fetchBackgroundImage(url) {
        if (this.canvas) {
            this._resetViewport();

            this.eventLog.agentImageDownloadStarted({url: url ? url.substring(0, 5000) : ''});

            // Try loading image from URL, if necessary retry up to 10 times with 200ms intervals
            const PromiseTryLoadingImage = async () =>
                new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();

                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status !== 200) {
                            return reject({message: 'Failed to load image from URL', code: xhr.status});
                        } else if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                            this.eventLog.agentImageDownloadSuccess({url: url ? url.substring(0, 5000) : ''});

                            this._setBackgroundFromDataUrl(URL.createObjectURL(xhr.response), resolve);
                        }
                    };

                    xhr.open('GET', url, true);
                    xhr.responseType = 'blob';
                    xhr.setRequestHeader('Cache-Control', 'no-cache');
                    xhr.send();
                }).catch((err) => {
                    this.eventLog.agentImageDownloadFailed({
                        url: url ? url.substring(0, 5000) : '',
                        error: err?.message || err,
                        code: err?.code || 'Unknown'
                    });

                    if (this.nextImageUrl) {
                        const nextUrl = this.nextImageUrl;

                        this.nextImageUrl = null;

                        this._fetchBackgroundImage(nextUrl);
                    } else {
                        this.imageChangeLock = false;

                        this.emit('canvas:failedtoloadurl');
                    }
                });

            const retrySettings = {retries: 10, retryIntervalMs: 200};

            await retryPromise(PromiseTryLoadingImage, retrySettings);
        }
    }

    _getAllowedRatios() {
        if (this.options && this.options.allowedRatios && this.options.allowedRatios.length) {
            return this.options.allowedRatios;
        }

        return false;
    }

    _scaleObject(obj, factor) {
        const {scaleX, scaleY, left, top} = obj;

        if (obj.type !== 'line') {
            obj.scaleX = scaleX * factor;
            obj.scaleY = scaleY * factor;
            obj.left = left * factor;
            obj.top = top * factor;
        }

        if (obj.type === 'triangle') {
            const line = this.objectGroups.getGroupedObject(obj);

            // scale the line
            line.left *= factor;
            line.top *= factor;
            line.x1 *= factor;
            line.y1 *= factor;
            line.strokeWidth *= factor;

            // put the line at the top of the triangle
            line.set({x2: obj.left, y2: obj.top});

            line.setCoords();
        }

        obj.setCoords();
    }

    _addGlobaleventHandlers() {
        const rotateHandler = () => {
            if (!this.canvas || this._textEditing) {
                return;
            }

            this.$timeout.cancel(this.resetTimer);
            this.resetTimer = this.$timeout(() => {
                const container = $(this.canvas.getElement()).parent();

                this.options.maxWidth = container.width();
                this.options.maxHeight = container.height();

                const sx = this.options.maxWidth / this.canvas.width,
                    sy = this.options.maxHeight / this.canvas.height,
                    sc = Math.min(sx, sy);

                this.windowSize = {
                    width: this.$window.innerWidth,
                    height: this.$window.innerHeight
                };

                this._setContainerScale(sc);
            }, 150);
        };

        const resizeHandler = () => {
            this.resizeAnnotationCanvas();
        };

        this.$window.addEventListener('resize', resizeHandler);
        this.$window.addEventListener('orientationchange', rotateHandler);

        this.$document.on('keydown', (e) => {
            if (!this.canvas || this.readonly) {
                return;
            }
            // Listen to 'Del' keypress (deletes sected object)
            if (e.keyCode === 46) {
                if (this.canvas.getActiveObjects()) {
                    const group = this.canvas.getActiveObjects();

                    forEach(group, (obj) => {
                        this.canvas.remove(obj);
                    });
                    this.canvas.discardActiveObject();
                    this.canvas.renderAll();
                } else if (this.canvas.getActiveObject()) {
                    const obj = this.canvas.getActiveObject();

                    this.canvas.remove(obj);
                    this.canvas.discardActiveObject();
                    this.canvas.renderAll();
                }
            } else if (e.keyCode === 27) {
                // Listen to 'Esc' keypress (deselects current tool).
                this.setTool();
                this.emit('tool:deselect');
            }
        });
    }

    resizeAnnotationCanvas() {
        if (!this.canvas) {
            return;
        }

        const getContainerWidth = () => {
            const classificationWidget = $('.device-classification-widget-panel').outerWidth() || 0;

            return (
                this.$window.innerWidth -
                this.mediaFrameUIParams.WIDTH_PADDING_IMAGES -
                classificationWidget -
                this.mediaFrameUIParams.MOBILE_EDIT_CANVAS_PADDING
            );
        };

        const getContainerHeight = () => {
            if (this.isMobile) {
                return this.$window.innerHeight;
            }

            if (!this.disablePadding) {
                return this.$window.innerHeight - this.tsInterfaceHelper.mediaFrameHeightPadding;
            }

            const imageEditorContainer = $('.image-editor-container');
            const imageEditorContainerHeight =
                imageEditorContainer &&
                this.$window.innerHeight - (this.$window.innerHeight - imageEditorContainer.outerHeight());

            return imageEditorContainerHeight;
        };

        const width =
            this.isMobile || this.disablePadding
                ? this.$window.innerWidth - this.mediaFrameUIParams.MOBILE_EDIT_CANVAS_PADDING
                : getContainerWidth();

        const height = getContainerHeight();

        const canvasRatio = width / height;
        let canvasSize = {width, height};
        let factor = 1;

        if (this.options && this.options.maxWidth && this.options.maxHeight) {
            this.options.maxWidth = width;
            this.options.maxHeight = height;
        }

        if (this.lastImgObject) {
            let imageRatio = this.lastImgObject.width / this.lastImgObject.height,
                selectedRatio = imageRatio;

            if (isImageRotated(this.lastImgObject)) {
                imageRatio = 1 / imageRatio;
            }

            const allowedRatios = this._getAllowedRatios();

            if (allowedRatios) {
                selectedRatio = CanvasMathUtils.selectBestRatio(imageRatio, allowedRatios);
            }

            canvasSize = CanvasMathUtils.fixInOutRatio(canvasRatio, selectedRatio, 'in', width, height);

            factor = canvasSize.width / this.canvas.width;

            const imgParams = this._getImageRotateParams(canvasSize, this.lastImgObject, this.lastImgObject.angle);

            this.lastImgObject.set(imgParams);
        }

        this._setDimensions(canvasSize.width, canvasSize.height);

        if (factor !== 1) {
            const objects = this.canvas.getObjects();

            forEach(objects, (obj) => this._scaleObject(obj, factor));
        }

        this.canvas.renderAll();
        this.canvas.calcOffset();
    }

    // Internally triggered viewport reset (emits zoom:change event to notify
    // controllers of the value change)
    _resetViewport() {
        this.canvas.setZoom(1);
        this._setContainerScale(1);
        this.panning = false;
        this.canvas.absolutePan(new fabric.Point(0, 0));
        this.emit('zoom:change', 1);
    }

    _getTouchEventCoords(e) {
        const rect = this.canvas.getElement().getBoundingClientRect();

        return {
            offsetX: e.touches[0].clientX - rect.left,
            offsetY: e.touches[0].clientY - rect.top
        };
    }

    _addEventHandlers(canvas) {
        // When empty space is clicked, a new object is added (if a tool is selected)
        canvas.on('mouse:down', (options) => {
            if (this.readonly) {
                return;
            }

            if (!options.target && this.tool && !this.canvas.isDrawingMode) {
                const obj = this._addAnnotation(this.tool, options.e),
                    {offsetX, offsetY} = this._getEventCoords(options.e),
                    coords = this._transposeCoordinates(offsetX, offsetY);

                if (obj) {
                    this.newItem = {
                        obj: obj,
                        x: coords.x,
                        y: coords.y
                    };
                }
            } else if (canvas.getZoom() > 1) {
                this.panning = true;
            } else if (options.target && !options.target.text && options.target.groupId) {
                // We need text object always to be above it's own rectangle container
                const selectedObj = this.objectGroups.getGroupedObject(options.target);

                if (selectedObj) {
                    this.canvas.setActiveObject(selectedObj);
                }
            }
        });

        // If we are creating a new object, moving the mouse has the following
        // 'interactive' results, according to tool:
        // rectangles and circles: sets its initial size
        // arrows, it positions the tip
        // text: nothing
        // pen: draws, but handled by fabric, not here

        canvas.on('mouse:move', (options) => {
            if (this.readonly) {
                return;
            }

            /**
             * We need to get REAL canvas size not when canvas is being inttialized
             * But when event REAL fired. Cause we can resize window or HTML element etc.
             *
             * Don't worry about performance overhead, JS virtual will optimize it.
             */
            const canvasWidth = this.canvas.getWidth();
            const canvasHeight = this.canvas.getHeight();

            const ev = options.e.originalEvent;
            const rect = this.canvas.getElement().getBoundingClientRect();

            const pageX = get(options.e, 'touches[0].pageX') || get(ev, 'changedTouches[0].pageX') || options.e.pageX;
            const pageY = get(options.e, 'touches[0].pageY') || get(ev, 'changedTouches[0].pageY') || options.e.pageY;

            const offsetX = Math.min(Math.max(pageX - rect.left, 0), rect.width);
            const offsetY = Math.min(Math.max(pageY - rect.top, 0), rect.height);

            if (this.newItem) {
                const obj = this.newItem.obj;
                const coords = this._transposeCoordinates(offsetX, offsetY);

                coords.x = coords.x < 1 ? 1 : coords.x;
                coords.y = coords.y < 1 ? 1 : coords.y;

                coords.x = coords.x < canvasWidth ? coords.x : canvasWidth - TOOLKIT_DEFAULTS.pencilWidth / 2;
                coords.y = coords.y < canvasHeight ? coords.y : canvasHeight - TOOLKIT_DEFAULTS.pencilWidth / 2;

                const moveX = Math.abs(this.newItem.x - coords.x),
                    moveY = Math.abs(this.newItem.y - coords.y);

                if (this.newItem.x > coords.x) {
                    obj.set({left: coords.x});
                }
                if (this.newItem.y > coords.y) {
                    obj.set({top: coords.y});
                }

                if (obj.type === 'rect') {
                    if (this.tool === 'select') {
                        obj.set({
                            width: moveX * (coords.x < this.newItem.x ? -1 : 1),
                            height: moveY * (coords.y < this.newItem.y ? -1 : 1)
                        });
                    } else {
                        obj.set({width: 2 * moveX, height: 2 * moveY});
                    }
                } else if (obj.type === 'ellipse') {
                    obj.set({rx: moveX, ry: moveY});
                } else if (obj.type === 'line') {
                    obj.set({x2: coords.x, y2: coords.y});
                    const triangle = this.objectGroups.getGroupedObject(obj);

                    triangle.set({
                        left: coords.x,
                        top: coords.y,
                        angle: CanvasMathUtils.lineAngle(obj) + 90
                    });
                    triangle.setCoords();
                }

                obj.setCoords();
                canvas.renderAll();
            }
            // Safari and IE do not support event.mouseMovementX and Y so we
            // have to keep track of it ourselves. Also, for some reason the
            // move event comes before a down event, so the creation of the
            // tracker has to happen here, too.
            if (!this.mouseMovement) {
                this.mouseMovement = {
                    x: offsetX,
                    y: offsetY,
                    moveX: 0,
                    moveY: 0
                };
            } else {
                this.mouseMovement.moveX = offsetX - this.mouseMovement.x;
                this.mouseMovement.moveY = offsetY - this.mouseMovement.y;
                this.mouseMovement.x = offsetX;
                this.mouseMovement.y = offsetY;

                if (this.panning && this.tool !== 'pen' && !this.canvas.getActiveObject()) {
                    const zoom = this.canvas.getZoom() * this._getContainerScale(),
                        posX = -this.canvas.viewportTransform[4],
                        posY = -this.canvas.viewportTransform[5],
                        maxPosX = (zoom - 1) * this.canvas.getWidth(),
                        maxPosY = (zoom - 1) * this.canvas.getHeight();

                    const moveX = CanvasMathUtils.clampOffset(posX, this.mouseMovement.moveX, 0, maxPosX);

                    const moveY = CanvasMathUtils.clampOffset(posY, this.mouseMovement.moveY, 0, maxPosY);

                    if (
                        Math.abs(moveX) > TOOLKIT_DEFAULTS.maxHandDelta ||
                        Math.abs(moveY) > TOOLKIT_DEFAULTS.maxHandDelta
                    ) {
                        // protection agains sudden movements due to the
                        // mouse jumping in and out of the canvas
                        return;
                    }

                    this.canvas.relativePan(new fabric.Point(moveX, moveY));
                }
            }
        });

        // If we were creating an object, this marks the end of that process.
        // also if this was an arrow, rect or eclipse we save history now that
        // the initial shape/size/direction is settled.
        canvas.on('mouse:up', (options) => {
            if (this.readonly) {
                return;
            }

            if (this.newItem) {
                if (this._isZeroSizeEllipse(this.newItem.obj) || this._isZeroSizeRectangle(this.newItem.obj)) {
                    this.history.reload(() => {
                        this.canvas.setBackgroundImage(this.lastImgObject);
                        this.objectGroups.rebuildGroupIndexes(this.canvas.getObjects());
                    });
                } else if (
                    (options.target && this._isArrowPart(options.target)) ||
                    (!options.target && this._isArrowPart(this.newItem.obj)) ||
                    this.newItem.obj.type === 'rect' ||
                    this.newItem.obj.type === 'ellipse'
                ) {
                    this._save();
                }

                if (this.tool === 'select') {
                    const {x, y, obj} = this.newItem,
                        {width, height} = obj,
                        rect = {
                            x: width > 0 ? x : x + width,
                            y: height > 0 ? y : y + height,
                            width: Math.abs(width),
                            height: Math.abs(height)
                        };

                    this.emit('areaSelected', {rect});
                }
            }

            this.panning = false;
            this.newItem = null;
            this.mouseMovement = null;
        });

        canvas.on('mouse:wheel', (options) => {
            if (!this._enableZoom) {
                options.e.preventDefault();
                options.e.stopPropagation();
            }
        });

        // used for saving pencil lines.
        canvas.on('object:added', (options) => {
            const target = options.target;

            if (
                (this.objectGroups.isGrouped(target) && !(target.type === 'i-text' && !target.text.match(/^\s*$/))) ||
                options.target.type === 'rect' ||
                options.target.type === 'ellipse'
            ) {
                return;
            }
            this._save();
        });

        // Captures most interactions with rectangles and circles
        canvas.on('object:modified', () => {
            this._save();
        });

        // when 'Delete' is pressed on an object
        canvas.on('object:removed', (options) => {
            const obj = options.target;

            if (this.objectGroups.isGrouped(obj)) {
                this.objectGroups.removeRestGroup(this.canvas, obj);
            }
            this._save();
        });

        // Scaling, using the controls
        canvas.on('object:scaling', (options) => {
            // Scaling, as performed by fabric.js, transforms the stroke, which
            // looks weird. So what happens here is that scaling is essentially
            // replaced with resizing, which gives the result we want.
            const obj = options.target;

            if (obj.type === 'rect') {
                const width = obj.width * obj.scaleX,
                    height = obj.height * obj.scaleY;

                obj.set({width: width, height: height, scaleX: 1, scaleY: 1});
            } else if (obj.type === 'ellipse') {
                const radiusX = obj.rx * obj.scaleX,
                    radiusY = obj.ry * obj.scaleY;

                obj.set({rx: radiusX, ry: radiusY, scaleX: 1, scaleY: 1});
            }
            obj.setCoords();
        });

        // Takes care of grouped objects, to move together (or if the arrow
        // tip is being moved, modify the arrow accordingly).
        canvas.on('object:moving', (options) => {
            const obj = options.target;

            if (obj.type === 'triangle') {
                const line = this.objectGroups.getGroupedObject(obj);

                line.set({x2: obj.left, y2: obj.top});
                obj.set({angle: CanvasMathUtils.lineAngle(line) + 90 - line.angle});
                line.setCoords();
                obj.setCoords();
            } else if (obj.type === 'line') {
                const offsetX = options.e.movementX || (this.mouseMovement && this.mouseMovement.moveX) || 0,
                    offsetY = options.e.movementY || (this.mouseMovement && this.mouseMovement.moveY) || 0,
                    zoom = canvas.getZoom();

                obj.set({
                    x1: obj.x1 + offsetX / zoom,
                    y1: obj.y1 + offsetY / zoom,
                    x2: obj.x2 + offsetX / zoom,
                    y2: obj.y2 + offsetY / zoom
                });

                const triangle = this.objectGroups.getGroupedObject(obj);

                triangle.set({left: obj.x2, top: obj.y2});

                triangle.setCoords();
                obj.setCoords();
            } else if (obj.type === 'rect' && this.objectGroups.isGrouped(obj)) {
                const text = this.objectGroups.getGroupedObject(obj);

                if (text) {
                    const type = this.objectGroups.getGroup(obj).type,
                        left = type === 'numtag' || type === 'texttag' ? obj.left : obj.left + 3;

                    text.set({left: left, top: obj.top});
                    text.setCoords();
                }
            } else if (obj.type === 'i-text') {
                const baloon = this.objectGroups.getGroupedObject(obj),
                    type = this.objectGroups.getGroup(obj).type,
                    left = type === 'numtag' || type === 'texttag' ? obj.left : obj.left - 3;

                baloon.set({left: left, top: obj.top});
                baloon.setCoords();
            }
        });

        // with every keypress we may need to recalculate the size of the baloon.
        // To avoid constantly doing so,
        canvas.on('text:changed', (options) => {
            const text = options.target,
                type = this.objectGroups.getGroup(text).type;

            if (type === 'texttag' || type === 'numtag') {
                const content = text.text,
                    maxLength =
                        type === 'texttag' ? TOOLKIT_DEFAULTS.textTagMaxLength : TOOLKIT_DEFAULTS.numTagMaxLength,
                    newLineIndex = content.indexOf('\n');

                if (newLineIndex >= 0 || content.length > maxLength) {
                    this._filterTagText(text, maxLength);

                    return;
                }

                const baloon = this.objectGroups.getGroupedObject(text),
                    textPadding = TOOLKIT_DEFAULTS.textTagPadding,
                    minWidth = TOOLKIT_DEFAULTS.numTagWidth - 2 * TOOLKIT_DEFAULTS.tagBorderWidth,
                    widthIncrement = TOOLKIT_DEFAULTS.textBoxWidthIncrement / 2,
                    zoom = canvas.getZoom(),
                    rect = this._getTextRect(text),
                    width = (rect.width / zoom > minWidth ? rect.width / zoom : minWidth) + 2 * textPadding;

                if (width > baloon.width && type === 'texttag') {
                    baloon.set({
                        width: width + widthIncrement
                    });
                    baloon.setCoords();
                    this.canvas.renderAll();
                } else if (width < baloon.width - (widthIncrement + textPadding)) {
                    baloon.set({
                        width: width
                    });
                    baloon.setCoords();
                    this.canvas.renderAll();
                }
            } else {
                const text = options.target,
                    baloon = this.objectGroups.getGroupedObject(text),
                    lineHeight = TOOLKIT_DEFAULTS.lineHeight * TOOLKIT_DEFAULTS.fontSize,
                    textPadding = TOOLKIT_DEFAULTS.textPadding,
                    minWidth = TOOLKIT_DEFAULTS.minTextBoxWidth,
                    widthIncrement = TOOLKIT_DEFAULTS.textBoxWidthIncrement,
                    minHeight = TOOLKIT_DEFAULTS.minTextBoxHeight,
                    zoom = canvas.getZoom(),
                    rect = this._getTextRect(text),
                    width = (rect.width / zoom > minWidth ? rect.width / zoom : minWidth) + 2 * textPadding,
                    height = rect.height / zoom > minHeight ? rect.height / zoom : minHeight;

                if (height > baloon.height || height < baloon.height - lineHeight / 2) {
                    baloon.set({
                        height: height
                    });
                    baloon.setCoords();
                    this.canvas.renderAll();
                }

                if (width > baloon.width) {
                    baloon.set({
                        width: width + widthIncrement
                    });
                    baloon.setCoords();
                    this.canvas.renderAll();
                } else if (width < baloon.width - (widthIncrement + textPadding)) {
                    baloon.set({
                        width: width
                    });
                    baloon.setCoords();
                    this.canvas.renderAll();
                }
            }
            text.changedFlag = true;
        });

        canvas.on('text:editing:entered', () => {
            this._textEditing = true;
        });

        // When text editing has ended, we check if something has actually
        // changed and also if the the text is now empty, we delete the
        // whole object
        canvas.on('text:editing:exited', (options) => {
            const text = options.target,
                empty = !text.text,
                changed = text.changedFlag;

            text.changedFlag = false;

            if (empty || (text.text.match(/^\s*$/) && changed === undefined)) {
                const baloon = this.objectGroups.getGroupedObject(text);

                this.historyLock += 2;

                this.canvas.remove(text);
                this.canvas.remove(baloon);
            }

            if (changed) {
                this._save();
            }

            this._textEditing = false;
        });
    }

    /*
     * This function returns the bounding rectangle of a text element,
     * taking into account overall image rotation. Used for size calculations
     * of the background box that encloses text annotations.
     *
     * @param text - FabricJS Text/IText Object
     *
     * @return Object { width, height }
     */
    _getTextRect(text) {
        const rect = text.getBoundingRect();

        // when the image is rotated 90/270/etc degrees, the text element
        // will calculate actual width and height (on x and y axis respectively),
        // but other elements have a  rotation offset, so their width will be on
        // the y axis and their height will be on the x axis. The easier way to
        // fix this, is to also rotate the text bounding rectangle
        if (text.angle % 180) {
            return {
                width: rect.height,
                height: rect.width
            };
        }

        return rect;
    }

    _rotateLeftRight(direction, history) {
        const rotateRads = direction === 'left' ? -Math.PI / 2 : Math.PI / 2;
        const rotateAngle = direction === 'left' ? -90 : 90;
        const w = this.canvas.width,
            h = this.canvas.height,
            center = new fabric.Point(w / 2, h / 2);

        /**
         * TODO: After testing need to remove scale
         */

        const scale = 1;

        this.historyLock = Infinity;

        this.canvas.getObjects().forEach((obj) => {
            const objOrigin = new fabric.Point(obj.left, obj.top),
                newObjPos = fabric.util.rotatePoint(objOrigin, center, rotateRads);

            obj.left = (newObjPos.x - (w / 2 - h / 2)) / scale;
            obj.top = (newObjPos.y - (h / 2 - w / 2)) / scale;

            if (obj.type === 'ellipse') {
                obj.set({rx: obj.rx / scale, ry: obj.ry / scale});
            } else if (obj.type !== 'i-text' && !(obj.type === 'rect' && this.objectGroups.isGrouped(obj))) {
                obj.width /= scale;
                obj.height /= scale;
            }

            if (obj.type === 'line') {
                const p1 = fabric.util.rotatePoint(new fabric.Point(obj.x1, obj.y1), center, rotateRads),
                    p2 = fabric.util.rotatePoint(new fabric.Point(obj.x2, obj.y2), center, rotateRads);

                const nx1 = (p1.x - (w / 2 - h / 2)) / scale,
                    ny1 = (p1.y - (h / 2 - w / 2)) / scale,
                    nx2 = (p2.x - (w / 2 - h / 2)) / scale,
                    ny2 = (p2.y - (h / 2 - w / 2)) / scale;

                obj.set({
                    x1: nx1,
                    y1: ny1,
                    x2: nx2,
                    y2: ny2
                });
            } else {
                obj.angle += rotateAngle;
            }

            obj.setCoords();
        });

        const newImageAngle = this.lastImgObject.angle + rotateAngle;
        const newCanvasSize = {width: h / scale, height: w / scale};
        const imageSize = {width: this.lastImgObject.width, height: this.lastImgObject.height};
        const imgParams = this._getImageRotateParams(newCanvasSize, imageSize, newImageAngle);

        this.lastImgObject.set(imgParams);
        this._setDimensions(newCanvasSize.width, newCanvasSize.height);
        this.canvas.renderAll();

        this.emit('rotated', direction);

        this.historyLock = 0;
        if (history) {
            const undoDirection = direction === 'left' ? 'right' : 'left';

            this._save({
                callback: (cb) => {
                    this._rotateLeftRight(undoDirection, false);

                    if (cb) {
                        return cb();
                    }
                }
            });
        }
    }

    _getImageRotateParams(canvasSize, imageSize, newAngle) {
        let isVertical = null;
        const resultObj = {
            top: 0,
            left: 0,
            angle: newAngle
        };

        if (resultObj.angle === 270) {
            resultObj.angle = -90;
        }

        if (resultObj.angle === -180) {
            resultObj.angle = 180;
        }

        switch (resultObj.angle) {
            case 0:
                resultObj.top = 0;
                resultObj.left = 0;
                isVertical = false;
                break;
            case 180:
                resultObj.top = canvasSize.height;
                resultObj.left = canvasSize.width;
                isVertical = false;
                break;
            case 90:
                resultObj.top = 0;
                resultObj.left = canvasSize.width;
                isVertical = true;
                break;
            case -90:
                resultObj.top = canvasSize.height;
                resultObj.left = 0;
                isVertical = true;
                break;
            default:
                throw new Error('Angle is incorrect, something gone wrong');
        }

        if (isVertical) {
            resultObj.scaleX = canvasSize.height / imageSize.width;
            resultObj.scaleY = canvasSize.width / imageSize.height;
        } else {
            resultObj.scaleX = canvasSize.width / imageSize.width;
            resultObj.scaleY = canvasSize.height / imageSize.height;
        }

        return resultObj;
    }

    _isZeroSizeEllipse(obj) {
        return obj.type === 'ellipse' && (obj.rx === 0 || obj.ry === 0);
    }

    _isZeroSizeRectangle(obj) {
        return obj.type === 'rect' && (obj.width === 0 || obj.height === 0);
    }

    _isArrowPart(obj) {
        return obj.type === 'line' || obj.type === 'triangle';
    }

    _filterTagText(text, maxLength) {
        const content = text.text,
            newContent = content.replace(/\n/g, '').substr(0, maxLength);

        if (text.setText) {
            text.setText(newContent);
        } else {
            text.text = newContent;
        }

        this.canvas.renderAll();
    }

    _save(callback) {
        if (this.historyLock > 0) {
            this.historyLock--;

            return;
        }

        const phase = this.$rootScope.$$phase;

        if (phase === '$apply' || phase === '$digest') {
            this.history.save(callback);
        } else {
            this.$rootScope.$apply(() => this.history.save(callback));
        }
    }

    _setDimensions(width, height) {
        if (this.canvas) {
            this.canvas.setDimensions({
                width: width,
                height: height
            });
        }
    }

    _addAnnotation(tool, event) {
        if (this.readonly) {
            return;
        }

        const {offsetX, offsetY} = this._getEventCoords(event),
            coords = this._transposeCoordinates(offsetX, offsetY),
            x = coords.x,
            y = coords.y;

        switch (tool) {
            case 'circle':
                return this.toolkit.addCircle(x, y);
            case 'rectangle':
                return this.toolkit.addRectangle(x, y);
            case 'select':
                return this.toolkit.addSelect(x, y);
            case 'arrow':
                return this.toolkit.addArrow(x, y);
            case 'text':
                // eslint-disable-next-line no-case-declarations
                const text = this.toolkit.addText(x, y);

                if (this.environmentDetect.isIE11()) {
                    // On IE11, there's a bug in fabric.js that only known workaround is to simulate a right arrow press
                    text.onKeyDown({
                        keyCode: 35,
                        // creating an event in a cross-browser way is tricky, so we'll
                        // just add stubs for the methods needed in the simulated event
                        stopImmediatePropagation: function () {},
                        preventDefault: function () {}
                    });
                }

                return text;
            case 'numtag':
                return this.toolkit.addNumTag(x, y, this.toolOptions);
            case 'texttag':
                return this.toolkit.addTextTag(x, y, this.toolOptions);
            default:
                return;
        }
    }

    _isTagsChanged() {
        return !isEqual(this.oldTags, this.customTags);
    }

    _setContainerScale(scale) {
        const container = $(this.canvas.getElement()).parent(),
            transform = scale || scale === 1 ? `scale(${scale})` : 'none',
            maxHeight = `${100 / scale}%`;

        this.containerScale = scale;

        container.css({transform, maxHeight});
    }

    _getContainerScale() {
        return this.containerScale || 1;
    }

    getZoom() {
        return this.canvas && this.canvas.getZoom();
    }

    /**
     * Transposes given x and y to the actual ones,
     * based on current zoom and pan
     *
     * @param x - {Number} x coordinate
     * @param y - {Number} y coordinate
     */
    _transposeCoordinates(x, y) {
        const zoom = this.canvas.getZoom() * this._getContainerScale(),
            offsetX = this.canvas.viewportTransform[4],
            offsetY = this.canvas.viewportTransform[5];

        return {
            x: (x - offsetX) / zoom,
            y: (y - offsetY) / zoom
        };
    }

    _getEventCoords(e) {
        if (typeof e.offsetX === 'undefined' && typeof e.offsetY === 'undefined' && e.touches && e.touches.length > 0) {
            return this._getTouchEventCoords(e);
        }

        return {
            offsetX: e.offsetX,
            offsetY: e.offsetY
        };
    }
}
