import {BasicFormModel} from './form-model';
import {ICountryInfo} from '@techsee/techsee-ui-common/lib/_contracts/CountryInfo';
import find from 'lodash/find';

import {observable, computed, action, runInAction} from 'mobx';
import {ITranslate} from '../../services/LocalizationService';
import {OperationResult} from '../../models/OperationResult';
import {countryCodes} from '@techsee/techsee-country-codes/lib/index';

export interface IEnabledFields {
    enablePhoneNumberField: boolean;
    enableCustomerRefField: boolean;
    enableEmailField: boolean;
    enableStartDate?: boolean;
    enableEndDate?: boolean;
}

export interface IGenericFormController {
    readonly formModel: BasicFormModel;
    readonly fieldsEnabled: IEnabledFields;
    readonly countriesInfo: ICountryInfo[];

    readonly getSelectedField: string;
    readonly isCustomerRefEnabled: boolean;
    readonly isCountryCodeEnabled: boolean;
    readonly keepCustomerMobile: boolean;
    readonly customerIdValidationRules: {minLength: number; maxLength: number};
    readonly formCustomerId: string;
    readonly formPhoneNumberValue: string;
    readonly formEmailValue: string;
    readonly formStartDateValue?: Date;
    readonly formEndDateValue?: Date;
    readonly dateFormat?: string;
    readonly maxDate?: Date;
    readonly minDate?: Date;

    isLoading: boolean;
    formError: boolean;
    selectedField: string;
    firstSubmit: boolean;

    onSubmit(event: any): void;
    onTypeFieldChange(event: React.FormEvent<HTMLInputElement>): void;
    setIsLoading(isLoading: boolean): void;
    setFormError(value: boolean): void;
    validateForm(): PromiseLike<OperationResult>;
    onTodayClick?(): void;
    onYesterdayClick?(): void;
    onLast7DaysClick?(): void;
    changeStartDate?(newDate: Date): void;
    changeEndDate?(newDate: Date): void;

    readonly phoneCountryCodes: string[];
    readonly defaultCountryCode: string | number;
    readonly countryList: ICountryInfo[];
}

export class GenericFormController implements IGenericFormController {
    private readonly _translate: ITranslate;

    readonly formModel: BasicFormModel;

    readonly _onSubmit: any;

    readonly fieldsEnabled: IEnabledFields;

    private _accountSettings: any;

    @observable isLoading: boolean = false;

    @observable formError: boolean;

    @observable countryList: ICountryInfo[] = [];

    @observable selectedField: string;

    @observable firstSubmit: boolean = false;

    @observable
    private _countriesInfoList: ICountryInfo[] = [];

    constructor(
        translate: ITranslate,
        accSettings: any,
        formModel: BasicFormModel,
        onSubmitFunction: any,
        fieldsEnabled: IEnabledFields
    ) {
        this._translate = translate;
        this.formModel = formModel;
        this._onSubmit = onSubmitFunction;
        this._accountSettings = accSettings;
        this.countryList = countryCodes;
        this.formError = false;
        this.fieldsEnabled = {
            enableEmailField: fieldsEnabled.enableEmailField,
            enableCustomerRefField: fieldsEnabled.enableCustomerRefField,
            enablePhoneNumberField: fieldsEnabled.enablePhoneNumberField,
            enableStartDate: fieldsEnabled.enableStartDate,
            enableEndDate: fieldsEnabled.enableEndDate
        };
        this.selectedField = this.getSelectedField;

        this.onTypeFieldChange = this.onTypeFieldChange.bind(this);

        if (this.isCountryCodeEnabled) {
            Promise.resolve(this.countryList).then((countryList) => {
                runInAction(() => {
                    this._countriesInfoList = countryList.filter(
                        (country) =>
                            accSettings.phoneCountryCodes.indexOf(country.code) > -1 ||
                            `+${this._accountSettings.defaultCountryCode}` === country.dial
                    );
                });

                this.setAccountCountryCode();
            });
        }

        this.createFormFields();
        if (this.isCustomerRefEnabled && this._accountSettings.keepCustomerMobile) {
            this.setDisabledSearchParameters();
        }
    }

    @computed
    get countriesInfo(): ICountryInfo[] {
        return this._countriesInfoList;
    }

    get isCustomerRefEnabled(): boolean {
        let display, displayForInviteByCode, displayForInviteByEmail;

        if (this._accountSettings.customerIdValidationRules) {
            display = this._accountSettings.customerIdValidationRules.display;
            displayForInviteByCode = this._accountSettings.customerIdValidationRules.displayForInviteByCode;
            displayForInviteByEmail = this._accountSettings.customerIdValidationRules.displayForInviteByEmail;
        } else {
            display = this._accountSettings.customerId.display;
            displayForInviteByCode = this._accountSettings.customerId.displayForInviteByCode;
            displayForInviteByEmail = this._accountSettings.customerId.displayForInviteByEmail;
        }

        return display || displayForInviteByCode || displayForInviteByEmail;
    }

    get getSelectedField(): string {
        const {customerRef, phoneNumber, email} = this.formModel.fieldNames;

        if (!this.fieldsEnabled.enableCustomerRefField || !this.isCustomerRefEnabled) {
            return this.fieldsEnabled.enableEmailField ? email : phoneNumber;
        }

        return customerRef;
    }

    get isCountryCodeEnabled(): boolean {
        return this._accountSettings.addCountryCodeToSms;
    }

    @computed
    get keepCustomerMobile() {
        return this._accountSettings.keepCustomerMobile;
    }

    @computed
    get customerIdValidationRules() {
        return this._accountSettings.customerIdValidationRules;
    }

    get formCustomerId(): string {
        const {customerRef} = this.formModel.fieldNames;

        if (this.formModel.fields[customerRef]) {
            return this.formModel.fields[customerRef].value as string;
        }

        return '';
    }

    get formPhoneNumberValue(): string {
        const {phoneNumber} = this.formModel.fieldNames;

        if (this.formModel.fields[phoneNumber]) {
            return this.formModel.fields[phoneNumber].value as string;
        }

        return '';
    }

    get formEmailValue(): string {
        const {email} = this.formModel.fieldNames;

        if (this.formModel.fields[email]) {
            return this.formModel.fields[email].value as string;
        }

        return '';
    }

    @action
    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @action
    setFirstSubmit(value: boolean) {
        this.firstSubmit = value;
    }

    @action
    setFormError(value: boolean) {
        this.formError = value;
    }

    @action
    onTypeFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.selectedField = event.target.value;
        this.setDisabledSearchParameters();
    }

    private createFormFields(): void {
        const {customerRef, phoneNumber, countryCode, email, startDate, endDate} = this.formModel.fieldNames;

        if (this.fieldsEnabled.enablePhoneNumberField) {
            if (this._accountSettings.keepCustomerMobile) {
                this.formModel.createPhoneField(this.isCountryCodeEnabled);
                this.formModel.fields[phoneNumber] && this.formModel.fields[phoneNumber].setValue('');
            }

            if (this.isCountryCodeEnabled) {
                const initialValue = this._accountSettings.phoneCountryCodes[0];

                this.formModel.fields[countryCode] && this.formModel.fields[countryCode].setValue(initialValue);
            }
        }

        if (this.isCustomerRefEnabled && this.fieldsEnabled.enableCustomerRefField) {
            this.formModel.createCustomerRefField();
            this.formModel.fields[customerRef] && this.formModel.fields[customerRef].setValue('');
        }

        if (this.fieldsEnabled.enableEmailField) {
            this.formModel.createEmailField();
            this.formModel.fields[email] && this.formModel.fields[email].setValue('');
        }

        if (this.fieldsEnabled.enableStartDate) {
            this.formModel.createStartDateField();
            this.formModel.fields[startDate] && this.formModel.fields[startDate].setValue('');
        }

        if (this.fieldsEnabled.enableEndDate) {
            this.formModel.createEndDateField();
            this.formModel.fields[endDate] && this.formModel.fields[endDate].setValue('');
        }
    }

    private setDisabledSearchParameters(): void {
        const {customerRef, countryCode, phoneNumber, phoneNumberModel, email} = this.formModel.fieldNames;

        if (this.selectedField === customerRef) {
            this.formModel.fields[customerRef] && this.formModel.fields[customerRef].setDisabled(false);
            this.formModel.fields[countryCode] && this.formModel.fields[countryCode].setDisabled(true);
            this.formModel.fields[phoneNumberModel] && this.formModel.fields[phoneNumberModel].setDisabled(true);
            this.formModel.fields[email] && this.formModel.fields[email].setDisabled(true);
        } else if (this.selectedField === phoneNumber) {
            this.formModel.fields[customerRef] && this.formModel.fields[customerRef].setDisabled(true);
            this.formModel.fields[countryCode] && this.formModel.fields[countryCode].setDisabled(false);
            this.formModel.fields[phoneNumberModel] && this.formModel.fields[phoneNumberModel].setDisabled(false);
            this.formModel.fields[email] && this.formModel.fields[email].setDisabled(true);
        } else if (this.selectedField === email) {
            this.formModel.fields[email] && this.formModel.fields[email].setDisabled(false);
            this.formModel.fields[countryCode] && this.formModel.fields[countryCode].setDisabled(true);
            this.formModel.fields[phoneNumberModel] && this.formModel.fields[phoneNumberModel].setDisabled(true);
            this.formModel.fields[customerRef] && this.formModel.fields[customerRef].setDisabled(true);
        }
    }

    get phoneCountryCodes(): string[] {
        return this._accountSettings.phoneCountryCodes;
    }

    get defaultCountryCode(): string | number {
        return this._accountSettings.defaultCountryCode;
    }

    private setAccountCountryCode() {
        const {countryCode} = this.formModel.fieldNames;
        const accountDialCode = `+${this._accountSettings.defaultCountryCode}`;

        const country: ICountryInfo | undefined = find(this._countriesInfoList, {dial: accountDialCode});

        if (country && country.dial) {
            if (this._accountSettings.keepCustomerMobile && this.isCountryCodeEnabled) {
                this.formModel.fields[countryCode] && this.formModel.fields[countryCode].setValue(country.dial);
            }
        }
    }

    validateForm(): PromiseLike<OperationResult> {
        return new Promise<OperationResult>((resolve) => {
            this.formModel.validateForm().then((isFormValid) => {
                if (!isFormValid) {
                    this.formModel.displayFieldsErrors();
                    resolve(OperationResult.getFailResult());

                    return;
                }
                resolve(OperationResult.getSuccessResult());
            });
        });
    }

    onSubmit(event: any) {
        this.setFirstSubmit(true);

        this._onSubmit(event);
    }
}
