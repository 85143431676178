import React from 'react';
import {
    IFloatingToolbarGroup,
    GROUP_DIVIDER,
    IFloatingToolbarItem,
    IClickItemInfo,
    ICustomSubmenuProps
} from '@techsee/techsee-ui-common/lib/floating-toolbar/contracts';
import {ITranslate} from '../../../_react_/services/LocalizationService';
import {getRootStore} from '../../../_react_/app.bootstrap';
import {GuidancePopoversController, IGuidancePopoversController} from './guidance-popovers/GuidancePopovers.controller';
import {IDomUtilsService} from '@techsee/techsee-client-infra/lib/services/DomUtilsService';
import {IMeasureService} from '../../../services/ts-measure/ts-measure.service';
import {SpeedTestGuidance} from './guidance-popovers/SpeedTestGuidance';
import {FlashlightGuidance} from './guidance-popovers/FlashlightGuidance';
import {MeasurementGuidance} from './guidance-popovers/MeasurementGuidance';
import get from 'lodash/get';
import RangeSlider from '@techsee/techsee-ui-common/lib/floating-toolbar/components/RangeSlider';
import ColorPicker from '@techsee/techsee-ui-common/lib/floating-toolbar/components/ColorPicker';
import TagsSelector from '@techsee/techsee-ui-common/lib/floating-toolbar/components/TagsSelector';
import {ISpeedtestService} from '../../../_react_/services/SpeedtestService';
import {IDbRooms} from '../../../_react_/services/AngularServices/AngularServices';
import {IEventLogsService} from '../../../_react_/services/EventsLogService';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';

export enum CANVAS_TOOLS {
    HAND = 'hand',
    RECTANGLE = 'rectangle',
    CIRCLE = 'circle',
    ARROW = 'arrow',
    PEN = 'pen',
    TEXT = 'text',
    NUMTAG = 'numtag',
    TEXTTAG = 'texttag',
    ANALYZE = 'analyze'
}

export interface IToolbarOptionsController {
    readonly toolbarOptions: IFloatingToolbarGroup[];
}

export interface ToolHandler {
    activate: () => void;
    deactivate: () => void;
    updateIcon?: (iconName: string) => void;
}

export enum TOOL_HANDLER_KEYS {
    MOVE = 'move',
    SHAPE = 'shape',
    TEXT = 'text',
    LABELS = 'labels',
    ZOOM = 'zoom'
}

export class ToolbarOptionsController implements IToolbarOptionsController {
    private _domUtilsService: IDomUtilsService;

    private _translate: ITranslate;

    private _measureService: IMeasureService;

    private _speedtestService: ISpeedtestService;

    private _dbRoomsService: IDbRooms;

    private _eventsLogService: IEventLogsService;

    private _guidancePopoversController: IGuidancePopoversController;

    private _mainCtrl: any;

    private _dashboardCtrl: any;

    private _canvasToolsHandlers: Record<TOOL_HANDLER_KEYS, ToolHandler>;

    private _lastShape: {tool: CANVAS_TOOLS; icon: string} = {tool: CANVAS_TOOLS.ARROW, icon: 'arrow'};

    constructor(
        private _scope: any,
        private _tsCanvasAnnotate: any
    ) {
        this._domUtilsService = getRootStore().domUtilsService;
        this._translate = getRootStore().localizationService.translate;
        this._measureService = getRootStore().measureService;
        this._speedtestService = getRootStore().speedtestService;
        this._dbRoomsService = getRootStore().dbRoomsService;
        this._eventsLogService = getRootStore().eventsLogService;

        this._mainCtrl = this._scope.main;
        this._dashboardCtrl = this._scope.dashboard;

        this._guidancePopoversController = new GuidancePopoversController(
            this._translate,
            this._domUtilsService,
            this._measureService,
            this._speedtestService,
            get(this._dashboardCtrl.accountData, 'protectedSettings.mobileSpeedtest.ooklaPremium'),
            get(this._dashboardCtrl.accountData, 'protectedSettings.mobileSpeedtest.defaultProvider')
        );

        this._canvasToolsHandlers = {
            move: {activate: () => {}, deactivate: () => {}},
            shape: {activate: () => {}, deactivate: () => {}},
            text: {activate: () => {}, deactivate: () => {}},
            labels: {activate: () => {}, deactivate: () => {}},
            zoom: {activate: () => {}, deactivate: () => {}}
        };
    }

    get toolbarOptions(): IFloatingToolbarGroup[] {
        const dashboard = this._dashboardCtrl;
        const main = this._mainCtrl;
        const isEditMode = main.isCanvas;
        const measureButtonsAvailable = main.measurementEnabled && !isEditMode;

        const groups: IFloatingToolbarGroup[] = [
            // Group 1
            {
                groupId: 'Group1',
                available: main.isDesktopSharingRemoteControlSession,
                items: [
                    {
                        title: dashboard.agentHasRemoteControl
                            ? this._translate('REACT.FLOATING_TOOLBAR.DS_STOP_CONTROL')
                            : this._translate('REACT.FLOATING_TOOLBAR.DS_REQUEST_CONTROL'),
                        icon: 'control',
                        disabled: !dashboard.isLivePointerRunning,
                        iconClassName: `icon-ds-control-${dashboard.agentHasRemoteControl ? 'active' : 'inactive'}`,
                        clickHandler: () => {
                            main.desktopSharingToggleControl();
                        }
                    }
                ]
            },
            // Group 2
            {
                groupId: 'Group2',
                available: main.roomData.initiateWithMeasure,
                items: [
                    {
                        icon: 'measure',
                        title: this._translate('REACT.FLOATING_TOOLBAR.AR_MEASURE'),
                        available: measureButtonsAvailable,
                        disabled: !main.arMeasurement?.isSupported(),
                        toggleOnClick: true,
                        activatable: true,
                        iconClassName: `icon-ar-measurement-${main.arMeasurement?.isActive() ? 'active' : 'inactive'}`,
                        clickHandler: () => {
                            main.arMeasurement?.setActiveState();
                        }
                    },
                    {
                        icon: 'point',
                        title: this._translate('REACT.FLOATING_TOOLBAR.AR_MEASURE_ADD_POINT'),
                        available: measureButtonsAvailable && main.addPointEnabled,
                        disabled: !main.arMeasurement?.canAddPoint(),
                        clickHandler: () => {
                            main.arMeasurement?.addMeasurementPoint();
                        }
                    },
                    {
                        icon: 'undo',
                        title: this._translate('REACT.FLOATING_TOOLBAR.AR_MEASURE_UNDO'),
                        available: measureButtonsAvailable,
                        disabled: !main.arMeasurement?.canUndoPoint(),
                        clickHandler: () => {
                            main.arMeasurement?.undoLastPoint();
                        }
                    },
                    {
                        icon: 'copytoclipboard',
                        title: this._translate('REACT.FLOATING_TOOLBAR.AR_MEASURE_COPY_DISTANCE'),
                        available: main.measurementEnabled && main.arMeasurement?.canCopyDistance(),
                        disabled: !main.arMeasurement?.canCopyDistance(),
                        clickHandler: () => {
                            main.arMeasurement?.copyDistances();
                        }
                    }
                ]
            },
            // Group 3
            {
                groupId: 'Group3',
                available: true,
                items: [
                    {
                        icon: 'sendnow',
                        title: this._translate('REACT.FLOATING_TOOLBAR.SEND'),
                        available: true,
                        disabled:
                            !(
                                !dashboard.observe &&
                                (dashboard.connected !== dashboard.CONNECTION_STATUS.WAITING ||
                                    dashboard.offlineRoom) &&
                                dashboard.annotateService.canvasReady
                            ) ||
                            (dashboard.displayLoaderReconnecting(dashboard.isDesktopSharingSession) &&
                                dashboard.enableIdleModeUsability),
                        clickHandler: () => {
                            dashboard.sendImage(() => {
                                this._setDefaultTool();
                            });
                        }
                    },
                    {
                        icon: 'sharing',
                        title: this._translate('REACT.FLOATING_TOOLBAR.SHARE'),
                        available:
                            dashboard.enableShareMenu &&
                            !dashboard.quickSharingModesEnabled &&
                            !dashboard.shareDisabledByAllToggles(),
                        disabled: !(
                            (dashboard.preloadedImage.resource &&
                                dashboard.offlineMode &&
                                !dashboard.libraryTaggingMode) ||
                            (!dashboard.observe &&
                                dashboard.connected !== dashboard.CONNECTION_STATUS.WAITING &&
                                dashboard.annotateService.canvasReady)
                        ),
                        children: [
                            {
                                title: this._translate('REACT.FLOATING_TOOLBAR.SHARE.COPY_TO_CLIPBOARD'),
                                icon: 'copytoclipboard',
                                available: dashboard.enableCopyToClipboard(),
                                clickHandler: () => {
                                    dashboard.newTopbarHeaderCopyToClipboard();
                                    this._setDefaultTool();
                                }
                            },
                            {
                                title: this._translate('REACT.FLOATING_TOOLBAR.SHARE.DOWNLOAD'),
                                icon: 'download',
                                available: dashboard.enableLocalImageSaving(),
                                clickHandler: () => {
                                    dashboard.newTopbarHeaderDownload();
                                    this._setDefaultTool();
                                }
                            },
                            {
                                title: this._translate('REACT.FLOATING_TOOLBAR.SHARE.EMAIL'),
                                icon: 'sendsms',
                                available: dashboard.enableEmailImageSharing(),
                                clickHandler: () => {
                                    dashboard.newTopbarHeaderEmail();
                                    this._setDefaultTool();
                                }
                            }
                        ]
                    },
                    {
                        title: this._translate('REACT.FLOATING_TOOLBAR.SAVE_CHANGES'),
                        icon: 'save',
                        available: dashboard.displaySaveButton(),
                        disabled: dashboard.disabledSaveButton(),
                        clickHandler: () => {
                            dashboard.saveResource();
                            this._setDefaultTool();
                        }
                    }
                ]
            },
            // Group 4
            {
                groupId: 'Group4',
                available:
                    dashboard.isEnableWifiScan() ||
                    main.displayFloatingToolbarSpeedtest() ||
                    main.displayFloatingToolbarFlashlight(),
                items: [
                    {
                        icon: 'wifi-high',
                        title: 'Run WiFi Scan',
                        available: dashboard.isEnableWifiScan(),
                        clickHandler: () => this._wifiScanClickHandler()
                    },
                    {
                        icon: 'speedtest',
                        title: this._translate('REACT.FLOATING_TOOLBAR.SPEEDTEST'),
                        available: main.displayFloatingToolbarSpeedtest(),
                        disabled: dashboard.disabledSpeedtestButton(
                            !!this._guidancePopoversController.speedtestResults
                        ),
                        popover: {
                            title: this._guidancePopoversController.speedtestResults
                                ? this._translate('REACT.FLOATING_TOOLBAR.SPEEDTEST_RESULTS_TITLE')
                                : this._translate('REACT.FLOATING_TOOLBAR.SPEEDTEST_GUIDANCE_TITLE'),
                            content: () => <SpeedTestGuidance ctrl={this._guidancePopoversController} />,
                            closeOnBodyClick: true,
                            toggleOnClick: true,
                            deactivateOnClose: true
                        },
                        setItemHandlers: ({setPopover}) => {
                            this._guidancePopoversController.speedtestPopoverActivate = setPopover;
                        },
                        clickHandler: ({activateItem, deactivateItem, isActive}) =>
                            this._speedtestClickHandler(activateItem, deactivateItem, isActive)
                    },
                    {
                        icon: 'no-torch',
                        activeIcon: 'torch',
                        title: this._translate('REACT.FLOATING_TOOLBAR.FLASHLIGHT'),
                        available: main.displayFloatingToolbarFlashlight() && !dashboard.observe,
                        disabled: !main.isFlashlightAvailableOnClient || dashboard.displayLoaderReconnecting(),
                        activatable: true,
                        popover: {
                            title: this._translate('REACT.FLOATING_TOOLBAR.FLASHLIGHT_GUIDANCE_TITLE'),
                            content: (properties: {isActive: boolean}) => (
                                <FlashlightGuidance
                                    ctrl={this._guidancePopoversController}
                                    isTurnedOn={properties.isActive}
                                    onSend={() => main.sendFlashfightGuidance()}
                                />
                            ),
                            toggleOnClick: true,
                            deactivateOnClose: false,
                            showDots: true
                        },
                        setItemHandlers: ({activateItem, deactivateItem}) => {
                            main.setFlashlightStates(activateItem, deactivateItem);
                        },
                        clickHandler: () => {
                            this._setDefaultTool();
                        }
                    }
                ]
            },
            // Group 5
            {
                groupId: 'Group5',
                available: true,
                items: [
                    {
                        icon: 'scan',
                        title: this._translate('REACT.FLOATING_TOOLBAR.SCAN'),
                        available: main.showScanners && main.isToggleEnabled_ScanCaptureDesign(),
                        disabled: this._isScanDisabled(),
                        activatable: true,
                        clickHandler: () => {
                            this._measureService.stop();
                            main.embeddedDashboard ? main.snapAndScan() : main.scan();

                            this._setDefaultTool();
                        },
                        setItemHandlers: ({activateItem, deactivateItem}) => {
                            main.setToolbarScanStates(activateItem, deactivateItem);
                        }
                    },
                    {
                        title: dashboard.recordingStatusMessage(),
                        icon: 'recodring',
                        available: dashboard.isRecordingEnabled,
                        disabled: !dashboard.recordButtonReady,
                        toggleOnClick: true,
                        activatable: true,
                        iconClassName: `icon-recording-${dashboard.recordingStatus}`,
                        clickHandler: () => dashboard.onRecordButton()
                    },
                    {
                        icon: 'measure',
                        title: this._translate('REACT.FLOATING_TOOLBAR.MEASUREMENT'),
                        available: this._isMeasureAvailable,
                        disabled: this._isMeasureDisabled(),
                        activatable: true,
                        clickHandler: ({isActive}) => {
                            if (isActive) {
                                main.stopMeasure();
                            } else {
                                main.startMeasure();
                            }

                            this._setDefaultTool();
                        },
                        setItemHandlers: ({activateItem, deactivateItem}) => {
                            this._measureService.setToolbarMeasureStates(activateItem, deactivateItem);
                        },
                        popover: {
                            title: this._translate('REACT.FLOATING_TOOLBAR.STEP', {
                                current: 1,
                                all: 2
                            }),
                            content: (properties: {onUpdate: () => void}) => (
                                <MeasurementGuidance
                                    ctrl={this._guidancePopoversController}
                                    onUpdate={properties.onUpdate}
                                />
                            ),
                            showOnActiveItem: true,
                            useItemOffset: true,
                            closeOnDeactivation: true,
                            setUpdateTitleHandler: (updateTitle) => {
                                this._guidancePopoversController.updatePopoverTitle = updateTitle;
                            }
                        }
                    }
                ]
            },
            // Group 6
            {
                groupId: 'Group6',
                minified: true,
                divider: GROUP_DIVIDER.THIN,
                available: !main.observe && (main.isCanvas || main.isImageScan),
                items: [
                    {
                        title: this._translate('REACT.FLOATING_TOOLBAR.ZOOM'),
                        icon: 'zoom',
                        showChildrenOnClick: true,
                        disabled: this._isZoomDisabled(),
                        activatable: true,
                        setItemHandlers: ({activateItem, deactivateItem}) => {
                            this._canvasToolsHandlers.zoom = {
                                activate: activateItem,
                                deactivate: deactivateItem
                            };
                        },
                        customSubmenu: () => {
                            const zoomValue = this._tsCanvasAnnotate.getZoom();

                            return (
                                <RangeSlider value={zoomValue} onChange={(value) => this._zoomChanged(Number(value))} />
                            );
                        }
                    },
                    {
                        title: this._translate('REACT.FLOATING_TOOLBAR.MOVE'),
                        icon: 'pan',
                        toggleOnClick: true,
                        disabled: this._isMoveDisabled(),
                        activatable: true,
                        setItemHandlers: ({activateItem, deactivateItem}) => {
                            this._canvasToolsHandlers.move = {
                                activate: activateItem,
                                deactivate: deactivateItem
                            };
                        },
                        clickHandler: ({isActive}) => {
                            this._moveClick(isActive);
                        }
                    },
                    {
                        title: this._translate('REACT.FLOATING_TOOLBAR.ROTATE_LEFT'),
                        icon: 'rotate',
                        iconClassName: 'flipped',
                        disabled: this._isRotateDisabled(),
                        clickHandler: () => {
                            this._tsCanvasAnnotate.rotateLeft();
                            this._scope.$apply();
                        }
                    },
                    {
                        title: this._translate('REACT.FLOATING_TOOLBAR.ROTATE_RIGHT'),
                        icon: 'rotate',
                        disabled: this._isRotateDisabled(),
                        clickHandler: () => {
                            this._tsCanvasAnnotate.rotateRight();
                            this._scope.$apply();
                        }
                    },
                    {
                        title: this._translate('REACT.FLOATING_TOOLBAR.COLOR'),
                        icon: 'color',
                        showChildrenOnClick: true,
                        disabled: this._isDrawDisabled(),
                        setItemHandlers: ({updateStyles}) => {
                            updateStyles({
                                icon: {color: this._tsCanvasAnnotate.getColor()}
                            });
                        },
                        customSubmenu: (props) => (
                            <ColorPicker onSelect={(event, color) => this._updateColor(color, props)} />
                        )
                    },
                    {
                        title: this._translate('REACT.FLOATING_TOOLBAR.SHAPE'),
                        icon: 'shape',
                        showChildrenOnClick: true,
                        disabled: this._isDrawDisabled(),
                        activatable: true,
                        setItemHandlers: ({activateItem, deactivateItem, updateIcon}) => {
                            this._canvasToolsHandlers.shape = {
                                activate: activateItem,
                                deactivate: deactivateItem,
                                updateIcon: updateIcon
                            };

                            this._deactivateTools();
                            activateItem();
                            updateIcon('arrow');

                            this._tsCanvasAnnotate.setTool(CANVAS_TOOLS.ARROW);
                        },
                        children: [
                            {
                                title: this._translate('REACT.FLOATING_TOOLBAR.SHAPE_RECTANGLE'),
                                icon: 'rectangle',
                                clickHandler: (item) => {
                                    this._shapeClick(CANVAS_TOOLS.RECTANGLE, item);
                                }
                            },
                            {
                                title: this._translate('REACT.FLOATING_TOOLBAR.SHAPE_CIRCLE'),
                                icon: 'circle',
                                clickHandler: (item) => {
                                    this._shapeClick(CANVAS_TOOLS.CIRCLE, item);
                                }
                            },
                            {
                                title: this._translate('REACT.FLOATING_TOOLBAR.SHAPE_ARROW'),
                                icon: 'arrow',
                                clickHandler: (item) => {
                                    this._shapeClick(CANVAS_TOOLS.ARROW, item);
                                }
                            },
                            {
                                title: this._translate('REACT.FLOATING_TOOLBAR.SHAPE_PEN'),
                                icon: 'freehand',
                                clickHandler: (item) => {
                                    this._shapeClick(CANVAS_TOOLS.PEN, item);
                                }
                            }
                        ]
                    },
                    {
                        title: this._translate('REACT.FLOATING_TOOLBAR.TEXT'),
                        icon: 'text',
                        activatable: true,
                        disabled: this._isDrawDisabled(),
                        setItemHandlers: ({activateItem, deactivateItem}) => {
                            this._canvasToolsHandlers.text = {
                                activate: activateItem,
                                deactivate: deactivateItem
                            };
                        },
                        clickHandler: ({isActive}) => {
                            this._textClick(isActive);
                        }
                    },
                    {
                        title: this._translate('REACT.FLOATING_TOOLBAR.TAGS'),
                        icon: 'templates',
                        disabled: this._isDrawDisabled(),
                        showChildrenOnClick: true,
                        activatable: true,
                        setItemHandlers: ({activateItem, deactivateItem}) => {
                            this._canvasToolsHandlers.labels = {
                                activate: activateItem,
                                deactivate: deactivateItem
                            };
                        },
                        clickHandler: ({isActive}) => {
                            this._tagsClick(isActive);
                        },
                        customSubmenu: () => (
                            <TagsSelector
                                numbersTagsTitle={this._translate('REACT.FLOATING_TOOLBAR.TAGS_NUMBER_TITLE')}
                                textTagsTitle={this._translate('REACT.FLOATING_TOOLBAR.TAGS_TEXT_TITLE')}
                                customTagTitle={this._translate('REACT.FLOATING_TOOLBAR.TAGS_CUSTOM_TITLE')}
                                onSelectNumTag={(tag) => {
                                    this._labelClick(CANVAS_TOOLS.NUMTAG, tag);
                                }}
                                onSelectTextTag={(tag) => {
                                    this._labelClick(CANVAS_TOOLS.TEXTTAG, tag);
                                }}
                                textTags={this._mainCtrl.accountData.dashboardTags}
                            />
                        )
                    }
                ]
            },
            // Group 7
            {
                groupId: 'Group7',
                available: !main.observe && (main.isCanvas || main.isImageScan),
                items: [
                    {
                        title: this._translate('REACT.FLOATING_TOOLBAR.UNDO'),
                        icon: 'undo',
                        disabled: main.isScanMode || !this._tsCanvasAnnotate.isAnnotated() || main.canvasReadOnly,
                        clickHandler: () => {
                            this._tsCanvasAnnotate.undo();
                            this._scope.$apply();
                        }
                    }
                ]
            }
        ];

        return groups.filter((group) => !!group.available);
    }

    private get _isMeasureAvailable() {
        return (
            this._mainCtrl.isToggleEnabled_measurement() &&
            !this._mainCtrl.isLiveVideo &&
            (this._mainCtrl.isCanvas || this._mainCtrl.isImageScan) &&
            !this._mainCtrl.isDesktopSharingSession
        );
    }

    private _isScanDisabled() {
        if (this._mainCtrl.isLiveVideo) {
            return false;
        }

        if (this._mainCtrl.isDesktopSharingSession) {
            return false;
        }

        if ((this._mainCtrl.isScreenSharing || this._mainCtrl.isApplicationSharing) && this._mainCtrl.video.focus) {
            return false;
        }

        return (
            (this._dashboardCtrl.displayLoaderReconnecting() && !this._mainCtrl.showImageWidget) ||
            !((this._mainCtrl.isCanvas && this._tsCanvasAnnotate.canvasReady) || this._mainCtrl.isScanMode) ||
            this._measureService.isActive
        );
    }

    private _isMeasureDisabled() {
        return this._mainCtrl.isImageScan || !this._tsCanvasAnnotate.canvasReady;
    }

    private _isZoomDisabled() {
        return (
            !this._tsCanvasAnnotate.canvasReady ||
            this._mainCtrl.isImageScan ||
            !this._tsCanvasAnnotate.zoomEnabled ||
            this._mainCtrl.canvasReadOnly
        );
    }

    private _isMoveDisabled() {
        return !this._tsCanvasAnnotate.canvasReady || this._mainCtrl.isImageScan || this._mainCtrl.canvasReadOnly;
    }

    private _isRotateDisabled() {
        return (
            !this._tsCanvasAnnotate.canvasReady ||
            this._mainCtrl.isImageScan ||
            !this._tsCanvasAnnotate.rightRotateEnabled ||
            this._mainCtrl.canvasReadOnly
        );
    }

    private _isDrawDisabled() {
        return !this._tsCanvasAnnotate.canvasReady || this._mainCtrl.isImageScan || this._mainCtrl.canvasReadOnly;
    }

    private _deactivateTools() {
        Object.keys(this._canvasToolsHandlers).forEach((tool) => {
            if (!(tool === TOOL_HANDLER_KEYS.ZOOM && this._tsCanvasAnnotate.getZoom() > 1)) {
                this._canvasToolsHandlers[tool as TOOL_HANDLER_KEYS].deactivate();
            }
        });
    }

    private _moveClick(isActive: boolean) {
        this._deactivateTools();

        if (isActive) {
            this._canvasToolsHandlers.move.activate();
            this._setTool(CANVAS_TOOLS.HAND);
        } else {
            this._setDefaultTool();
        }
    }

    private _shapeClick(tool: CANVAS_TOOLS, itemInfo: IClickItemInfo & {item: IFloatingToolbarItem}) {
        this._deactivateTools();
        this._canvasToolsHandlers.shape.activate();

        if (itemInfo.parent) {
            itemInfo.parent.activateItem();
            itemInfo.parent.updateIcon(itemInfo.item.icon);
        }

        this._lastShape = {
            tool: tool,
            icon: itemInfo.item.icon
        };

        this._setTool(tool);
    }

    private _textClick(isActive: boolean) {
        this._deactivateTools();

        if (!isActive) {
            this._canvasToolsHandlers.text.activate();
            this._setTool(CANVAS_TOOLS.TEXT);
        } else {
            this._setDefaultTool();
        }
    }

    private _tagsClick(isActive: boolean) {
        if (isActive) {
            this._setDefaultTool();
        }
    }

    private _labelClick(type: CANVAS_TOOLS.NUMTAG | CANVAS_TOOLS.TEXTTAG, tag?: string) {
        this._deactivateTools();
        this._canvasToolsHandlers.labels.activate();

        this._setTool(type, tag);
    }

    private _zoomChanged(value: number) {
        this._deactivateTools();

        if (value > 1) {
            this._canvasToolsHandlers.zoom.activate();
        } else {
            this._canvasToolsHandlers.zoom.deactivate();
        }

        this._canvasToolsHandlers.move.activate();
        this._tsCanvasAnnotate.setZoom(Number(value));
        this._tsCanvasAnnotate.setTool(CANVAS_TOOLS.HAND);
    }

    private _updateColor(color: string, props: ICustomSubmenuProps) {
        props.updateIconClassName(color.toLowerCase().indexOf('#ffffff') !== -1 ? 'backlayer' : '');
        props.updateStyles({icon: {color: color}});
        this._tsCanvasAnnotate.setColor(color);
        this._scope.$apply();
    }

    private _setTool(tool: CANVAS_TOOLS | null, options?: string) {
        this._tsCanvasAnnotate.setTool(tool, options);
        this._scope.$apply();
    }

    private _setDefaultTool() {
        this._deactivateTools();

        if (this._canvasToolsHandlers.shape.updateIcon) {
            this._canvasToolsHandlers.shape.updateIcon(this._lastShape.icon);
        }

        this._canvasToolsHandlers.shape.activate();

        this._tsCanvasAnnotate.setTool(this._lastShape.tool);
        this._scope.$apply();
    }

    _speedtestClickHandler(activateItem: Function, deactivateItem: Function, isActive: boolean) {
        if (!isActive) {
            this._mainCtrl.runSpeedTestOnClient();
            activateItem();

            const roomId = this._mainCtrl.roomData._id;
            const date = new Date();

            this._eventsLogService.debug({
                roomId,
                logType: LOG_EVENTS.speedtestRequested,
                meta: {date}
            });

            this._dbRoomsService.setReportedField(roomId, {
                data: {
                    event: {
                        key: 'speedtestRequestTimestamp',
                        value: date
                    }
                }
            });
        } else {
            deactivateItem();
            this._setDefaultTool();
        }
    }

    _wifiScanClickHandler() {
        this._mainCtrl.runWifiScanOnClient();
        const roomId = this._mainCtrl.roomData._id;
        const date = new Date();

        this._eventsLogService.debug({
            roomId,
            logType: LOG_EVENTS.wifiScanInitiated,
            meta: {date}
        });

        this._dbRoomsService.setReportedField(roomId, {
            data: {
                event: {
                    key: 'wifiScanRequestTimestamp',
                    value: date
                }
            }
        });
    }
}
