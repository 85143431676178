'use strict';

// CSS libraries
import 'normalize.css/normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'angular-tree-control/css/tree-control.css';
import 'ladda/dist/ladda-themeless.min.css';
import 'ng-tags-input/build/ng-tags-input.css';
import 'ng-tags-input/build/ng-tags-input.bootstrap.css';
import 'angular-material/angular-material.min.css';

// JS libraries

// Need to put jQuery on the `window` object for Angular to use it (using expose-loader)
import 'expose-loader?exposes=jQuery!jquery/dist/jquery.min';
import 'expose-loader?exposes=$!jquery/dist/jquery.min';
import 'expose-loader?exposes=moment!moment/moment';
import 'moment-timezone/builds/moment-timezone-with-data.min';
import 'expose-loader?exposes=LoginEncoder!@techsee/techsee-common/lib/login-info-encoder';
import 'expose-loader?exposes=TsUtils!@techsee/techsee-common/lib/utils';

import 'angular/angular.min';
import 'js/fabric.js';
import 'lodash';
import 'hamsterjs';
import 'react';
import 'react-dom';
import 'ngreact';

// External Angular modules
import uiRouter from 'angular-ui-router';
import ngAnimate from 'angular-animate';
import ngMessages from 'angular-messages';
import ngTouch from 'angular-touch';
import hmTouchEvents from 'angular-hammer';
import uiBootstrap from 'angular-ui-bootstrap';
import ngSanitize from 'angular-sanitize';
import jsDataAngular from 'js-data-angular';
import ngStorage from 'ngstorage';
import ngTreeControl from 'angular-tree-control';
import ngTranslate from 'angular-translate';
import ngCookies from 'angular-cookies';
import ngLadda from 'angular-ladda/dist/angular-ladda.min';
import 'ngclipboard';
import 'ng-focus-on';
import 'ng-tags-input';
import '@techsee/techsee-common/lib/fe/password-validator/password-validator';
import 'angular-elastic/elastic';
import ngMaterial from 'angular-material';
import ngFileUpload from 'ng-file-upload';

// the url-config service needs to be configured for the environment
import tsUrlConfigServiceInit from '@techsee/techsee-common/lib/fe/ts-url-config/ts-url-config';

tsUrlConfigServiceInit({ENV, API_URL, SOCKET_URL, MOBILE_CLIENT_URL, STATS_API_URL});

// Require Angular-Mocks when running in test mode (e.g. `npm run test`)
// ENV global variable set in webpack.config.js
if (ENV.test) {
    import('angular-mocks/angular-mocks');
}

export default angular.module('app.vendor', [
    uiRouter,
    ngAnimate,
    ngMessages,
    ngTouch,
    hmTouchEvents,
    uiBootstrap,
    ngSanitize,
    jsDataAngular,
    ngStorage.name,
    ngTreeControl,
    ngTranslate,
    ngCookies,
    ngLadda,
    'ngclipboard',
    'focusOn',
    'ngTagsInput',
    'monospaced.elastic',
    ngMaterial,
    ngFileUpload,
    'react'
]);
