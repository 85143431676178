'use strict';

import get from 'lodash/get';
import last from 'lodash/last';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';

import './library.style.scss';

export class LibraryDialogController {
    constructor(
        $scope,
        $modalInstance,
        selectedNode,
        collection,
        topLevel,
        footer,
        offlineMode,
        endMeeting,
        dashboardScope,
        $rootScope,
        $q,
        db
    ) {
        'ngInject';

        this.$scope = $scope;
        this.$modalInstance = $modalInstance;

        this.endMeeting = endMeeting;
        this.collection = collection;
        this.carouselItems = [];
        this.footer = footer;
        this.topLevelDir = topLevel.name;
        this.libDirectories = [this.topLevelDir].concat(selectedNode.parents);
        this.offlineMode = offlineMode;
        this.$q = $q;
        this.db = db;
        this.dashboardScope = dashboardScope;

        if (selectedNode.type === 'dir') {
            this.libDirectories = [this.topLevelDir].concat(selectedNode.children[0].parents);
            this.loadDirectory(selectedNode.title, selectedNode);
        } else {
            this.loadDirectory(last(this.libDirectories), selectedNode);
        }

        this._watchCarouselSelection();

        // Close Modal dialog on route changing
        $rootScope.$on('$locationChangeSuccess', () => $modalInstance.dismiss());
    }

    loadDirectory(dirNode, selectedNode) {
        // pointer to the current collection item, as we iterate
        let pCollection = this.collection;
        const selection = selectedNode && selectedNode.type !== 'dir' ? selectedNode : null;

        // requested topLevel
        if (dirNode === this.topLevelDir) {
            this.libDirectories = [dirNode];

            return this._setItems(this.collection.children, () => {
                this._loadCarousel(selectedNode);
            });
        }

        forEach(this.libDirectories.slice(1), (directory) => {
            // we start at the top of the tree and search the node's children
            // for the dirNode we were provided. If the node itself is named
            // dirNode, then we are there already
            pCollection = pCollection.children[findIndex(pCollection.children, {title: directory})];

            if (directory === dirNode && (!selectedNode || selectedNode === pCollection)) {
                return false;
            }
        });

        if (pCollection) {
            this._setItems(pCollection.children, () => {
                this._loadCarousel(selection);
                this.libDirectories = [this.topLevelDir].concat(pCollection.parents).concat(dirNode);
            });
        }
    }

    _setItems(items, cb) {
        const secureItems = filter(items, (item) => get(item.resource, 'security.enabled'));

        const promises = map(secureItems, (item) =>
            this.db.Resource.find(item.resource._id, {bypassCache: true}).then((resource) => {
                item.resource = resource;
                item.url = resource.url || item.url;
            })
        );

        return this.$q.all(promises).then(() => {
            this.items = sortBy(items, 'type');
            cb();
        });
    }

    _updateSecureItem(item) {
        const dataItem = item.data || item;

        if (get(dataItem.resource, 'security.enabled')) {
            return this.db.Resource.resign(dataItem.resource._id, {bypassCache: true}).then((resp) => {
                dataItem.resource = resp.data;
                dataItem.url = resp.data.url || dataItem.url;

                return item;
            });
        }

        return this.$q.when(item);
    }

    _watchCarouselSelection() {
        this.$scope.$watch(
            () => findIndex(this.carouselItems, {active: true}),
            (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    if (newValue >= 0) {
                        this._setSelectedFile(this.carouselItems[newValue].data);
                        this.activeType = this.carouselItems[newValue].data.type;
                    }
                }
            }
        );
    }

    _setSelectedFile(selectedNode) {
        if (!selectedNode) {
            this.selectedFileIndex = 0;

            return;
        }

        this.selectedFileIndex = findIndex(this.items, {url: selectedNode.url}) || 0;
    }

    _loadCarousel(selectedNode) {
        this.carouselItems.length = 0;

        forEach(this.items, (item, index) => {
            // discard directories
            if (!item.url) {
                return;
            }

            const active = (!selectedNode && !index) || (selectedNode && selectedNode.url === item.url);

            if (active) {
                this.selectedFileIndex = index;
            }

            // we wrap the actual data because the carousel will mess with the
            // object (particularly with the 'active' property)
            this.carouselItems.push({
                active: active,
                data: item
            });
        });

        if (this.carouselItems.length > 0 && this.selectedFileIndex >= 0) {
            this.activeType = this.items[this.selectedFileIndex].type;
        }
    }

    clickItem(item) {
        if (item.type === 'dir') {
            this.libDirectories = [this.topLevelDir].concat(item.children[0].parents);
            this.loadDirectory(item.title, item);
        } else if (this.footer) {
            this._setActiveCarouselItem(item);
        } else {
            this._updateSecureItem({data: item}).then((updatedItem) => {
                this.$modalInstance.close(updatedItem);
            });
        }
    }

    _setActiveCarouselItem(item) {
        if (!item) {
            return;
        }
        this._setSelectedFile(item);

        forEach(this.carouselItems, (cItem) => {
            cItem.active = cItem.data.url === item.url;
        });
    }

    annotateItem() {
        const item = find(this.carouselItems, {active: true});

        this._updateSecureItem(item).then((updatedItem) => {
            this.$modalInstance.close(updatedItem);
        });
    }

    sendItem() {
        const item = find(this.carouselItems, {active: true});

        item.send = true;
        this._updateSecureItem(item).then((updatedItem) => {
            this.$modalInstance.close(updatedItem);
        });
    }

    close() {
        this.$modalInstance.dismiss();
    }
}
